import { Device } from '@/services/devices/devices.types';
import { TablePaginationOptions } from '../../tables/tables.types';
import { SaveBase64Options } from '@/services/file/file.types';
import { AppExternalError } from '@/core/errors/app-external-error.class';

export type UkTransfer = Device;

export type UkTransferInvoiceFile = SaveBase64Options;

export class InvalidUkInvoiceError extends AppExternalError {
  constructor() {
    super(`La facture n'a pas le format attendu.`);
  }
}

export interface UkTransferListOptions {
  filters?: Partial<Record<keyof UkTransferListOptions, string[]>> & { fulltext: string };
  pagination?: TablePaginationOptions;
}
