import { partFailuresService } from '@/services/parts-failures/parts-failures.service';
import { PartFailure } from '@/services/parts-failures/parts-failures.types';
import { RootState } from '@/store';
import { Module } from 'vuex';

export interface PartFailuresState {
  partFailures: PartFailure[];
  partFailureLoading: boolean;
}

const state: PartFailuresState = {
  partFailures: [],
  partFailureLoading: false
};

const partFailures: Module<PartFailuresState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    createPartFailure: (
      { commit, dispatch },
      partFailureData: { part_serial_no: string; serial_no: string }
    ): Promise<void> => {
      commit('partsFailureLoading', true);
      return partFailuresService
        .create(partFailureData)
        .then(newPartFailure => {
          commit('createPartFailure', newPartFailure);
        })
        .catch(error => {
          dispatch('alert/pushError', error.response?.data?.error || error.message, { root: true });
        })
        .finally(() => {
          commit('partsFailureLoading', false);
        });
    }
  },
  mutations: {
    partFailures: (state, partFailures: PartFailure[]) => (state.partFailures = partFailures),
    createPartFailure: (state, newPartFailure: PartFailure) => {
      state.partFailures.push(newPartFailure);
    },
    partsFailureLoading: (state, loading: boolean) => (state.partFailureLoading = loading)
  }
};

export default partFailures;
