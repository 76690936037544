import { AppExternalError } from '@/core/errors/app-external-error.class';
import { Comment } from '../comments/comments.types';
import { Device } from '../devices/devices.types';
import { SaveBase64Options } from '../file/file.types';
import { OrderLine } from '../order-lines/order-lines.types';
import { Match, MatchStatus } from '../matches/matches.types';
import { TablePaginationOptions } from '../tables/tables.types';
import { Marketplace } from '../marketplaces/marketplaces.types';

export interface OrderAddress {
  city: string;
  civility: number;
  company: string;
  country: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  dial_code: string;
  phone_secondary?: string;
  postal_code: string;
  relay_id: number;
  state?: string;
  street_1: string;
  street_2: string;
}

export enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  PAYPAL = 'paypal',
  BANK_TRANSFER = 'bank_transfer'
}

export type Order = {
  association_error?: string;
  billing_info: OrderAddress;
  created_at: string;
  currency: string;
  device?: Device;
  express: boolean;
  id: number;
  invoice_file_id: string;
  invoice_number?: number;
  is_cancelled: boolean;
  is_exchanged: boolean;
  is_retracted: boolean;
  last_comment?: Comment;
  marketplace: Marketplace;
  marketplace_id: number;
  marketplace_line_id: string;
  marketplace_order_id: string;
  match?: Match;
  max_shipping_date: string;
  nb_items: number;
  new_match_required: boolean;
  order_date: string;
  order_lines: OrderLine[];
  out_of_stock: boolean;
  payment_method: PaymentMethod;
  same_address: boolean;
  shipping_date?: string;
  shipping_fees: number;
  shipping_info: OrderAddress;
  shipping_order_id: string;
  shipping_tracking_external_link: string;
  tax_scheme: string;
  total_price: number;
  updated_at: string;
};

export interface OrdersList {
  count: number;
  values: Order[];
}

export interface OrdersListOptions {
  filters?: Partial<Record<keyof Order, string[]>> & { fulltext: string; match_status: MatchStatus };
  pagination?: TablePaginationOptions;
}

export interface OrderCarrierLabelData {
  label_url: string;
  order_id: string;
  tracking_carrier: string;
  tracking_external_link: string;
  tracking_number: string;
  zpl: string;
}
export interface OrderPreparationLabelData {
  zpl: string;
}

export type OrderInvoiceFile = SaveBase64Options;

export class InvalidInvoiceError extends AppExternalError {
  constructor(id: number) {
    super(`La facture de la commande ${id} n'a pas le format attendu.`);
  }
}

export interface OrdersImportOptions {
  marketplace: string;
  file: File;
}

export interface OrdersImportResponse {
  count: number;
}
