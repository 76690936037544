import axios from '@/core/axios';

import { InvalidReportError, Report, ReportFile } from './reports.types';

export class ReportsService {
  list(serial_no: string): Promise<Report[]> {
    return axios.get<Report[]>('/reports/list', { params: { serial_no: serial_no } }).then(resp => resp?.data);
  }

  createReport(serial_no: string, step: string, additional_tests: string[]): Promise<ReportFile> {
    const params = new URLSearchParams();
    params.append('step', step);

    if (step == 'repair' && additional_tests) {
      additional_tests.forEach(test => params.append('additional_tests[]', test));
    }
    return axios.get<ReportFile>(`/reports/${serial_no}`, { params: params }).then(resp => resp?.data);
  }

  downloadReport(report_id: number, language: string): Promise<ReportFile> {
    return axios.get<ReportFile>(`/reports/download/${report_id}`, { params: { language: language } }).then(resp => {
      const { base_64, filename, type } = resp?.data || {};
      if (!base_64 || !filename || !type) throw new InvalidReportError(report_id);
      return { base_64, filename, type };
    });
  }

  getReport(report_id: number): Promise<ReportFile> {
    return axios.get<ReportFile>(`/reports/${report_id}`).then(resp => {
      const { base_64, filename, type } = resp?.data || {};
      if (!base_64 || !filename || !type) throw new InvalidReportError(report_id);
      return { base_64, filename, type };
    });
  }
}

export const reportsService = new ReportsService();
