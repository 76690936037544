import axios from '@/core/axios';

import { CollectionList } from '../collections/collections.types';
import { AppSectionCode } from '../app-sections/app-sections.types';
import { Device, DeviceReportFile, DevicesListOptions, ImportUpdateStatusResponse } from './devices.types';
import { InvalidReportError } from '../reports/reports.types';

export class DevicesService {
  list(opts: DevicesListOptions = {}): Promise<CollectionList<'devices'>> {
    const { filters, pagination } = opts;
    return axios.get<CollectionList<'devices'>>('/devices', { params: { ...filters, ...pagination } }).then(resp => {
      return resp?.data;
    });
  }

  get(serialNo: string): Promise<Device> {
    return axios.get<Device>(`/devices/${serialNo}`).then(resp => resp?.data);
  }
  checkDeviceControl(imei: string, serialNo: string, selectedReference?: string): Promise<Device> {
    return axios
      .get<Device>('/devices/search', {
        params: { imei, serial_no: serialNo, selected_reference: selectedReference }
      })
      .then(resp => resp.data)
      .catch(error => {
        console.error('Error checking device control status:', error);
        throw error;
      });
  }

  findBySerialNo(serialNo: string, params: { stocks?: AppSectionCode[] } = {}): Promise<Device> {
    return axios.get<Device>(`/devices/find/${serialNo}`, { params }).then(resp => resp?.data);
  }

  newDevice(): Promise<Device> {
    return axios.get<Device>(`devices/new`).then(resp => resp?.data);
  }

  create(device: Device): Promise<Device> {
    return axios.post<Device>(`devices`, { device }).then(resp => resp?.data);
  }

  createFromControl(controlId: number, device: Device, selectedReference?: string): Promise<Device> {
    const serialNo = device.serial_no;
    const imei = device.supplier_reference;
    const selected_reference = selectedReference || device.reference_internal;
    return axios
      .post<Device>(`/devices/${controlId}`, { device, imei, serial_no: serialNo, selected_reference })
      .then(resp => {
        if (resp.data && resp.data.status === 'ok') {
          if (!resp.data.device || !resp.data.device.id || !resp.data.device.serial_no) {
            throw new Error('Incomplete device data in response');
          }
          return resp.data.device;
        } else {
          throw new Error(resp.data.message || 'Unknown error occurred');
        }
      })
      .catch(error => {
        console.error('Error in createFromControl:', error);
        throw error;
      });
  }

  update(deviceId: number | string, device: Partial<Device>): Promise<Device> {
    // deviceId should be serialNo in fact
    if (!deviceId) throw new Error('Missing device id in update');
    return axios.put<Device>(`/devices/${deviceId}`, { device }).then(resp => resp?.data);
  }

  delete(serialNo: string): Promise<void> {
    if (!serialNo) throw new Error('Missing serial number in delete');
    return axios.delete<void>(`/devices/${serialNo}`).then(resp => resp?.data);
  }

  move(serialNo: string, stockId: number, reason: string): Promise<Device> {
    return axios.post<Device>(`/devices/${serialNo}/stocks/${stockId}`, { reason }).then(resp => resp?.data);
  }

  getLabel(serialNo: string): Promise<string> {
    return axios.get(`/devices/${serialNo}/control-label`).then(response => response.data.zpl);
  }

  getMiniLabel(serialNo: string): Promise<string> {
    return axios.get(`/devices/${serialNo}/mini-label`).then(response => response.data.zpl);
  }

  lost(payload: any): Promise<any> {
    return axios.post('/devices/lost', payload).then(resp => resp?.data);
  }

  getReport(deviceId: number, step: string): Promise<DeviceReportFile> {
    return axios
      .get<DeviceReportFile>(`/control-reports/${deviceId}`, { params: { step: step } })
      .then(resp => resp?.data);
  }

  deviceWithoutPurchaseItem(purchase_id: number): Promise<Device> {
    return axios
      .get<Device>(`/devices/without-purchase`, { params: { purchase_id: purchase_id } })
      .then(resp => resp?.data);
  }

  downloadReport(control_report_id: number): Promise<DeviceReportFile> {
    return axios.get<DeviceReportFile>(`/control-reports/download/${control_report_id}`).then(resp => {
      const { base_64, filename, type } = resp?.data || {};
      if (!base_64 || !filename || !type) throw new InvalidReportError(control_report_id);
      return { base_64, filename, type };
    });
  }

  deviceType(device: Device): 'laptop' | 'iMac' {
    return device.model_namestring === 'iMac' ? 'iMac' : 'laptop';
  }

  importUpdateStatus(file: File): Promise<ImportUpdateStatusResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return axios
      .post<ImportUpdateStatusResponse>('/devices/import', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }

  async exist(serialNo: string): Promise<boolean> {
    let exist = true;
    try {
      await axios.get<Device>(`/devices/${serialNo}`);
    } catch (err) {
      exist = false;
    }
    return exist;
  }

  async prepReturnFr(serial_no: string): Promise<Device> {
    if (!serial_no) throw new Error('Missing serial_no in prepReturn');
    const resp = await axios.post<Device>(`/devices/${serial_no}/back_fr`);
    return resp?.data;
  }
}

export const devicesService = new DevicesService();
