import { Module } from 'vuex';

import { deviceFailuresService } from '@/services/device-failures/device-failures.service';
import { DeviceFailureTask, DeviceFailures, Attempt } from '@/services/device-failures/device-failures.types';
import { RootState } from '@/store';

export interface DeviceFailuresState {
  deviceFailures: DeviceFailures[];
  full: boolean;
  deviceFailureLoading: boolean;
}

const state: DeviceFailuresState = {
  deviceFailures: [],
  full: false,
  deviceFailureLoading: false
};

const options: Module<DeviceFailuresState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    get: ({ commit, dispatch }, payload: { serialNo: string; full: boolean }): Promise<void> => {
      const { serialNo, full } = payload;
      commit('full', full);
      commit('deviceFailureLoading', true);
      return deviceFailuresService
        .get(serialNo, full)
        .then(deviceFailures => deviceFailures && commit('deviceFailures', deviceFailures))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },
    createDeviceFailure: ({ commit, dispatch }, deviceFailures: DeviceFailures): Promise<void> => {
      commit('deviceFailureLoading', true);
      return deviceFailuresService
        .create(deviceFailures)
        .then(deviceFailure => deviceFailure && commit('createDeviceFailure', deviceFailure))
        .then(() => dispatch('alert/pushSuccess', 'Panne ajouté avec succès !', { root: true }))
        .then(() =>
          dispatch('failures/setFailureList', { serial_no: deviceFailures.serial_no, full: state.full }, { root: true })
        )
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },
    updateDeviceFailure: ({ commit, dispatch }, payload: { data: DeviceFailures; index: number }): Promise<void> => {
      commit('deviceFailureLoading', true);
      const { data, index } = payload;
      return deviceFailuresService
        .update(state.deviceFailures[index].id, data)
        .then(
          deviceFailure => deviceFailure && commit('editDeviceFailure', { index: index, deviceFailure: deviceFailure })
        )
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },
    deleteDeviceFailures: ({ commit, dispatch }, list: number[]): Promise<void> => {
      commit('deviceFailureLoading', true);
      const serial: string = state.deviceFailures[0].serial_no;
      return deviceFailuresService
        .delete(serial, list)
        .then(deviceFailures => deviceFailures && commit('deviceFailures', deviceFailures))
        .then(() => dispatch('failures/setFailureList', { serial_no: serial, full: state.full }, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },
    validateDiag: (
      { commit, dispatch },
      { id, timer, index }: { id: number; timer: number; index: number }
    ): Promise<void> => {
      commit('deviceFailureLoading', true);
      return deviceFailuresService
        .validateD(id, timer)
        .then(deviceFailure => deviceFailure && dispatch('editDeviceFailures', { index, deviceFailure }))
        .then(() => dispatch('alert/pushSuccess', 'Diagnostique sauvegardé !', { root: true }))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },
    validateRepair: (
      { commit, dispatch },
      { id, timer, index }: { id: number; timer: number; index: number }
    ): Promise<void> => {
      commit('deviceFailureLoading', true);
      return deviceFailuresService
        .validateR(id, timer)
        .then(deviceFailure => deviceFailure && dispatch('editDeviceFailures', { index, deviceFailure }))
        .then(() => dispatch('alert/pushSuccess', 'Réparation sauvegardé !', { root: true }))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },
    validateRepairFromPreparation: ({ commit, dispatch }, deviceFailureId: number): Promise<void> => {
      commit('deviceFailureLoading', true);
      return deviceFailuresService
        .validateFromPreparation(deviceFailureId)
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },
    undoRepairFromPreparation: ({ commit, dispatch }, deviceFailureId: number): Promise<void> => {
      commit('deviceFailureLoading', true);
      return deviceFailuresService
        .undoFromPreparation(deviceFailureId)
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },
    closeDeviceFailures: ({ commit, dispatch }, payload: { index: number; deviceFailureId: number }): Promise<void> => {
      const { index, deviceFailureId } = payload;
      commit('deviceFailureLoading', true);
      return deviceFailuresService
        .closeDeviceFailure(deviceFailureId)
        .then(deviceFailure => deviceFailure && dispatch('editDeviceFailures', { index, deviceFailure }))
        .then(() => dispatch('alert/pushSuccess', 'Réparation terminé !', { root: true }))
        .catch(error => error && dispatch('alert/pusError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },
    editAttempts: (
      { commit, dispatch },
      payload: { index: number; deviceFailureId: number; attempt: Attempt }
    ): Promise<void> => {
      const { index, deviceFailureId, attempt } = payload;
      commit('deviceFailureLoading', true);
      return deviceFailuresService
        .editAttempt(deviceFailureId, attempt)
        .then(deviceFailure => deviceFailure && dispatch('editDeviceFailures', { index, deviceFailure }))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },
    failedRepair: ({ commit, dispatch }, payload: { index: number; deviceFailureId: number }): Promise<void> => {
      const { index, deviceFailureId } = payload;
      commit('deviceFailureLoading', true);
      return deviceFailuresService
        .failedRepair(deviceFailureId)
        .then(deviceFailure => deviceFailure && dispatch('editDeviceFailures', { index, deviceFailure }))
        .then(() => dispatch('alert/pushSuccess', 'Echec de réparation pris en compte !', { root: true }))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },

    editDeviceFailures: ({ commit }, { index, deviceFailure }): void => {
      commit('deviceFailures', [
        ...state.deviceFailures.slice(0, index),
        deviceFailure,
        ...state.deviceFailures.slice(index + 1)
      ]);
    },

    // Task related
    validateTask: ({ commit, dispatch }, payload: { id: number; index: number; data }): Promise<void> => {
      const { id, index, data } = payload;
      commit('deviceFailureLoading', true);
      return deviceFailuresService
        .validateTask(id, data)
        .then(deviceFailure => deviceFailure && dispatch('editDeviceFailures', { index, deviceFailure }))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },
    undoTask: (
      { commit, dispatch },
      payload: { id: number; index: number; task: DeviceFailureTask }
    ): Promise<void> => {
      const { id, index, task } = payload;
      commit('deviceFailureLoading', true);
      return deviceFailuresService
        .undoTask(id, task)
        .then(deviceFailure => deviceFailure && dispatch('editDeviceFailures', { index, deviceFailure }))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },
    nextTask: (
      { commit, dispatch },
      { deviceFailureIndex, response }: { deviceFailureIndex: number; response: boolean }
    ): Promise<DeviceFailureTask> => {
      const tasks = state.deviceFailures[deviceFailureIndex].tasks;
      const id = state.deviceFailures[deviceFailureIndex].tasks[tasks.length - 1].id;
      commit('deviceFailureLoading', true);
      return deviceFailuresService
        .getNextTask(id, response)
        .then(
          deviceFailureTask =>
            deviceFailureTask &&
            commit('addTask', {
              index: deviceFailureIndex,
              task: deviceFailureTask
            })
        )
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceFailureLoading', false));
    },
    clearDeviceFailures: ({ commit }): void => {
      commit('deviceFailures', []);
    }
  },
  mutations: {
    deviceFailures: (state, deviceFailures: DeviceFailures[]) => (state.deviceFailures = deviceFailures),
    createDeviceFailure: (state, deviceFailure: DeviceFailures) => state.deviceFailures.push(deviceFailure),
    editDeviceFailure: (state, payload: { index; deviceFailure }) =>
      (state.deviceFailures[payload.index] = payload.deviceFailure),
    deleteDeviceFailure: (state, index: number) => state.deviceFailures.splice(index, 1),
    addTask: (state, payload: { index; task }) => state.deviceFailures[payload.index].tasks.push(payload.task),
    full: (state, full: boolean) => (state.full = full),
    deviceFailureLoading: (state, loading: boolean) => (state.deviceFailureLoading = loading)
  }
};

export default options;
