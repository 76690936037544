import { Module } from 'vuex';
import { RootState } from '@/store';
import { Part } from '@/services/parts/parts.types';
import { Device } from '@/services/devices/devices.types';
import { PartsService } from '@/services/parts/parts.service';
import { formsService } from '@/services/forms/forms.service';

const partsService = new PartsService();

export interface PartState {
  part: Part;
  partLoading: boolean;
  partLabelLoading: boolean;
  coque: any;
  dalle: any;
  coqueLoading: boolean;
  dalleLoading: boolean;
}

const state: PartState = {
  part: null,
  partLabelLoading: false,
  coque: null,
  dalle: null,
  partLoading: false,
  coqueLoading: false,
  dalleLoading: false
};

const options: Module<PartState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setPart: ({ commit, dispatch }, part: Part | string | number): Promise<void> => {
      if (typeof part === 'number' || typeof part === 'string') return dispatch('getPartByserialNumber', part);
      else commit('setPart', part);
    },
    async fetchParts({ commit, dispatch }): Promise<any> {
      commit('setpartLoading', true);
      try {
        const result = await partsService.list();
        commit('setPart', result);
        return result;
      } catch (error) {
        console.error('An error occurred while fetching parts:', error);
        dispatch('alert/pushError', error, { root: true });
        throw error;
      } finally {
        commit('setPartLoading', false);
      }
    },

    async getpart({ dispatch, commit }, partId: number): Promise<Part> {
      commit('setPartLoading', true);
      return partsService
        .get(partId)
        .then(part => part && commit('setPart', part))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('setPartLoading', false));
    },

    createScreenPart: async ({ commit, dispatch }, device: Device): Promise<Part> => {
      commit('setPartLoading', true);
      try {
        const createdPart = await partsService.dismantleScreen(device.serial_no);
        if (createdPart && createdPart.serial_number) {
          commit('setPart', createdPart);
          dispatch('alert/pushSuccess', 'Écran démonté avec succès !', { root: true });
          return createdPart;
        } else {
          throw new Error('Le numéro de série est manquant ou invalide');
        }
      } catch (error) {
        console.error('Erreur lors de la création:', error);
        dispatch('alert/pushError', error.message || error, { root: true });
        throw error;
      } finally {
        commit('setPartLoading', false);
      }
    },

    async getPartByserialNumber({ dispatch, commit }, serialNumber: string): Promise<Part> {
      commit('setpartLoading', true);
      try {
        const part = await partsService.getBySerialNumber(serialNumber);
        commit('setPart', part);
        return part;
      } catch (error) {
        dispatch('alert/pushError', error, { root: true });
        throw error;
      } finally {
        commit('setPartLoading', false);
      }
    },
    destructed: ({ commit, dispatch }, partId: number): Promise<void> => {
      commit('setPartLoading', true);
      return partsService
        .destructed(partId)
        .then(part => part && commit('setPart', part))
        .then(() => dispatch('alert/pushSuccess', 'Piéce détruite avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('setPartLoading', false));
    },
    printMiniLabel: ({ commit, dispatch }, serialNo: string): Promise<void> => {
      commit('partLabelLoading', true);
      return partsService
        .getMiniLabel(serialNo)
        .then(zpl => zpl && dispatch('printer/printWith', { data: [zpl], printer: 'MINI' }, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('partLabelLoading', false));
    },
    dismantlePart: ({ commit, dispatch }, partId: number): Promise<void> => {
      commit('setPartLoading', true);
      return partsService
        .dismantle(partId)
        .then(part => part && commit('setPart', part))
        .then(() => dispatch('alert/pushSuccess', 'Pièce démontelée avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('setPartLoading', false));
    },

    clearPart({ commit }): void {
      commit('setPart', null);
      commit('setPartLoading', false);
    },

    assembleParts: ({ commit, dispatch }, { dalleSerialNumber, coqueSerialNumber }): Promise<void> => {
      commit('setpartLoading', true);
      return partsService
        .assembleParts(dalleSerialNumber, coqueSerialNumber)
        .then(part => part && commit('setPart', part))
        .then(() => dispatch('alert/pushSuccess', 'Assemblage réussi !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('setPartLoading', false));
    }
  },
  mutations: {
    setPart(state, part: Part): void {
      state.part = part;
    },
    partLabelLoading: (state, loading) => (state.partLabelLoading = loading),
    coque: (state, coque) => (state.coque = coque),
    coqueLoading: (state, loading) => (state.coqueLoading = loading),
    setPartLoading(state, loading): void {
      state.partLoading = loading;
    },
    dalle: (state, dalle) => (state.dalle = dalle),
    dalleLoading: (state, loading) => (state.dalleLoading = loading)
  },
  getters: {
    partForm: (state, getters, rootState) =>
      formsService.getForm('part', state.part, rootState['global-settings'].settings)
  }
};

export default options;
