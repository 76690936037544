import { Module } from 'vuex';

import { marketplaceSkusService } from '@/services/marketplace-skus/marketplace-skus.service';
import { RootState } from '@/store';
import { Alias, Thresholds } from '@/services/marketplace-skus/marketplace-skus.types';
import { formsService } from '@/services/forms/forms.service';

export interface MarketplaceSkuState {
  alias: Alias;
  aliasLoading: boolean;
  thresholds: Thresholds;
  thresholdLoading: boolean;
  marketplaceSkuLoading: boolean;
  marketplaceSkuImporting: boolean;
  marketplaceSkuPriceImporting: boolean;
}

const state: MarketplaceSkuState = {
  alias: null,
  aliasLoading: false,
  thresholds: null,
  thresholdLoading: false,
  marketplaceSkuLoading: false,
  marketplaceSkuImporting: false,
  marketplaceSkuPriceImporting: false
};

const options: Module<MarketplaceSkuState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    importMarketplaceSkusPrice: ({ commit, dispatch }, { file }: { file: File }): Promise<void> => {
      commit('marketplaceSkuPriceImporting', true);
      return marketplaceSkusService
        .importMarketplaceSkusPrice(file)
        .then(resp =>
          dispatch(
            'alert/pushSuccess',
            `${resp.count} correspondance${resp.count > 1 ? 's' : ''} importée${resp.count > 1 ? 's' : ''} !`,
            { root: true }
          )
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('marketplaceSkuPriceImporting', false));
    },
    importMarketplaceSkus: ({ commit, dispatch }, { file }: { file: File }): Promise<void> => {
      commit('marketplaceSkuImporting', true);
      return marketplaceSkusService
        .importMarketplaceSkus(file)
        .then(resp =>
          dispatch(
            'alert/pushSuccess',
            `${resp.count} correspondance${resp.count > 1 ? 's' : ''} importée${resp.count > 1 ? 's' : ''} !`,
            { root: true }
          )
        )
        .then(() => dispatch('table/listRows', null, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('marketplaceSkuImporting', false));
    },
    deleteMarketplaceSku: ({ commit, dispatch }, marketplaceSkuId: number): Promise<void> => {
      commit('marketplaceSkuLoading', true);
      return marketplaceSkusService
        .delete(marketplaceSkuId)
        .then(() => dispatch('alert/pushSuccess', 'Correspondance SKU supprimée !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('marketplaceSkuLoading', false));
    },

    activeThresholds: ({ commit, dispatch, rootGetters }): Promise<void> => {
      commit('thresholdLoading', true);
      return marketplaceSkusService
        .thresholds(rootGetters['table/queryOptions'])
        .then(ths => ths && commit('thresholds', ths))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('thresholdLoading', false));
    },
    addThresholds: ({ commit }, ths: number[]): void => {
      commit('addThreshold', ths);
    },
    uptThresholds: ({ commit }, payload): void => {
      commit('uptThreshold', payload);
    },
    removeThresholds: ({ commit }, index: number): void => {
      commit('rmvThreshold', index);
    },
    saveThresholds: ({ commit, dispatch, rootGetters }, thresholds: number[][]): Promise<void> => {
      commit('thresholdLoading', true);
      return marketplaceSkusService
        .saveThresholds(rootGetters['table/queryOptions'], thresholds)
        .then(ths => ths && commit('thresholds', ths))
        .then(() => dispatch('alert/pushSuccess', 'Seuils sauvegardés avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('thresholdLoading', false));
    },

    activeAlias: ({ commit, dispatch, rootGetters }, prev?: Alias): Promise<void> => {
      if (prev) {
        commit('alias', {});
        commit('alias', prev);
      } else {
        commit('aliasLoading', true);
        return marketplaceSkusService
          .alias(rootGetters['table/queryOptions'])
          .then(alias => alias && commit('alias', alias))
          .catch(error => dispatch('alert/pushError', error, { root: true }))
          .finally(() => commit('aliasLoading', false));
      }
    },
    saveAlias: ({ commit, dispatch, rootGetters }, rule: string): Promise<void> => {
      commit('aliasLoading', true);
      return marketplaceSkusService
        .saveAlias(rootGetters['table/queryOptions'], rule)
        .then(alias => alias && commit('alias', alias))
        .then(() => dispatch('alert/pushSuccess', 'Règle sauvegardée avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('aliasLoading', false));
    }
  },
  mutations: {
    alias: (state, alias: Alias) => (state.alias = alias),
    aliasLoading: (state, loading: boolean) => (state.aliasLoading = loading),
    thresholds: (state, thresholds: Thresholds) => (state.thresholds = thresholds),
    thresholdLoading: (state, loading: boolean) => (state.thresholdLoading = loading),
    addThreshold: (state, thresholds) => state.thresholds.thresholds.push(thresholds),
    uptThreshold: (state, payload) => (state.thresholds.thresholds[payload.index] = payload.ths),
    rmvThreshold: (state, index: number) => state.thresholds.thresholds.splice(index, 1),
    marketplaceSkuLoading: (state, loading: boolean) => (state.marketplaceSkuLoading = loading),
    marketplaceSkuImporting: (state, importing: boolean) => (state.marketplaceSkuImporting = importing),
    marketplaceSkuPriceImporting: (state, importing: boolean) => (state.marketplaceSkuPriceImporting = importing)
  },
  getters: {
    allThresholds: state => state.thresholds.thresholds,
    aliasLoading: state => state.aliasLoading,
    thresholdsLoading: state => state.thresholdLoading,
    aliasForm: (state, getters, rootState) =>
      formsService.getForm('alias', state.alias, rootState['global-settings'].settings, {
        aliasOptions: [
          { value: 'follow_marketplace', label: 'Suivre la place de marché' },
          { value: 'low', label: 'Très précis' },
          { value: 'medium', label: 'Assez précis' },
          { value: 'large', label: 'Peu précis' }
        ]
      })
  }
};

export default options;
