import axios from '@/core/axios';

import { UkInvoice, UkInvoiceListOptions } from './ukInvoices.types';

export class UkInvoicesServices {
  list(opts: UkInvoiceListOptions = {}): Promise<UkInvoice[]> {
    const { filters, pagination } = opts;

    return axios.get<UkInvoice[]>(`/uk-invoices`, { params: { ...filters, ...pagination } }).then(resp => resp?.data);
  }

  update(invoiceId: number, invoice: Partial<UkInvoice>): Promise<UkInvoice> {
    if (!invoiceId) throw new Error('Missing invoice id in update');
    return axios.put<UkInvoice>(`uk-invoices/${invoiceId}`, { invoice }).then(resp => resp?.data);
  }
}

export const ukInvoicesService = new UkInvoicesServices();
