import { CollectionName, CollectionService } from './collections.types';

import { cagesService } from '@/services/cages/cages.service';
import { commissionsService } from '../commissions/commissions.service';
import { consumablesService } from '../consumables/consumables.service';
import { devicesService } from '@/services/devices/devices.service';
import { macStocksService } from '../mac-stocks/mac-stocks.service';
import { marketplaceSkusService } from '@/services/marketplace-skus/marketplace-skus.service';
import { matchErrorsService } from '@/services/match-errors/match-errors.service';
import { matchesService } from '@/services/matches/matches.service';
import { orderLinesService } from '@/services/order-lines/order-lines.service';
import { orderReturnsService } from '@/services/order-returns/order-returns.service';
import { ordersService } from '@/services/orders/orders.service';
import { partsService } from '../parts/parts.service';
import { pickingsService } from '@/services/pickings/pickings.service';
import { productionOrdersService } from '@/services/production-orders/production-orders.service';
import { purchaseQueriesService } from '../purchase-queries/purchase-queries.service';
import { purchasesService } from '../purchases/purchases.service';
import { sparesService } from '@/services/spares/spares.service';
import { suppliersService } from '../suppliers/suppliers.service';
import { ukInvoicesService } from '../ukManagement/ukInvoices/ukInvoices.service';
import { ukTransferService } from '../ukManagement/ukTransfer/ukTransfer.service';
import { specialProcessPriorityService } from '../devices/special-process-priority.service';

class CollectionsService {
  getCollectionService<T extends CollectionName>(collection: CollectionName): CollectionService<T> {
    switch (collection) {
      case 'commissions':
        return commissionsService as CollectionService<T>;
      case 'consumables':
        return consumablesService as CollectionService<T>;
      case 'devices':
        return devicesService as CollectionService<T>;
      case 'dispatch-cages':
        return cagesService as CollectionService<T>;
      case 'mac-stocks':
        return macStocksService as CollectionService<T>;
      case 'marketplace-skus':
        return marketplaceSkusService as CollectionService<T>;
      case 'matches':
        return matchesService as CollectionService<T>;
      case 'match-errors':
        return matchErrorsService as CollectionService<T>;
      case 'orders':
        return ordersService as CollectionService<T>;
      case 'order-lines':
        return orderLinesService as CollectionService<T>;
      case 'order-returns':
        return orderReturnsService as CollectionService<T>;
      case 'parts':
        return partsService as CollectionService<T>;
      case 'pickings':
        return pickingsService as CollectionService<T>;
      case 'production-orders':
        return productionOrdersService as CollectionService<T>;
      case 'purchases':
        return purchasesService as CollectionService<T>;
      case 'purchase-queries':
        return purchaseQueriesService as CollectionService<T>;
      case 'spares':
        return sparesService as CollectionService<T>;
      case 'special-process-priority':
        return specialProcessPriorityService as CollectionService<T>;
      case 'suppliers':
        return suppliersService as CollectionService<T>;
      case 'uk-invoices':
        return ukInvoicesService as CollectionService<T>;
      case 'uk-transfer':
        return ukTransferService as CollectionService<T>;
      default:
        throw new Error('Unknown table collection');
    }
  }
}

export const collectionsService = new CollectionsService();
