import { Module } from 'vuex';

import { gradesService } from '@/services/grades/grades.service';
import { Grade } from '@/services/grades/grades.types';
import { RootState } from '@/store';

export interface GradesState {
  grade: Grade;
  control_fields: string[];
  gradeLoading: boolean;
}

const state: GradesState = {
  grade: null,
  control_fields: [
    'blue_spots',
    'yellow_spots',
    'pink_screen',
    'light_leakage',
    'white_spots',
    'liquid_spots',
    'dust_screen'
  ],
  gradeLoading: false
};

const options: Module<GradesState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    getGrade({ commit, dispatch }, gradeId: number) {
      commit('gradeLoading', true);
      return gradesService
        .get(gradeId)
        .then(grade => grade && commit('grade', grade))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    gradeTask: ({ commit, dispatch }, grade: Grade): Promise<void> => {
      commit('gradeLoading', true);
      return gradesService
        .gradeTask(grade)
        .then(grade => grade && commit('grade', grade))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    createGrade: ({ commit, dispatch }, grade: Grade): Promise<void> => {
      commit('gradeLoading', true);
      return gradesService
        .create(grade)
        .then(grade => grade && commit('grade', grade))
        .then(() => dispatch('alert/pushSuccess', 'Grade créée avec succès !', { root: true }))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    setGrade: ({ commit, dispatch }, gradeId: number): Promise<void> => {
      commit('gradeLoading', true);
      return gradesService
        .setGrade(gradeId)
        .then(grade => grade && commit('grade', grade))
        .then(() => dispatch('alert/pushSuccess', 'Grade mis à jour avec succès !', { root: true }))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    updateGrade: ({ commit, dispatch, state }, payload: { grading: Grade; step: string }): Promise<void> => {
      commit('gradeLoading', true);
      return gradesService
        .update(state.grade.id, payload)
        .then(grade => grade && commit('grade', grade))
        .then(() => dispatch('device/setDevice', state.grade.serial_no, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Grade sauvegardé !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    updateOkamacGrade({ commit, dispatch, state }, newGrade: string) {
      commit('gradeLoading', true);
      return gradesService
        .updateOkamacGrade(state.grade.id, newGrade)
        .then(grade => grade && commit('grade', grade))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    editStateGrade({ commit }, options: { currentStepCode: string; value }) {
      commit('gradeLoading', true);
      const { currentStepCode, value } = options;
      if (value.fieldLabel)
        state.grade[currentStepCode]['fields'][value.fieldBlockLabel][value.fieldLabel] = value.value;
      else {
        state.grade[currentStepCode]['fields'][value.fieldBlockLabel] = value.value;
      }
      commit('gradeLoading', false);
    }
  },
  mutations: {
    grade: (state, grade: Grade) => (state.grade = grade),
    gradeLoading: (state, loading: boolean) => (state.gradeLoading = loading)
  },
  getters: {
    control_fields: state => state.control_fields
  }
};

export default options;
