import { Module } from 'vuex';

import { marketplaceService } from '@/services/marketplaces/marketplaces.service';
import { Marketplace } from '@/services/marketplaces/marketplaces.types';
import { RootState } from '@/store';
import { formsService } from '@/services/forms/forms.service';

export interface MarketplaceState {
  marketplaces: Marketplace[];
  marketplace: Marketplace;
  selectedMp: string[];
  marketplaceLoading: boolean;
}

const state: MarketplaceState = {
  marketplaces: [],
  marketplace: null,
  selectedMp: [],
  marketplaceLoading: false
};

const options: Module<MarketplaceState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setMarketplace: ({ commit, dispatch }, marketplace: Marketplace | number): Promise<void> => {
      if (typeof marketplace === 'number') return dispatch('getMarketplace', marketplace);
      else commit('marketplace', marketplace);
    },
    getMarketplace: ({ commit, dispatch }, marketplaceId: number): Promise<void> => {
      commit('marketplaceLoading', true);
      return marketplaceService
        .get(marketplaceId)
        .then(marketplace => marketplace && commit('marketplace', marketplace))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('marketplaceLoading', false));
    },
    updateMarketplace: ({ commit, dispatch }, marketplace: Marketplace): Promise<void> => {
      commit('marketplaceLoading', true);
      return marketplaceService
        .update(marketplace)
        .then(marketplace => marketplace && dispatch('setMarketplace', marketplace))
        .then(() => dispatch('alert/pushSuccess', 'Marketplace mis à jour !', { root: true }))
        .then(() => dispatch('indexMarketplace'))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('marketplaceLoading', false));
    },
    indexMarketplace: ({ commit, dispatch }): Promise<void> => {
      commit('marketplaceLoading', true);

      return marketplaceService
        .index()
        .then(marketplaces => marketplaces && commit('marketplaces', marketplaces))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('marketplaceLoading', false));
    },
    select: ({ commit }, name: string): void => {
      commit('addMp', name);
    },
    undo: ({ commit }, index: number): void => {
      commit('supMp', index);
    },
    setSelectedMp: ({ commit }, set: string[]): void => {
      commit('selectedMp', set);
    }
  },
  mutations: {
    marketplaces: (state, marketplaces: []) => (state.marketplaces = marketplaces),
    marketplace: (state, marketplace: Marketplace) => (state.marketplace = marketplace),
    selectedMp: (state, selected: []) => (state.selectedMp = selected),
    addMp: (state, name: string) => state.selectedMp.push(name),
    supMp: (state, index: number) => state.selectedMp.splice(index, 1),
    marketplaceLoading: (state, loading: boolean) => (state.marketplaceLoading = loading)
  },
  getters: {
    marketplaceForm: (state, getters, rootState) =>
      formsService.getForm('marketplace', state.marketplace, rootState['global-settings'].settings, {
        aliasOptions: [
          { value: 'no_rules', label: 'Pas de règles' },
          { value: 'low', label: 'Peu précis' },
          { value: 'medium', label: 'Assez précis' },
          { value: 'large', label: 'Très précis' }
        ]
      })
  }
};

export default options;
