import axios from '@/core/axios';

import { Marketplace } from './marketplaces.types';

export class MarketplaceService {
  async index(): Promise<Marketplace> {
    const resp = await axios.get<Marketplace>(`/marketplaces`);
    return resp?.data;
  }

  async get(marketplaceId: number): Promise<Marketplace> {
    const resp = await axios.get<Marketplace>(`/marketplaces/${marketplaceId}`);
    return resp?.data;
  }

  async update(marketplace: Marketplace): Promise<Marketplace> {
    if (!marketplace) throw new Error('Missing date to update Marketplace');
    const resp = await axios.post<Marketplace>(`/marketplaces/${marketplace.id}`, { marketplace });
    return resp?.data;
  }
}

export const marketplaceService = new MarketplaceService();
