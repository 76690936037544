export enum AppSectionCode {
  ADMIN = 'admin',
  ADV = 'adv',
  CONTROL = 'control',
  EXPEDITION = 'dispatch',
  GRADE = 'grade',
  MATCHING = 'matching',
  PREPARATION = 'prepare',
  PURCHASE = 'purchase',
  REPAIR = 'repair',
  SAV = 'sav',
  SPECIAL_PROCESS = 'specialprocess',
  MANAGEMENT_UK = 'management_uk'
}

export interface AppSection {
  public: boolean;
  code: AppSectionCode;
  icon: string; // Font Awesome icon class
  name: string;
  desc: string;
  subSections?: AppSubSection[];
  stockName?: string;
}
export interface AppSubSection {
  code: string;
  name: string;
  public?: boolean;
  desc?: string;
  icon?: string;
  subSections?: AppSubSection[];
}

export type SectionsDico = Record<string, AppSection | AppSubSection>;
