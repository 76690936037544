import { Module } from 'vuex';

import { formsService } from '@/services/forms/forms.service';
import { invoicesService } from '@/services/invoices/invoices.service';
import { Invoice } from '@/services/invoices/invoices.types';
import { ordersService } from '@/services/orders/orders.service';
import { Order } from '@/services/orders/orders.types';
import { RootState } from '@/store';

export interface OrderState {
  order: Order;
  orderLoading: boolean;
  carrierLabelLoading: boolean;
  preparationLabelLoading: boolean;
  invoices: Invoice[];
  invoiceLoading: boolean;
  ordersImporting: boolean;
}

const state: OrderState = {
  order: null,
  orderLoading: false,
  carrierLabelLoading: false,
  preparationLabelLoading: false,
  invoices: [],
  invoiceLoading: false,
  ordersImporting: false
};

const options: Module<OrderState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setOrder: ({ commit, dispatch }, order: Order | string | number): Promise<void> => {
      if (typeof order === 'number' || typeof order === 'string') return dispatch('getOrder', order);
      else commit('order', order);
    },
    newOrder: ({ commit, dispatch }): Promise<void> => {
      commit('order', null);
      commit('orderLoading', true);
      return ordersService
        .newOrder()
        .then(order => order && commit('order', order))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderLoading', false));
    },
    createOrder: ({ commit, dispatch }, order: Order): Promise<void> => {
      commit('orderLoading', true);
      return ordersService
        .create(order)
        .then(order => order && commit('order', order))
        .then(() => dispatch('alert/pushSuccess', 'Commande créée avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderLoading', false));
    },
    updateOrder: ({ commit, dispatch }, order: Order): Promise<void> => {
      commit('orderLoading', true);
      return ordersService
        .update(order.id, order)
        .then(order => order && commit('order', order))
        .then(() => dispatch('alert/pushSuccess', 'Commande mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderLoading', false));
    },
    deleteOrder: ({ commit, dispatch }, orderId: number): Promise<void> => {
      commit('orderLoading', true);
      return ordersService
        .delete(orderId)
        .then(() => commit('order', null))
        .then(() => dispatch('alert/pushSuccess', 'Commande supprimée !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderLoading', false));
    },
    getOrder: ({ commit, dispatch }, orderId: number): Promise<void> => {
      commit('orderLoading', true);
      return ordersService
        .get(orderId)
        .then(order => order && commit('order', order))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderLoading', false));
    },
    getOrderByMpOrderId: ({ commit, dispatch }, marketplaceOrderId: string): Promise<void> => {
      commit('orderLoading', true);
      return ordersService
        .byMpOrderId(marketplaceOrderId)
        .then(order => order && commit('order', order))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderLoading', false));
    },
    duplicateOrder: ({ commit, dispatch, getters }, order: Order): Promise<void> => {
      commit('order', order);
      return dispatch('createOrder', getters.orderCreationForm.value);
    },
    printPreparationLabel: ({ commit, dispatch }, orderId: number): Promise<void> => {
      commit('preparationLabelLoading', true);
      return ordersService
        .getPreparationLabel(orderId)
        .then(
          resp => resp?.zpl && dispatch('printer/printWith', { data: [resp.zpl], printer: 'PREPA' }, { root: true })
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('preparationLabelLoading', false));
    },
    saveAndPrintInvoice: ({ commit, dispatch }, orderId: number): Promise<void> => {
      commit('invoiceLoading', true);
      return ordersService
        .getInvoice(orderId)
        .then(invoice =>
          Promise.all([
            invoice &&
              dispatch(
                'printer/printWith',
                { data: [{ type: 'pdf', format: 'base64', data: invoice.base_64 }] },
                { root: true }
              ),
            dispatch('alert/pushInfo', 'Impression de la facture...', { root: true })
          ])
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('invoiceLoading', false));
    },
    printInvoice: ({ commit, dispatch }, invoiceId: number): Promise<void> => {
      commit('invoiceLoading', true);
      return ordersService
        .downloadInvoice(invoiceId)
        .then(invoice =>
          Promise.all([
            invoice &&
              dispatch(
                'printer/printWith',
                { data: [{ type: 'pdf', format: 'base64', data: invoice.base_64 }] },
                { root: true }
              ),
            dispatch('alert/pushInfo', 'Impression de la facture...', { root: true })
          ])
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('invoiceLoading', false));
    },
    saveInvoice: ({ commit, dispatch }, invoiceId: number): Promise<void> => {
      commit('invoiceLoading', true);
      return ordersService
        .downloadInvoice(invoiceId)
        .then(invoice => invoice && dispatch('file/saveBase64As', invoice, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('invoiceLoading', false));
    },
    generateNewInvoice: ({ commit, dispatch }, orderId: number): Promise<void> => {
      commit('invoiceLoading', true);
      return ordersService
        .getInvoice(orderId)
        .then(invoice => invoice && dispatch('file/saveBase64As', invoice, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('invoiceLoading', false));
    },
    importOrders: ({ commit, dispatch }, { marketplace, file }: { marketplace: string; file: File }): Promise<void> => {
      commit('ordersImporting', true);
      return ordersService
        .importOrders(marketplace, file)
        .then(resp =>
          dispatch(
            'alert/pushSuccess',
            `${resp.count} commande${resp.count > 1 ? 's' : ''} importée${resp.count > 1 ? 's' : ''} !`,
            { root: true }
          )
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('ordersImporting', false));
    },
    getOrderInvoices: ({ commit, dispatch }, orderId: number): Promise<void> => {
      commit('invoiceLoading', true);
      return invoicesService
        .list(orderId)
        .then(invoices => invoices && commit('invoices', invoices))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('invoiceLoading', false));
    }
  },
  mutations: {
    order: (state, order) => (state.order = order),
    orderLoading: (state, loading) => (state.orderLoading = loading),
    ordersImporting: (state, importing) => (state.ordersImporting = importing),
    carrierLabelLoading: (state, loading) => (state.carrierLabelLoading = loading),
    preparationLabelLoading: (state, loading) => (state.preparationLabelLoading = loading),
    invoices: (state, loading) => (state.invoices = loading),
    invoiceLoading: (state, loading) => (state.invoiceLoading = loading)
  },
  getters: {
    orderCreationForm: (state, getters, rootState) =>
      formsService.getForm('order-creation', state.order, rootState['global-settings'].settings, {
        marketplaces: rootState['marketplace'].marketplaces
      }),
    orderForm: (state, getters, rootState, rootGetters) =>
      formsService.getForm('order', state.order, rootState['global-settings'].settings, {
        UserCanUpdateOrderShippingDate: rootGetters['user/canUpdateOrderShippingDate'],
        marketplaces: rootState['marketplace'].marketplaces
      })
  }
};

export default options;
