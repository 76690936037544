import axios from '@/core/axios';

import { Device, DevicesListOptions } from './devices.types';

export class SpecialProcessPriorityService {
  async list(opts: DevicesListOptions = {}): Promise<Device[]> {
    const { filters, pagination } = opts;
    const resp = await axios.get<Device[]>('/devices/special-process-priority', {
      params: { ...filters, ...pagination }
    });
    return resp?.data;
  }

  async update(): Promise<void> {
    return; // ici rien est fait parce que pas besoin d'edit les ordis transfer mais besoin pr le fonctionnement interne de Table
  }
}

export const specialProcessPriorityService = new SpecialProcessPriorityService();
