import { TablePaginationOptions } from '../tables/tables.types';

export interface Notification {
  createdAt: Date;
  id: string;
  readAt: Date;
  text: string;
  type: string;
}

export enum UserDivision {
  CONTROL = 'Contrôle',
  DISPATCH = 'Expédition',
  PREPARATION = 'Préparation'
}

export enum UserCompanyRole {
  ADMIN = 'Admin',
  DIRECTION = 'Direction',
  TECHNICIEN = 'Technicien'
}

export enum UserRoleType {
  ACHATS_MEMBER = 'achats_member',
  ADMIN = 'admin',
  ADV_MEMBER = 'adv_member',
  ASSOCIATION_MEMBER = 'association_member',
  B2B_MEMBER = 'b2b_member',
  CARRIER_LABEL_PRINTER = 'carrier_label_printer',
  DATABASE_ADMIN = 'database_admin',
  DEVICE_DELETER = 'device_deleter',
  EXPORTS_ADMIN = 'exports_admin',
  FAILURE_DELETER = 'failure_deleter',
  FINISH_CONTROL = 'finish_control',
  HANDLING_MEMBER = 'handling_member',
  MASS_STATUS_CHANGE = 'mass_status_change',
  MANAGEMENT_UK_ACCESS = 'management_uk_access',
  ORDER_DELETER = 'order_deleter',
  ORDER_SHIPPING_DATE_UPDATER = 'order_shipping_date_updater',
  PREPARATION_MASTER = 'preparation_master',
  PRODUCTION_ORDERS = 'production_orders',
  RC_MEMBER = 'rc_member',
  RESET_CARRIER_LABEL = 'reset_carrier_label',
  RND_MEMBER = 'rnd_member',
  SAV_MEMBER = 'sav_member',
  SHIPPING_MASTER = 'shipping_master',
  SPECIAL_PROCESS_ACCESS = 'special_process_access',
  TECHNICAL_ALL_MANAGER = 'technical_all_manager',
  TECHNICAL_ALL_SCANNER = 'technical_all_scanner',
  TECHNICAL_SELF_MANAGER = 'technical_self_manager',
  TECHNICAL_SELF_SCANNER = 'technical_self_scanner',
  UNMATCHER = 'unmatcher'
}

export interface UserRole {
  name: UserRoleType;
}

export interface User {
  avatar_url: string;
  company_role: UserCompanyRole;
  created_at: string;
  division: UserDivision;
  email: string;
  first_name: string;
  id?: string;
  last_name: string;
  notifications: Notification[];
  roles: UserRole[];
  session_id: string;
  updated_at: string;
  userid: string;
}

export interface UsersList {
  count: number;
  values: User[];
}

export interface UsersListOptions {
  filters?: Partial<Record<keyof User, string[]>>;
  pagination?: TablePaginationOptions;
}

export interface ChangePasswordOptions {
  current_password: string;
  new_password: string;
  new_password_conf: string;
}
