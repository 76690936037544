import axios from '@/core/axios';
import { PartFailure } from './parts-failures.types';

export class PartFailuresService {
  create(partFailureData: { part_serial_no: string; serial_no: string }): Promise<PartFailure> {
    if (!partFailureData) throw new Error('Missing partsFailureData in create');
    return axios
      .post<PartFailure>('/parts_failures', partFailureData)
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        console.error('Error during the creation of partFailures: ', error);
        throw error;
      });
  }
}
export const partFailuresService = new PartFailuresService();
