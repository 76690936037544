import axios from '@/core/axios';

import { InvalidUkInvoiceError, UkTransfer, UkTransferInvoiceFile, UkTransferListOptions } from './ukTransfer.types';

export class UkTransferServices {
  list(opts: UkTransferListOptions): Promise<UkTransfer[]> {
    const { filters, pagination } = opts;

    return axios.get<UkTransfer[]>(`/uk-transfers`, { params: { ...filters, ...pagination } }).then(resp => resp?.data);
  }

  update(): Promise<void> {
    return; // ici rien est fait parce que pas besoin d'edit les ordis transfer mais besoin pr le fonctionnement interne de Table
  }

  async getInvoice(): Promise<UkTransferInvoiceFile> {
    const resp = await axios.get<UkTransferInvoiceFile>(`invoices/uk/invoice`);
    const { base_64, filename, type } = resp?.data || {};
    if (!base_64 || !filename || !type) throw new InvalidUkInvoiceError();
    return { base_64, filename, type };
  }

  async getCreditNote(): Promise<number> {
    const resp = await axios.get<number>(`invoices/uk/credit_note`);
    return resp?.data;
  }
}

export const ukTransferService = new UkTransferServices();
