import axios from '@/core/axios';
import { Part, PartsListOptions } from './parts.types';
import { CollectionList } from '../collections/collections.types';

export class PartsService {
  list(opts: PartsListOptions = {}): Promise<CollectionList<'parts'>> {
    const { filters, pagination } = opts;
    return axios.get<CollectionList<'parts'>>(`/parts`, { params: { ...filters, ...pagination } }).then(resp => {
      return resp?.data;
    });
  }
  getBySerialNumber(serialNumber: string): Promise<Part> {
    return axios
      .get<Part>(`/parts/${serialNumber}/scan`)
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        console.error('Error fetching part by serial number:', error);
        throw error;
      });
  }

  dismantleScreen(serial_number: string): Promise<Part> {
    return axios
      .get<Part>(`/parts/screen/${serial_number}`)
      .then(resp => resp.data)
      .catch(error => {
        console.error('Erreur lors de la création de la pièce:', error);
        throw error;
      });
  }
  get(partId: number): Promise<Part> {
    return axios.get<Part>(`/parts/${partId}`).then(resp => resp?.data);
  }

  getMiniLabel(serialNo: string): Promise<string> {
    return axios.get(`/parts/${serialNo}/mini-label`).then(response => response.data.zpl);
  }

  update(partId: number | string, part: Partial<Part>): Promise<Part> {
    if (!partId) throw new Error('Missing part id in update');
    return axios.put<Part>(`parts/${partId}`, { part }).then(resp => resp?.data);
  }
  destructed(partId: number): Promise<Part> {
    if (!partId) throw new Error('Missing parts id in validate');
    return axios
      .put<Part>(`parts/${partId}/destructed`)
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        console.error('Failed to update part:', error);
        throw error;
      });
  }

  dismantle(partId: number): Promise<Part> {
    if (!partId) throw new Error('Missing part ID for dismantle');
    return axios
      .post<Part>(`/parts/${partId}/dismantle`)
      .then(resp => resp.data)
      .catch(error => {
        console.error('Failed to dismantle part:', error);
        throw error;
      });
  }
  assembleParts(dalleSerialNumber: string, coqueSerialNumber: string): Promise<Part> {
    return axios
      .post<Part>(`/parts/assemble`, {
        dalle_serial_number: dalleSerialNumber,
        coque_serial_number: coqueSerialNumber
      })
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        console.error('Failed to assemble parts:', error);
        throw error;
      });
  }
}
export const partsService = new PartsService();
