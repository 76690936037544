import { Module } from 'vuex';

import { RootState } from '@/store';
import { ukTransferService } from '@/services/ukManagement/ukTransfer/ukTransfer.service';
import { devicesService } from '@/services/devices/devices.service';

export interface UkTransferState {
  ukInvoiceLoading: boolean;
  ukCreditNoteLoading: boolean;
  prepReturnLoading: boolean;
}

const state: UkTransferState = {
  ukInvoiceLoading: false,
  ukCreditNoteLoading: false,
  prepReturnLoading: false
};

const options: Module<UkTransferState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    generateInvoice: ({ commit, dispatch }): Promise<void> => {
      commit('ukInvoiceLoading', true);

      return ukTransferService
        .getInvoice()
        .then(invoice => invoice && dispatch('file/saveBase64As', invoice, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('ukInvoiceLoading', false));
    },
    generateCreditNote: ({ commit, dispatch }): Promise<void> => {
      commit('ukCreditNoteLoading', true);

      return ukTransferService
        .getCreditNote()
        .then(
          nbNote => nbNote && dispatch('alert/pushSuccess', 'Création de ' + nbNote + ' avoir(s) !', { root: true })
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('ukInvoiceLoading', false));
    },
    prepReturn: ({ commit, dispatch }, serial_no: string): Promise<void> => {
      commit('prepReturnLoading', true);

      return devicesService
        .prepReturnFr(serial_no)
        .then(device => device && dispatch('device/setDevice', device, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Validation du retour en France !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('prepReturnLoading', false));
    }
  },
  mutations: {
    ukInvoiceLoading: (state, loading: boolean) => (state.ukInvoiceLoading = loading),
    ukCreditNoteLoading: (state, loading: boolean) => (state.ukCreditNoteLoading = loading),
    prepReturnLoading: (state, loading: boolean) => (state.prepReturnLoading = loading)
  }
};

export default options;
