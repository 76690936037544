import { get, isArray, isNil, isPlainObject } from 'lodash';

import { Alias } from '../marketplace-skus/marketplace-skus.types';
import { ChangePasswordOptions } from '@/services/users/users.types';
import { Cage, CageCarrierLabel } from '../cages/cages.types';
import { Commission } from '../commissions/commissions.types';
import { Collection, CollectionName } from '../collections/collections.types';
import { consumablesService } from '../consumables/consumables.service';
import { DataExportFilters } from '../data-exports/data-exports.types';
import { Device, RelocateOptions } from '../devices/devices.types';
import { dicoLabelsService } from '../dico-labels/dico-labels.service';
import { GlobalSettings } from '../global-settings/global-settings.types';
import { MarketplaceSkuImportOptions } from '../marketplace-skus/marketplace-skus.types';
import {
  ReportMatchErrorOptions,
  SkuDifferenceCostImportOptions,
  ImportUpdateStatusOptions
} from '../matches/matches.types';
import { FilterOptions } from '../options/options.types';
import { OrderLine } from '../order-lines/order-lines.types';
import {
  OrderReturn,
  OrderReturnExchangeOptions,
  OrderReturnReceptionOptions
} from '../order-returns/order-returns.types';
import { Order, OrderAddress, OrdersImportOptions } from '../orders/orders.types';
import { Picking } from '../pickings/pickings.types';
import { ProductionOrder } from '../production-orders/production-orders.types';
import { PurchaseQuery } from '../purchase-queries/purchase-queries.types';
import {
  Purchase,
  PurchaseDealOptions,
  PurchasePauseOptions,
  PurchasePaymentEffectiveOptions,
  PurchasePaymentScheduleOptions,
  PurchaseRMACreationOptions
} from '../purchases/purchases.types';
import { sparesService } from '../spares/spares.service';
import { StockStatus } from '../stocks-status/stocks-status.types';
import { suppliersService } from '../suppliers/suppliers.service';
import { Supplier, SupplierRib } from '../suppliers/suppliers.types';
import { FormControlOption, FormControlRaw } from './form-control.types';
import { Form } from './form.class';
import { stocksStatusService } from '../stocks-status/stocks-status.service';
import { Marketplace } from '../marketplaces/marketplaces.types';
import { Part } from '../parts/parts.types';
import { purchasesService } from '../purchases/purchases.service';
import { datesService } from '../dates/dates.service';
import { devicesService } from '../devices/devices.service';

type DeviceFormType = 'control' | 'control-relocate' | 'device' | 'device-creation';
type OrderLineFormType = 'order-line' | 'order-line-creation' | 'order-line-exchange-creation';
type OrderFormType = 'order' | 'order-creation';
type OrderReturnFormType = 'order-return' | 'order-return-creation';
type ProductionOrderLineFormType = 'production-order' | 'production-order-line-creation';
type PickingFormType = 'picking' | 'picking-creation';
type CageFormType = 'cage' | 'cage-creation';
type CommissionFormType = 'commission' | 'commission-creation';
type MarketplaceFormType = 'marketplace';
type AliasFormType = 'alias';
type PartFormType = 'part';
type FormType =
  | DeviceFormType
  | OrderLineFormType
  | OrderFormType
  | OrderReturnFormType
  | ProductionOrderLineFormType
  | PickingFormType
  | CageFormType
  | CommissionFormType
  | MarketplaceFormType
  | AliasFormType
  | PartFormType
  | 'purchase-query'
  | 'purchase'
  | 'supplier'
  | 'supplier-display'
  | 'supplier-creation'
  | 'purchase-reception'
  | 'purchase-creation'
  | 'cage-carrier-label-display';

class FormsService {
  getForm(form: DeviceFormType, value: Device, globalSettings: GlobalSettings): Form<Device>;
  getForm(
    form: OrderLineFormType,
    value: OrderLine,
    globalSettings: GlobalSettings,
    options?
  ): Form<OrderLine & OrderAddress>;
  getForm(form: OrderFormType, value: Order, globalSettings: GlobalSettings, options?): Form<Order & OrderAddress>;
  getForm(
    form: OrderReturnFormType,
    value: OrderReturn,
    globalSettings: GlobalSettings,
    options?
  ): Form<OrderReturn & OrderAddress>;
  getForm(
    form: ProductionOrderLineFormType,
    value: ProductionOrder,
    globalSettings: GlobalSettings,
    options?
  ): Form<ProductionOrder>;
  getForm(form: CageFormType, value: Cage, globalSettings: GlobalSettings, options?): Form<Cage>;
  getForm(form: PickingFormType, value: Picking, globalSettings: GlobalSettings, options?): Form<Picking>;
  getForm(form: PartFormType, value: Part, globalSettings: GlobalSettings, options?): Form<Part>;
  getForm(form: 'purchase-reception', value: Purchase, globalSettings: GlobalSettings): Form<Purchase>;
  getForm(form: 'purchase-query', value: PurchaseQuery, globalSettings: GlobalSettings): Form<PurchaseQuery>;
  getForm(form: 'purchase', value: Purchase, globalSettings: GlobalSettings): Form<Purchase>;
  getForm(form: 'supplier', value: Supplier, globalSettings: GlobalSettings): Form<Supplier>;
  getForm(form: 'supplier-display', value: Supplier, globalSettings: GlobalSettings): Form<Supplier>;
  getForm(form: 'supplier-creation', value: Supplier, globalSettings: GlobalSettings): Form<Supplier>;
  getForm(form: 'purchase-creation', value: Purchase, globalSettings: GlobalSettings): Form<Purchase>;
  getForm(
    form: 'cage-carrier-label-display',
    value: CageCarrierLabel,
    globalSettings: GlobalSettings
  ): Form<CageCarrierLabel>;
  getForm(form: CommissionFormType, value: Commission, globalSettings: GlobalSettings, options?): Form<Commission>;
  getForm(form: MarketplaceFormType, value: Marketplace, globalSettings: GlobalSettings, options?): Form<Marketplace>;
  getForm(form: AliasFormType, value: Alias, globalSettings: GlobalSettings, options?): Form<Alias>;
  getForm(form: FormType, value: any, globalSettings: GlobalSettings, options?): Form<any> {
    if (!value) return null;
    if (form === 'device-creation' || form === 'control' || form === 'device')
      return this.getDeviceForm(value, form, globalSettings);

    if (form === 'cage') return this.getCageForm();
    if (form === 'cage-carrier-label-display') return this.getCageCarrierLabelDisplayForm(value, globalSettings);
    if (form === 'cage-creation') return this.getCageCreationForm(value, globalSettings);
    if (form === 'commission') return this.getCommissionForm(value);
    if (form === 'commission-creation') return this.getCommissionCreationForm(value, globalSettings, options);
    if (form === 'control-relocate') return this.getControlRelocateForm(value, globalSettings);
    if (form === 'order-line-creation') return this.getOrderLineCreationForm(value, globalSettings);
    if (form === 'order-line-exchange-creation') return this.getOrderLineExchangeCreationForm(value, globalSettings);
    if (form === 'order-return-creation') return this.getOrderReturnCreationForm(value, globalSettings, options);
    if (form === 'order-line') return this.getOrderLineForm(value, globalSettings, options);
    if (form === 'order') return this.getOrderForm(value, globalSettings, options);
    if (form === 'order-creation') return this.getOrderCreationForm(value, globalSettings, options);
    if (form === 'order-return') return this.getOrderReturnForm(value, globalSettings, options);
    if (form === 'production-order-line-creation') return this.getProductionOrderCreationForm(value);
    if (form === 'production-order') return this.getProductionOrderForm(value);
    if (form === 'purchase') return this.getPurchaseForm(value, globalSettings);
    if (form === 'purchase-query') return this.getPurchaseQueryForm(value);
    if (form === 'supplier') return this.getSupplierForm(value, globalSettings);
    if (form === 'supplier-display') return this.getSupplierDisplayForm(value, globalSettings);
    if (form === 'supplier-creation') return this.getSupplierCreationForm(value, globalSettings);
    if (form === 'part') return this.getPartForm(value);
    if (form === 'purchase-reception') return this.getReceptionForm(value);
    if (form === 'purchase-creation') return this.getPurchaseCreationForm(value, globalSettings);
    if (form === 'picking-creation') return this.getPickingCreationForm(value);
    if (form === 'picking') return this.getPickingForm(value);
    if (form === 'marketplace') return this.getMarketplaceForm(value, globalSettings, options);
    if (form === 'alias') return this.getAliasForm(value, globalSettings, options);
  }

  getFiltersForm<T extends CollectionName>(
    values: FilterOptions<T>,
    filters: FilterOptions<T>,
    settings: GlobalSettings
  ) {
    if (!filters || !settings) return null;
    const form = new Form<Collection<T>>([
      {
        name: 'filters',
        size: 'medium',
        controls: Object.keys(filters).map(option => {
          if (isArray(filters[option])) {
            const value = isNil(values[option]) ? ['all'] : isArray(values[option]) ? values[option] : [values[option]];
            return {
              name: option,
              label: dicoLabelsService.getCollectionLabel(option, 'long'),
              type: 'multi-dropdown',
              options: this.getFilterOptions(option, filters[option], settings),
              value
            };
          } else if (isPlainObject(filters[option])) {
            const { min, max, init } = filters[option];
            const value = isNil(values[option]) ? init : values[option];

            return {
              name: option,
              label: dicoLabelsService.getCollectionLabel(option, 'long'),
              type: 'slider',
              min,
              max,
              value
            };
          } else throw new Error('Format de filtre invalide');
        })
      }
    ]);

    return form;
  }

  getReportMatchErrorForm(
    init: { stockId?: string | number; stepCode?: string },
    device: Device,
    globalSettings: GlobalSettings,
    options: {
      disableStockDropdown?: boolean;
      disableFutureSteps?: boolean;
      stocksSubSet?: [number, StockStatus][];
      is_manager?: boolean;
    }
  ): Form<Omit<ReportMatchErrorOptions, 'dissociate'>> {
    return new Form<Omit<ReportMatchErrorOptions, 'dissociate'>>([
      {
        name: 'report-match-error',
        controls: [
          ...this.getRelocateControls(init, device, globalSettings, options),
          {
            name: 'comment',
            label: 'Commentaire',
            type: 'text-multiline',
            required: true
          }
        ]
      }
    ]);
  }

  getRelocateForm(
    init: { stockId?: string | number; stepCode?: string; matchId?: number },
    device: Device,
    globalSettings: GlobalSettings,
    is_manager?: boolean
  ): Form<RelocateOptions & { stepCode: string }> {
    const { matchId } = init;
    const options = { disableStocksWithSteps: !matchId, is_manager: is_manager };
    return new Form<RelocateOptions & { stepCode: string }>([
      {
        name: 'relocate',
        controls: [...this.getRelocateControls(init, device, globalSettings, options)]
      }
    ]);
  }

  getDuplicationForm(): Form<{ nTimes: number }> {
    return new Form<{ nTimes: number }>([
      {
        name: 'duplication',
        controls: [
          {
            name: 'n_times',
            label: '',
            value: 1,
            type: 'number',
            required: true,
            unit: 'fois',
            step: '1',
            min: 1,
            max: 500
          }
        ]
      }
    ]);
  }

  getChangePasswordForm(): Form<ChangePasswordOptions> {
    return new Form<ChangePasswordOptions>([
      {
        name: 'change_password',
        controls: [
          {
            name: 'current_password',
            label: 'Mot de passe actuel',
            type: 'password',
            required: true
          },
          {
            name: 'new_password',
            label: 'Nouveau mot de passe',
            type: 'password',
            subtext: '(8 car. min, majuscule, minuscule, chiffre et caractère spécial obligatoire)',
            required: true
          },
          {
            name: 'new_password_conf',
            label: 'Nouveau mot de passe (confirmation)',
            type: 'password',
            required: true
          }
        ]
      }
    ]);
  }

  getDataExportForm(): Form<DataExportFilters> {
    return new Form<DataExportFilters>([
      {
        name: 'export',
        label: 'Export de données',
        controls: [
          {
            name: 'start_date',
            label: dicoLabelsService.getCollectionLabel('start_date'),
            type: 'datetime-local',
            value: new Date().toISOString(),
            required: true,
            split: 2
          },
          {
            name: 'end_date',
            label: dicoLabelsService.getCollectionLabel('end_date'),
            type: 'datetime-local',
            value: new Date().toISOString(),
            required: true,
            split: 2
          },
          {
            name: 'collection',
            label: 'Collection',
            type: 'dropdown',
            required: true,
            options: [
              { value: 'device_stock', label: "Stock d'ordinateurs" },
              { value: 'device_stock_adv', label: "Stock d'ordinateurs ADV" },
              { value: 'accessory_stock', label: "Stock d'accessoires" },
              { value: 'device_orders', label: "Commandes d'ordinateurs" },
              { value: 'accessory_orders', label: "Commandes d'accessoires" },
              { value: 'orders_adv', label: 'Commandes ADV' },
              { value: 'stock_movements', label: 'Mouvements de stock' },
              { value: 'controls', label: 'Contrôles' },
              { value: 'preparations', label: 'Préparations' },
              { value: 'shipments', label: 'Expéditions' },
              { value: 'process_errors', label: 'Erreurs de process' }
            ]
          }
        ]
      }
    ]);
  }
  private getReceptionForm(purchase: Purchase): Form<Purchase> {
    return new Form<Purchase>([
      {
        name: 'reception',
        controls: [
          {
            name: 'nb_received_packaging',
            label: dicoLabelsService.getCollectionLabel('nb_received_packaging', 'long'),
            value: purchase.nb_received_packaging,
            type: 'number',
            required: true,
            split: 2
          },
          {
            name: 'reserve_reception',
            label: dicoLabelsService.getCollectionLabel('reserve_reception'),
            type: 'toggle',
            options: [
              { value: 'true', label: 'Oui' },
              { value: 'false', label: 'Non' }
            ],
            value: purchase.reserve_reception,
            split: 2,
            afterChange: async (value: string, group) => {
              group.get('reserve_detail').hidden = Boolean(!value);
              group.get('photos').hidden = Boolean(!value);
            }
          },
          {
            name: 'photos',
            value: purchase.photos,
            fileName: purchase.reference_internal,
            type: 'photo'
          },
          {
            name: 'reserve_detail',
            label: dicoLabelsService.getCollectionLabel('reserve_detail'),
            value: purchase.reserve_detail,
            type: 'text-multiline',
            required: true
          }
        ]
      }
    ]);
  }

  private getPurchaseCreationForm(purchase: Purchase, settings: GlobalSettings): Form<Purchase> {
    if (!settings) return;
    const statusIndex = ['negotiation', /*'to_pay',*/ 'shipping', 'received', 'control', 'validated'].indexOf(
      purchase.purchase_status
    );

    return new Form<Purchase>([
      {
        name: 'supplier',
        label: '<i class="fas fa-user-circle"></i> Fournisseur',
        controls: [
          {
            name: 'supplier_id',
            label: dicoLabelsService.getCollectionLabel('last_name', 'short'),
            type: 'dropdown',
            options: [],
            value: purchase.supplier?.id,
            disabled: statusIndex > 0,
            required: true,
            afterChange: (value: string, group) => {
              const options = group.get('supplier_id').options;
              if (!options?.length) {
                // Options init
                return suppliersService.order_list({ filters: { category: 'device' } }).then(resp => {
                  const suppliers = resp.values || [];

                  if (!suppliers) throw new Error('Impossible de récupérer la liste des fournisseurs');
                  group.get('supplier_id').options = suppliers?.map(value => ({
                    value: value.id,
                    label: value.display_name,
                    details: value
                  }));
                });
              } else {
                const option = options.find(opt => opt.value === value);
                group.get('supplier_country').value = option?.details?.country;
                group.get('currency').value = option?.details?.currency;
                group.get('tax_scheme').value = option?.details?.tax_scheme;
                group.get('payment_timing').value = option?.details?.payment_timing;
                group.get('payment_delay').value = option?.details?.payment_delay;
                group.get('payment_delay').hidden = option?.details?.payment_timing !== 'delay';
              }
            }
          },
          {
            name: 'item_type',
            type: 'text',
            value: 'device',
            hidden: true
          },
          {
            name: 'supplier_country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'text',
            value: purchase.supplier?.country,
            required: true,
            split: 3
          },
          {
            name: 'currency',
            label: dicoLabelsService.getCollectionLabel('currency', 'long'),
            type: 'dropdown',
            value: purchase.currency,
            disabled: false,
            options: [
              { value: 'eur', label: 'EUR' },
              { value: 'gbp', label: 'GBP' },
              { value: 'usd', label: 'USD' }
            ],
            required: true,
            split: 3
          },
          {
            name: 'tax_scheme',
            label: dicoLabelsService.getCollectionLabel('tax_scheme', 'short'),
            value: purchase.tax_scheme,
            type: 'dropdown',
            options: settings.schemes.map((scheme, index) => ({
              value: scheme[0],
              label: scheme[1],
              indexWeight: index
            })),
            disabled: false,
            required: true,
            split: 3
          },
          {
            name: 'incoterm',
            label: dicoLabelsService.getCollectionLabel('incoterm', 'short'),
            value: purchase.incoterm,
            type: 'dropdown',
            options: ['CIF', 'CIP', 'CFR', 'CPT', 'DAP', 'DDP', 'DPU', 'EXW', 'FAS', 'FCA', 'FOB'].map(
              (item, index) => ({
                value: item,
                label: item,
                indexWeight: index
              })
            ),
            disabled: false,
            required: false,
            split: 2
          },
          {
            name: 'reference_internal',
            label: dicoLabelsService.getCollectionLabel('reference_internal', 'long'),
            type: 'text',
            value: purchase.reference_internal,
            disabled: true,
            required: false,
            split: 2
          },
          {
            name: 'reference_supplier',
            label: dicoLabelsService.getCollectionLabel('reference_supplier', 'long'),
            type: 'text',
            value: purchase.reference_supplier,
            disabled: false,
            required: false,
            split: 2
          },
          ...(statusIndex === 0
            ? ([
                {
                  name: 'payment_timing',
                  label: dicoLabelsService.getCollectionLabel('payment_timing', 'long'),
                  type: 'dropdown',
                  value: purchase.payment_timing || purchase.supplier?.payment_timing,
                  options: [
                    { value: 'before_reception', label: 'Avant expédition' },
                    { value: 'at_reception', label: 'A la livraison' },
                    { value: 'delay', label: 'A X jours' }
                  ],
                  required: true,
                  split: 2,
                  afterChange: async (value: string, group) => {
                    group.get('payment_delay').hidden = value !== 'delay';
                  }
                },
                {
                  name: 'payment_delay',
                  label: dicoLabelsService.getCollectionLabel('payment_delay', 'long'),
                  type: 'number',
                  value: purchase.payment_delay || purchase.supplier?.payment_delay,
                  required: true
                }
              ] as FormControlRaw<Purchase>[])
            : []),
          {
            name: 'purchase_types',
            label: dicoLabelsService.getCollectionLabel('purchase_types'),
            type: 'dropdown',
            value: purchase.purchase_types,
            options: settings.purchase_types.map(type => ({
              value: type[0],
              label: type[1]
            })),
            required: true,
            split: 1
          }
        ]
      }
    ]);
  }
  private getSupplierCreationForm(supplier: Supplier, globalSettings: GlobalSettings): Form<Supplier> {
    if (!globalSettings) return;

    return new Form<Supplier>([
      {
        name: 'supplier',
        controls: [
          {
            name: 'display_name',
            label: dicoLabelsService.getCollectionLabel('display_name', 'long'),
            type: 'text',
            value: supplier.display_name,
            required: true,
            split: 1
          },
          {
            name: 'category',
            label: dicoLabelsService.getCollectionLabel('category', 'long'),
            type: 'dropdown',
            options: [
              { value: 'device', label: 'Ordinateurs' },
              { value: 'spare', label: 'Pièces détachées' },
              { value: 'consumable', label: 'Emballages' },
              { value: 'other', label: 'Autre' }
            ],
            value: supplier.category,
            required: true,
            split: 1
          },
          {
            name: 'country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'dropdown',
            value: supplier.country,
            options: globalSettings.countries.map((scheme, index) => ({
              value: scheme[0],
              label: scheme[1],
              indexWeight: index
            })),
            required: true,
            split: 3
          },
          {
            name: 'currency',
            label: dicoLabelsService.getCollectionLabel('currency', 'long'),
            type: 'dropdown',
            value: supplier.currency,
            options: [
              { value: 'eur', label: 'EUR' },
              { value: 'gbp', label: 'GBP' },
              { value: 'usd', label: 'USD' }
            ],
            required: true,
            split: 3
          },
          {
            name: 'tax_scheme',
            label: dicoLabelsService.getCollectionLabel('tax_scheme', 'long'),
            value: supplier.tax_scheme,
            type: 'dropdown',
            options: globalSettings.schemes.map((scheme, index) => ({
              value: scheme[0],
              label: scheme[1],
              indexWeight: index
            })),
            required: true,
            split: 3
          },
          {
            name: 'warranty',
            label: dicoLabelsService.getCollectionLabel('warranty', 'long'),
            type: 'number',
            value: supplier.warranty,
            unit: dicoLabelsService.getUnit('day'),
            required: true,
            split: 2
          },
          {
            name: 'payment_timing',
            label: dicoLabelsService.getCollectionLabel('payment_timing', 'long'),
            type: 'dropdown',
            value: supplier.payment_timing,
            options: [
              { value: 'before_reception', label: 'Avant la livraison' },
              { value: 'at_reception', label: 'A la livraison' },
              { value: 'delay', label: 'A X jours' }
            ],
            required: true,
            split: 2
          },
          {
            name: 'comment',
            label: dicoLabelsService.getCollectionLabel('comment', 'long'),
            type: 'text-multiline',
            value: supplier.comment,
            split: 1
          }
        ]
      }
    ]);
  }
  getPurchasePaymentScheduleForm(
    purchase: Purchase,
    globalSettings: GlobalSettings
  ): Form<PurchasePaymentScheduleOptions> {
    return new Form<PurchasePaymentScheduleOptions>([
      {
        name: 'supplier',
        controls: [
          {
            name: 'supplier_id',
            label: dicoLabelsService.getCollectionLabel('supplier_id', 'long'),
            type: 'dropdown',
            options: [],
            value: purchase.supplier?.id,
            disabled: true,
            split: 2,
            afterChange: (value: string, group) => {
              const options = group.get('supplier_id').options;
              const optionsPromise = options?.length
                ? Promise.resolve()
                : suppliersService.order_list().then(resp => {
                    const suppliers = resp?.values || [];
                    if (!suppliers) throw new Error('Impossible de récupérer la liste des fournisseurs');
                    group.get('supplier_id').options = suppliers?.map(value => ({
                      value: value.id,
                      label: value.display_name,
                      details: value
                    }));
                  });
              return optionsPromise.then(() => {
                const option = group.get('supplier_id').options.find(opt => opt.value === value);
                group.get('supplier_country').value = option?.details?.country;
                group.get('currency').value = option?.details?.currency;
                group.get('tax_scheme').value = option?.details?.tax_scheme;
              });
            }
          },
          {
            name: 'supplier_country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'text',
            value: purchase.supplier_country || purchase.supplier?.country,
            disabled: true,
            split: 2,
            required: true
          },
          {
            name: 'currency',
            label: dicoLabelsService.getCollectionLabel('currency', 'long'),
            type: 'dropdown',
            value: purchase.currency,
            options: [
              { value: 'eur', label: 'EUR' },
              { value: 'gbp', label: 'GBP' },
              { value: 'usd', label: 'USD' }
            ],
            required: true,
            split: 2
          },
          {
            name: 'tax_scheme',
            label: dicoLabelsService.getCollectionLabel('tax_scheme', 'long'),
            value: purchase.tax_scheme,
            type: 'dropdown',
            options: globalSettings.schemes.map((scheme, index) => ({
              value: scheme[0],
              label: scheme[1],
              indexWeight: index
            })),
            split: 2,
            required: true
          },
          {
            name: 'incoterm',
            label: dicoLabelsService.getCollectionLabel('incoterm', 'short'),
            value: purchase.incoterm,
            type: 'dropdown',
            options: ['CIF', 'CIP', 'CFR', 'CPT', 'DAP', 'DDP', 'DPU', 'EXW', 'FAS', 'FCA', 'FOB'].map(
              (item, index) => ({
                value: item,
                label: item,
                indexWeight: index
              })
            ),
            disabled: false,
            required: false
          },
          {
            name: 'reference_internal',
            label: dicoLabelsService.getCollectionLabel('reference_internal', 'long'),
            type: 'text',
            value: purchase.reference_internal,
            disabled: false,
            required: false
          },
          {
            name: 'reference_supplier',
            label: dicoLabelsService.getCollectionLabel('reference_supplier', 'long'),
            type: 'text',
            value: purchase.reference_supplier,
            disabled: false,
            required: false
          }
        ]
      },
      {
        name: 'payment-schedule',
        controls: [
          {
            name: 'payment_scheduled_timing',
            label: dicoLabelsService.getCollectionLabel('payment_scheduled_timing', 'long'),
            type: 'dropdown',
            options: [
              { value: 'immediate', label: 'Immédiat' },
              { value: 'automatic', label: 'Prélèvement automatique' },
              { value: 'date', label: 'Choisir une date' }
            ],
            value: purchase.payment_scheduled_timing,
            required: true,
            afterChange: async (value: string, group) => {
              group.get('payment_scheduled_delay').hidden = value !== 'automatic';
              group.get('payment_expected_date').hidden = value !== 'date';
            }
          },
          {
            name: 'payment_scheduled_delay',
            label: dicoLabelsService.getCollectionLabel('payment_scheduled_delay', 'long'),
            unit: dicoLabelsService.getUnit('day'),
            type: 'number',
            value: purchase.payment_scheduled_delay,
            required: true,
            hidden: true
          },
          {
            name: 'payment_expected_date',
            label: dicoLabelsService.getCollectionLabel('payment_expected_date', 'long'),
            type: 'datetime-local',
            value: purchase.payment_expected_date,
            required: true,
            hidden: true
          },
          {
            name: 'delivery_expected_date',
            label: dicoLabelsService.getCollectionLabel('delivery_expected_date', 'long'),
            type: 'datetime-local',
            value: purchase.delivery_expected_date,
            required: true
          },
          {
            name: 'use_credit',
            label: `Utiliser la note de crédit de ${purchase?.supplier?.credit_amount} ${
              purchase?.supplier?.currency || '€'
            } ?`,
            type: 'toggle',
            value: false,
            hidden: !purchase?.supplier?.credit_amount,
            required: true
          }
        ]
      }
    ]);
  }

  getPurchasePaymentEffectiveForm(purchase: Purchase): Form<PurchasePaymentEffectiveOptions> {
    return new Form<PurchasePaymentEffectiveOptions>([
      {
        name: 'payment-effective',
        controls: [
          {
            name: 'payment_method',
            label: dicoLabelsService.getCollectionLabel('payment_method'),
            type: 'dropdown',
            value: purchase.payment_method,
            required: true,
            options: [
              { value: 'credit_card', label: 'Carte bancaire' },
              { value: 'paypal', label: 'Paypal' },
              { value: 'bank_transfer', label: 'Virement' }
            ],
            afterChange: async (value: string, group) => {
              group.get('payment_supplier_rib_id').hidden = value !== 'bank_transfer';
            }
          },
          {
            name: 'payment_supplier_rib_id',
            label: dicoLabelsService.getCollectionLabel('payment_supplier_rib_id', 'long'),
            type: 'dropdown',
            options: purchase.supplier?.ribs?.map(rib => ({
              value: rib.id,
              label: rib.bic
            })),
            value: purchase.payment_supplier_rib_id,
            required: true
          },
          {
            name: 'payment_transfer_date',
            label: dicoLabelsService.getCollectionLabel('payment_transfer_date', 'long'),
            type: 'datetime-local',
            value: purchase.payment_transfer_date,
            required: true
          },
          {
            name: 'payment_confirmation_file',
            label: dicoLabelsService.getCollectionLabel('payment_confirmation_file', 'long'),
            type: 'file',
            value: purchase.payment_confirmation_file
          }
        ]
      }
    ]);
  }

  getPurchaseDealForm(purchase: Purchase): Form<PurchaseDealOptions> {
    return new Form<PurchaseDealOptions>([
      {
        name: 'deal',
        controls: [
          {
            name: 'analysis_supplier_file',
            label: dicoLabelsService.getCollectionLabel('analysis_supplier_file', 'long'),
            type: 'file',
            value: purchase.analysis_supplier_file
          }
        ]
      }
    ]);
  }

  getPurchaseRMACreationForm(purchase: Purchase): Form<PurchaseRMACreationOptions> {
    return new Form<PurchaseRMACreationOptions>([
      {
        name: 'rma-creation',
        controls: [
          {
            name: 'rma_problem_type',
            label: dicoLabelsService.getCollectionLabel('rma_problem_type'),
            type: 'dropdown',
            value: purchase.rma_problem_type,
            required: true,
            options: [
              { value: 'partial_reception', label: 'Réception partielle' },
              { value: 'damaged_package', label: 'Emballage abîmé' },
              { value: 'damaged_product', label: 'Produits abîmés' },
              { value: 'delivery_delay', label: 'Retard de livraison' },
              { value: 'non_compliance', label: 'Non conformité' },
              { value: 'not_received', label: 'Non reçu' }
            ]
          },
          {
            name: 'rma_problem_desc',
            label: dicoLabelsService.getCollectionLabel('rma_problem_desc', 'long'),
            type: 'text-multiline',
            value: purchase.rma_problem_desc,
            required: true
          },
          {
            name: 'rma_solution',
            label: dicoLabelsService.getCollectionLabel('rma_solution'),
            type: 'dropdown',
            value: purchase.rma_solution,
            required: true,
            options: [
              { value: 'refund', label: 'Remboursement' },
              { value: 'credit', label: 'Note de crédit' }
            ]
          },
          {
            name: 'rma_amount',
            label: dicoLabelsService.getCollectionLabel('rma_amount', 'long'),
            type: 'number',
            value: purchase.rma_amount,
            required: true,
            split: 2
          },
          {
            name: 'rma_currency',
            label: dicoLabelsService.getCollectionLabel('rma_currency', 'long'),
            type: 'dropdown',
            value: purchase.rma_currency,
            options: [
              { value: 'eur', label: 'EUR' },
              { value: 'gbp', label: 'GBP' },
              { value: 'usd', label: 'USD' }
            ],
            required: true,
            split: 2
          }
        ]
      }
    ]);
  }

  getPurchasePauseForm(): Form<PurchasePauseOptions> {
    return new Form<PurchasePauseOptions>([
      {
        name: 'pause-options',
        controls: [
          {
            name: 'comment',
            label: dicoLabelsService.getCollectionLabel('comment', 'long'),
            type: 'text-multiline',
            required: true
          }
        ]
      }
    ]);
  }

  getSupplierRibForm(rib: SupplierRib, globalSettings: GlobalSettings): Form<SupplierRib> {
    return new Form<SupplierRib>([
      {
        name: 'rib-options',
        controls: [
          {
            name: 'iban',
            label: dicoLabelsService.getCollectionLabel('iban', 'long'),
            type: 'text',
            value: rib?.iban,
            required: true
          },
          {
            name: 'bic',
            label: dicoLabelsService.getCollectionLabel('bic', 'long'),
            type: 'text',
            value: rib?.bic,
            split: 2,
            required: true
          },
          {
            name: 'country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'dropdown',
            options: globalSettings.countries.map(country => ({
              value: country[0],
              label: country[1]
            })),
            value: rib?.country,
            required: true,
            split: 4
          },
          {
            name: 'currency',
            label: dicoLabelsService.getCollectionLabel('currency', 'long'),
            type: 'dropdown',
            options: [
              { value: 'eur', label: 'EUR' },
              { value: 'gbp', label: 'GBP' },
              { value: 'usd', label: 'USD' }
            ],
            value: rib?.currency,
            required: true,
            split: 4
          }
        ]
      }
    ]);
  }

  getOrdersImportForm(): Form<OrdersImportOptions> {
    return new Form<OrdersImportOptions>([
      {
        // name: 'orders',
        name: 'orderLines',
        controls: [
          {
            name: 'marketplace',
            label: dicoLabelsService.getCollectionLabel('marketplace', 'long'),
            type: 'dropdown',
            options: [
              { value: 'XXX', label: "N'importe" },
              { value: 'GRE', label: 'Greenweez' },
              { value: 'PHH', label: 'Phone House' },
              { value: 'PGB', label: 'Pigsback' },
              { value: 'QBP', label: 'Quel Bon Plan' }
            ],
            required: true
          },
          {
            name: 'orders_file',
            label: dicoLabelsService.getCollectionLabel('orders_file', 'long'),
            type: 'file',
            required: true
          }
        ]
      }
    ]);
  }

  getMarketplaceSkusImportForm(): Form<MarketplaceSkuImportOptions> {
    return new Form<MarketplaceSkuImportOptions>([
      {
        name: 'marketplace-skus',
        controls: [
          {
            name: 'marketplace_skus_file',
            label: dicoLabelsService.getCollectionLabel('marketplace_skus_file', 'long'),
            type: 'file',
            required: true
          }
        ]
      }
    ]);
  }

  getMarketplaceSkusPriceImportForm(): Form<MarketplaceSkuImportOptions> {
    return new Form<MarketplaceSkuImportOptions>([
      {
        name: 'marketplace-skus',
        controls: [
          {
            name: 'marketplace_skus_price_file',
            label: dicoLabelsService.getCollectionLabel('marketplace_skus_price_file', 'long'),
            type: 'file',
            required: true
          }
        ]
      }
    ]);
  }

  getSkuDifferenceCostImportForm(): Form<SkuDifferenceCostImportOptions> {
    return new Form<SkuDifferenceCostImportOptions>([
      {
        name: 'sku-difference-cost',
        controls: [
          {
            name: 'sku_difference_cost_file',
            label: dicoLabelsService.getCollectionLabel('sku-difference-cost'),
            type: 'file',
            required: true
          }
        ]
      }
    ]);
  }

  getImportUpdateStatusForm(): Form<ImportUpdateStatusOptions> {
    return new Form<ImportUpdateStatusOptions>([
      {
        name: 'label-update-status',
        controls: [
          {
            name: 'label_update_status_file',
            label: dicoLabelsService.getCollectionLabel('label-update-status'),
            type: 'file',
            required: true
          }
        ]
      }
    ]);
  }

  private getRelocateControls(
    init: { stockId?: string | number; stepCode?: string } = {},
    device: Device,
    globalSettings: GlobalSettings,
    options: {
      /**
       * We can prevent the user from changing stock value
       */
      disableStockDropdown?: boolean;
      /**
       * We can disable the stocks with steps (Preparation and Expedition, because they require the device or order to be matched)
       */
      disableStocksWithSteps?: boolean;
      /**
       * Allows to filter options on a list of stocks (e.g. in Expedition, for an iMac device, the user should be
       * allowed to send the device to Preparation or Expedition)
       */
      stocksSubSet?: [number, StockStatus][];

      is_manager?: boolean;
    }
  ): FormControlRaw<{ stockId: number; stepCode: string }>[] {
    const { disableStockDropdown, disableStocksWithSteps, stocksSubSet, is_manager } = options;
    if (!globalSettings) return null;
    const stocks_status = stocksSubSet ? stocksSubSet : globalSettings.stocks_status;
    const initStock = stocks_status.find(s => +s[0] === +init.stockId);
    const stocks = stocksStatusService.list_stock(stocks_status);
    return [
      {
        name: 'stockId',
        label: dicoLabelsService.getCollectionLabel('stock', 'long'),
        type: 'dropdown',
        options: stocks.map(stock => {
          const data = {
            value: stock[0],
            label: stock[1].display_name
          };
          return data;
        }),
        required: true,
        disabled: disableStockDropdown,
        value: initStock && initStock[1].stock_id,
        afterChange: async (value: number, group) => {
          const stock_status = stocks_status.find(s => s[1].stock.id === value);

          const statusFields = group.get('statusId');
          statusFields.value = null;
          const filter_status = stocks_status.filter(s => s[1].stock.id == value);
          if (filter_status[0]) {
            statusFields.options = filter_status
              .map(stock_status => {
                if (stock_status[1].status) {
                  return {
                    value: stock_status[1].status.id,
                    label: stock_status[1].status.display_name,
                    disabled: stock_status[1].no_relocate && disableStocksWithSteps && !is_manager
                  };
                }
              })
              .filter(s => s);

            statusFields.hidden = !statusFields.options[0];

            if (
              (stock_status[1].no_relocate && disableStocksWithSteps) ||
              !stocks_status.find(s => s[1].stock_id == stock_status[1].stock_id && !s[1].status_id)
            ) {
              statusFields.required = true;
              statusFields.label = dicoLabelsService.getCollectionLabel('status_required');
            } else {
              statusFields.required = false;
              statusFields.label = dicoLabelsService.getCollectionLabel('status', 'long');
            }
            statusFields.value = initStock && initStock[1].status_id;
          }
        },
        split: 2
      },
      {
        name: 'statusId',
        label: dicoLabelsService.getCollectionLabel('status', 'long'),
        type: 'dropdown',
        hidden: true,
        required: false,
        split: 2
      }
    ];
  }

  private getDeviceForm(device: Device, type: DeviceFormType, globalSettings: GlobalSettings): Form<Device> {
    const test = [];

    test.push({
      name: 'message',
      type: 'message-insert',
      content: '',
      theme: '',
      hidden: true
    });
    try {
      if (type == 'control') {
        devicesService.get(device.serial_no);

        test[0] = {
          name: 'message',
          type: 'message-insert',
          content:
            'Cet ordinateur a déjà été contrôlé le &nbsp;' +
            '<span class="emphasis">' +
            datesService.format(get(device, 'control.created_at')) +
            '</span>' +
            '</div>',
          theme: 'warning',
          hidden: false
        };
      }
    } catch {
      test;
    }
    let supplierComment = '';
    if (device.purchase_items?.supplier_grade) supplierComment += device.purchase_items.supplier_grade;
    if (device.purchase_items?.supplier_grade && device.purchase_items.supplier_comment) supplierComment += '\n';
    if (device.purchase_items?.supplier_comment) supplierComment += device.purchase_items.supplier_comment;
    let hiddenSupplierComment = true;
    if (type == 'device') hiddenSupplierComment = false;
    return new Form<Device>([
      {
        name: 'message',
        controls: test
      },
      {
        name: 'model',
        label: '<i class="fas fa-laptop"></i> Modèle',
        controls: this.getDeviceModelControls(device, type, globalSettings)
      },
      {
        name: 'perfs',
        label: '<i class="fas fa-chart-line"></i> Performances',
        controls: this.getDevicePerfsControls(device, globalSettings)
      },
      {
        name: 'battery',
        label: '<i class="fas fa-car-battery"></i> Batterie',
        controls: this.getDeviceBatteryControls(device, type)
      },
      {
        name: 'supplier',
        label: '<i class="far fa-handshake"></i> Informations fournisseur',
        controls: this.getDeviceSupplierControls(device, type, globalSettings)
      },
      {
        name: 'grading',
        label: '<i class="fas fa-laptop"></i> Grade',
        controls: this.getDeviceGradingsControls(device, type)
      },
      {
        name: 'supplier_comment',
        controls: [
          {
            name: 'supplier_comment',
            label: dicoLabelsService.getCollectionLabel('supplier_comment'),
            type: 'text-multiline',
            value: supplierComment,
            disabled: true,
            hidden: hiddenSupplierComment,
            split: 1
          }
        ]
      },
      {
        name: 'comment',
        controls: [
          {
            name: 'initial_comment',
            label: dicoLabelsService.getCollectionLabel('initial_comment', 'long'),
            type: 'text-multiline',
            value: device.initial_comment
          }
        ]
      }
    ]);
  }

  private getControlRelocateForm(device: Device, globalSettings: GlobalSettings): Form<Device> {
    return new Form<Device>([
      {
        name: 'stock_status',
        label: '<i class="fas fa-laptop"></i> Choix du stock',
        controls: [
          {
            name: 'stock_status_id',
            label: dicoLabelsService.getCollectionLabel('stock', 'long'),
            type: 'option-list',
            value: device.stock_status_id,
            options: globalSettings.stocks_status
              .filter(stocks_status => stocks_status[1].control_output)
              .map(stocks_status => {
                const data = {
                  value: stocks_status[0],
                  label: ''
                };
                data['label'] = stocks_status[1].status
                  ? stocks_status[1].stock.display_name + ' / ' + stocks_status[1].status.display_name
                  : stocks_status[1].stock.display_name;
                return data;
              }),
            required: true,
            afterChange: async (value: number, group) => {
              group.get('rma_reason').hidden = value !== 26;
            }
          },
          {
            name: 'rma_reason',
            label: 'Raison du RMA',
            type: 'option-list',
            value: device.rma_reason,
            options: globalSettings.rma_reasons.map(reason => ({
              value: reason[0],
              label: reason[1]
            })),
            required: true,
            hidden: true
          }
        ]
      },
      {
        name: 'comment',
        controls: [
          {
            name: 'comment',
            label: dicoLabelsService.getCollectionLabel('comment', 'long'),
            type: 'text-multiline',
            required: true
          }
        ]
      }
    ]);
  }

  private getCageCreationForm(cage: Cage, globalSettings: GlobalSettings): Form<Cage> {
    return new Form<Cage>([
      {
        name: 'information',
        label: '<i class="fas fa-info-circle"></i> Informations',
        controls: [
          {
            name: 'carrier',
            label: dicoLabelsService.getCollectionLabel('carrier'),
            type: 'option-list',
            options: globalSettings.shipping_companies
              .filter(sc => ['DHL', 'Colissimo', 'Palette', 'Chronopost', 'UPS'].includes(sc[1].display_name))
              .map(sc => ({
                value: sc[0],
                label: sc[1].display_name
              })),
            value: cage.name,
            required: true
          },
          {
            name: 'cage_number',
            label: dicoLabelsService.getCollectionLabel('cage_number'),
            type: 'option-list',
            options: ['1', '2', '3', '4', '5', '6'].map(num => ({
              value: num,
              label: num
            })),
            value: cage.cage_number,
            required: true
          }
        ]
      }
    ]);
  }

  private getOrderLineCreationForm(
    orderLine: OrderLine,
    globalSettings: GlobalSettings
  ): Form<OrderLine & OrderAddress> {
    return new Form<OrderLine & OrderAddress>([
      {
        name: 'purchase',
        label: '<i class="fas fa-shopping-basket"></i> Achat',
        controls: [
          {
            name: 'order_line_type',
            label: dicoLabelsService.getCollectionLabel('order_line_type'),
            type: 'dropdown',
            options: globalSettings.order_line_types.map((type, index) => ({
              value: type[0],
              label: type[1],
              indexWeight: index
            })),
            value: globalSettings.order_line_types[0][0],
            required: true,
            afterChange: async (value: string, group) => {
              // Adapt form depending on if the order is for a device or an accessory/part
              const isDeviceOrderLine = value === 'device';
              group.get('sku_marketplace').hidden = !isDeviceOrderLine;
              group.get('sku_okamac').label = isDeviceOrderLine
                ? dicoLabelsService.getCollectionLabel('sku_okamac', 'long')
                : dicoLabelsService.getCollectionLabel('accessory_part_id');
              // group.get('sku_okamac').split = isDeviceOrderLine ? 2 : 1;
              group.get('sku_okamac').required = isDeviceOrderLine ? false : true;
            },
            split: 3
          },
          {
            name: 'price',
            label: dicoLabelsService.getCollectionLabel('price', 'long'),
            type: 'number',
            // value: orderLine.price,
            required: true,
            split: 3
          },
          {
            name: 'currency',
            label: dicoLabelsService.getCollectionLabel('currency', 'long'),
            type: 'dropdown',
            options: globalSettings.currencies.map((currency, index) => ({
              value: currency[0],
              label: `${currency[1]} (${currency[0]})`,
              indexWeight: index
            })),
            // value: orderLine.order.currency,
            value: globalSettings.currencies[0][0],
            required: true,
            split: 3
          },
          {
            name: 'product_description',
            label: dicoLabelsService.getCollectionLabel('product_description', 'long'),
            type: 'text'
            // value: orderLine.product_description
          },
          {
            name: 'sku_marketplace',
            label: dicoLabelsService.getCollectionLabel('sku_marketplace', 'long'),
            type: 'text'
            // value: orderLine.sku_marketplace,
          },
          {
            name: 'sku_okamac',
            label: dicoLabelsService.getCollectionLabel('sku_okamac', 'long'),
            type: 'text',
            split: 1
            // value: orderLine.sku_okamac,
          },
          {
            name: 'warranty',
            label: dicoLabelsService.getCollectionLabel('warranty', 'long'),
            type: 'number',
            // value: orderLine.warranty,
            unit: dicoLabelsService.getUnit('month'),
            required: true,
            split: 2
          },
          {
            name: 'warranty_amount',
            label: dicoLabelsService.getCollectionLabel('warranty_amount', 'long'),
            type: 'number',
            value: orderLine.warranty_amount,
            unit: dicoLabelsService.getUnit('money'),
            split: 2
          },
          {
            name: 'tax_scheme',
            label: dicoLabelsService.getCollectionLabel('tax_scheme', 'short'),
            value: orderLine.tax_scheme,
            type: 'dropdown',
            options: [
              ...globalSettings.schemes.map(scheme => ({
                value: scheme[0],
                label: scheme[1]
              })),
              ...[{ value: null, label: 'Aucun' }]
            ],
            split: 2
          }
        ]
      },
      {
        name: 'battery',
        label: '<i class="fas fa-battery-full"></i> Batterie neuve',
        controls: [
          {
            name: 'new_battery_required',
            label: dicoLabelsService.getCollectionLabel('new_battery_required', 'long'),
            type: 'toggle',
            value: get(orderLine, 'new_battery_required'),
            split: 2,
            afterChange: async (value: boolean, group) => {
              group.get('new_battery_price').hidden = value !== true;
              if (value !== true) {
                group.get('new_battery_price').value = null;
              }
            }
          },
          {
            name: 'new_battery_price',
            label: dicoLabelsService.getCollectionLabel('new_battery_price', 'long'),
            type: 'number',
            value: get(orderLine, 'new_battery_price'),
            unit: dicoLabelsService.getUnit('money'),
            required: true,
            split: 2
          }
        ]
      }
    ]);
  }

  private getOrderLineForm(
    orderLine: OrderLine,
    globalSettings: GlobalSettings,
    options?
  ): Form<OrderLine & OrderAddress> {
    const shippingCompany = globalSettings.shipping_companies.find(sp => sp[0] === orderLine.shipping_company);
    const shippingTypes = (shippingCompany && shippingCompany[1].types) || [];
    const status_code = globalSettings?.expedition_status?.find(sp => sp[0] === String(orderLine.carrier_label_status));
    const statusLabel = status_code ? status_code[1] : '';
    const isDeviceOrderLine = orderLine.order_line_type === 'device';
    return new Form<OrderLine & OrderAddress>([
      {
        name: 'purchase',
        label: '<i class="fas fa-shopping-basket"></i> Achat',
        controls: [
          {
            name: 'marketplace',
            label: dicoLabelsService.getCollectionLabel('marketplace', 'short'),
            type: 'text',
            value: orderLine.order.marketplace.display_name,
            required: true,
            disabled: true,
            split: 2
          },
          {
            name: 'marketplace_order_id',
            label: dicoLabelsService.getCollectionLabel('marketplace_order_id', 'long'),
            type: 'text',
            value: orderLine.order.marketplace_order_id,
            required: true,
            disabled: true,
            split: 2
          },
          {
            name: 'order_date',
            label: dicoLabelsService.getCollectionLabel('order_date', 'long'),
            type: 'datetime-local',
            value: orderLine.return_id != null ? orderLine.created_at : orderLine.order.order_date,
            required: true,
            split: 3
          },
          {
            name: 'price',
            label: dicoLabelsService.getCollectionLabel('price', 'long'),
            type: 'number',
            value: orderLine.price,
            required: true,
            split: 3
          },
          {
            name: 'currency',
            label: dicoLabelsService.getCollectionLabel('currency', 'long'),
            type: 'dropdown',
            options: globalSettings.currencies.map((currency, index) => ({
              value: currency[0],
              label: `${currency[1]} (${currency[0]})`,
              indexWeight: index
            })),
            value: orderLine.order.currency,
            required: true,
            split: 4
          },
          {
            name: 'product_description',
            label: dicoLabelsService.getCollectionLabel('product_description', 'long'),
            type: 'text',
            value: orderLine.product_description
          },
          {
            name: 'sku_marketplace',
            label: dicoLabelsService.getCollectionLabel('sku_marketplace', 'long'),
            type: 'text',
            value: orderLine.sku_marketplace,
            required: true,
            split: 2,
            hidden: !isDeviceOrderLine
          },
          {
            name: 'sku_okamac',
            label: isDeviceOrderLine
              ? dicoLabelsService.getCollectionLabel('sku_okamac', 'long')
              : dicoLabelsService.getCollectionLabel('accessory_part_id'),
            type: 'text',
            disabled: false,
            value: orderLine.sku_okamac,
            split: isDeviceOrderLine ? 2 : 1,
            required: isDeviceOrderLine ? false : true
          },
          {
            name: 'warranty',
            label: dicoLabelsService.getCollectionLabel('warranty', 'long'),
            type: 'number',
            value: orderLine.warranty,
            unit: dicoLabelsService.getUnit('month'),
            required: true,
            split: 2
          },
          {
            name: 'warranty_amount',
            label: dicoLabelsService.getCollectionLabel('warranty_amount', 'long'),
            type: 'number',
            value: orderLine.warranty_amount,
            unit: dicoLabelsService.getUnit('money'),
            split: 2
          },
          {
            name: 'payment_method',
            label: dicoLabelsService.getCollectionLabel('payment_method'),
            type: 'dropdown',
            options: globalSettings.payment_methods.map((payment_method, index) => ({
              value: payment_method[0],
              label: payment_method[1],
              indexWeight: index
            })),
            value: orderLine.order.payment_method,
            split: 2
          },
          {
            name: 'tax_scheme',
            label: dicoLabelsService.getCollectionLabel('tax_scheme', 'short'),
            value: orderLine.tax_scheme,
            type: 'dropdown',
            options: [
              ...globalSettings.schemes.map(scheme => ({
                value: scheme[0],
                label: scheme[1]
              })),
              ...[{ value: null, label: 'Aucun' }]
            ],
            split: 2
          },
          {
            name: 'os_code_to_install',
            label: dicoLabelsService.getCollectionLabel('ordered_os'),
            value: orderLine.os_code_to_install,
            type: 'dropdown',
            options: [
              ...globalSettings.operating_systems.map(scheme => ({
                value: scheme[0],
                label: scheme[1].display_name
              })),
              ...[{ value: null, label: 'Aucun' }]
            ]
          }
        ]
      },
      {
        name: 'dispatch_info',
        label: '<i class="fas fa-dolly"></i> Expédition',
        controls: [
          {
            name: 'express',
            label: dicoLabelsService.getCollectionLabel('express', 'long'),
            type: 'toggle',
            value: orderLine.order.express
          },
          {
            name: 'shipping_company',
            label: dicoLabelsService.getCollectionLabel('shipping_company', 'long'),
            type: 'dropdown',
            options: globalSettings.shipping_companies.map((sc, index) => ({
              value: sc[0],
              label: sc[1].display_name,
              indexWeight: index
            })),
            value: orderLine.shipping_company,
            afterChange: async (value: string, group) => {
              const company = globalSettings.shipping_companies.find(sp => sp[0] === value);
              const types = (company && company[1].types) || [];
              const options = types.map(type => ({
                value: type,
                label: type
              }));
              group.get('shipping_type').options = options;
            },
            required: true,
            split: 2
          },
          {
            name: 'shipping_type',
            label: dicoLabelsService.getCollectionLabel('shipping_type', 'long'),
            type: 'dropdown',
            options: shippingTypes.map(type => ({
              value: type,
              label: type
            })),
            required: true,
            value: orderLine.shipping_type,
            split: 2
          },
          {
            name: 'max_shipping_date',
            label: dicoLabelsService.getCollectionLabel('max_shipping_date', 'long'),
            type: 'datetime-local',
            value: orderLine.max_shipping_date,
            required: true,
            disabled: true,
            split: 2
          },
          {
            name: 'shipping_date',
            label: dicoLabelsService.getCollectionLabel('shipping_date', 'long'),
            type: 'datetime-local',
            value: orderLine.shipping_date,
            disabled: !options?.UserCanUpdateOrderShippingDate,
            split: 2
          },
          {
            name: 'shipping_tracking_number',
            label: dicoLabelsService.getCollectionLabel('shipping_tracking_number', 'long'),
            type: 'text',
            value: orderLine.shipping_tracking_number,
            disabled: true,
            split: 2
          },
          {
            name: 'expedition_status',
            label: dicoLabelsService.getCollectionLabel('expedition_status'),
            type: 'text',
            value: statusLabel,
            required: false,
            split: 2
          },
          {
            name: 'manual_tracking_number',
            label: dicoLabelsService.getCollectionLabel('manual_tracking_number'),
            type: 'text',
            value: orderLine.manual_tracking_number,
            required: false,
            split: 2
          }
        ]
      },
      {
        name: 'shipping_info',
        label: '<i class="fas fa-dolly"></i> Adresse de livraison',
        controls: [
          {
            name: 'shipping_info.last_name',
            label: dicoLabelsService.getCollectionLabel('last_name', 'long'),
            type: 'text',
            value: get(orderLine.order, 'shipping_info.last_name'),
            required: true,
            split: 2
          },
          {
            name: 'shipping_info.first_name',
            label: dicoLabelsService.getCollectionLabel('first_name', 'long'),
            type: 'text',
            value: get(orderLine.order, 'shipping_info.first_name'),
            required: true,
            split: 2
          },
          {
            name: 'shipping_info.company',
            label: dicoLabelsService.getCollectionLabel('company', 'long'),
            type: 'text',
            value: get(orderLine.order, 'shipping_info.company')
          },
          {
            name: 'shipping_info.email',
            label: dicoLabelsService.getCollectionLabel('email', 'long'),
            type: 'text',
            value: get(orderLine.order, 'shipping_info.email'),
            split: 3
          },
          {
            name: 'shipping_info.dial_code',
            label: dicoLabelsService.getCollectionLabel('dial_code'),
            type: 'dropdown',
            value: get(orderLine.order, 'shipping_info.dial_code'),
            options: globalSettings.countries.map((phone_label, index) => ({
              value: phone_label[2],
              label: dicoLabelsService.decodeFlag(phone_label[0]) + ' ' + phone_label[2],
              indexWeight: index
            })),
            split: 4
          },
          {
            name: 'shipping_info.phone',
            label: dicoLabelsService.getCollectionLabel('phone', 'long'),
            type: 'text',
            value: get(orderLine.order, 'shipping_info.phone'),
            split: 3
          },
          {
            name: 'shipping_info.street_1',
            label: dicoLabelsService.getCollectionLabel('street_1', 'long'),
            type: 'text',
            value: get(orderLine.order, 'shipping_info.street_1'),
            required: true
          },
          {
            name: 'shipping_info.street_2',
            label: dicoLabelsService.getCollectionLabel('street_2', 'long'),
            type: 'text',
            maxlength: 35,
            value: get(orderLine.order, 'shipping_info.street_2')
          },
          {
            name: 'shipping_info.postal_code',
            label: dicoLabelsService.getCollectionLabel('postal_code', 'long'),
            type: 'text',
            value: get(orderLine.order, 'shipping_info.postal_code'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.city',
            label: dicoLabelsService.getCollectionLabel('city', 'long'),
            type: 'text',
            value: get(orderLine.order, 'shipping_info.city'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'dropdown',
            options: globalSettings.countries.map((country, index) => ({
              value: country[0],
              label: country[1],
              indexWeight: index
            })),
            value: get(orderLine.order, 'shipping_info.country'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.relay_id',
            label: 'nothing',
            type: 'text',
            value: get(orderLine.order, 'shipping_info.relay_id'),
            split: 3,
            hidden: true
          }
        ]
      },
      {
        name: 'billing_info',
        label: '<i class="fas fa-money-bill-alt"></i> Adresse de facturation',
        controls: [
          {
            name: 'same_address',
            label: dicoLabelsService.getCollectionLabel('same_address', 'long'),
            type: 'toggle',
            value: get(orderLine.order, 'same_address'),
            afterChange: async (value: boolean, group) => {
              group.get('billing_info.last_name').hidden = value;
              group.get('billing_info.first_name').hidden = value;
              group.get('billing_info.company').hidden = value;
              group.get('billing_info.vat_number').hidden = value;
              group.get('billing_info.nif_code').hidden = value;
              group.get('billing_info.email').hidden = value;
              group.get('billing_info.phone').hidden = value;
              group.get('billing_info.street_1').hidden = value;
              group.get('billing_info.street_2').hidden = value;
              group.get('billing_info.postal_code').hidden = value;
              group.get('billing_info.city').hidden = value;
              group.get('billing_info.country').hidden = value;
              group.get('billing_info.dial_code').hidden = value;
            }
          },
          {
            name: 'billing_info.last_name',
            label: dicoLabelsService.getCollectionLabel('last_name', 'long'),
            type: 'text',
            value: get(orderLine.order, 'billing_info.last_name'),
            required: true,
            hidden: get(orderLine.order, 'same_address'),
            split: 2
          },
          {
            name: 'billing_info.first_name',
            label: dicoLabelsService.getCollectionLabel('first_name', 'long'),
            type: 'text',
            value: get(orderLine.order, 'billing_info.first_name'),
            required: true,
            hidden: get(orderLine.order, 'same_address'),
            split: 2
          },
          {
            name: 'billing_info.company',
            label: dicoLabelsService.getCollectionLabel('company', 'long'),
            type: 'text',
            value: get(orderLine.order, 'billing_info.company'),
            hidden: get(orderLine.order, 'same_address')
          },
          {
            name: 'billing_info.vat_number',
            label: dicoLabelsService.getCollectionLabel('vat_number', 'long'),
            type: 'text',
            value: get(orderLine.order, 'billing_info.vat_number'),
            hidden: get(orderLine.order, 'same_address'),
            split: 2
          },
          {
            name: 'billing_info.nif_code',
            label: dicoLabelsService.getCollectionLabel('nif_code', 'long'),
            type: 'text',
            value: get(orderLine.order, 'billing_info.nif_code'),
            split: 2
          },
          {
            name: 'billing_info.email',
            label: dicoLabelsService.getCollectionLabel('email', 'long'),
            type: 'text',
            value: get(orderLine.order, 'billing_info.email'),
            hidden: get(orderLine.order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.dial_code',
            label: dicoLabelsService.getCollectionLabel('dial_code'),
            type: 'dropdown',
            value: get(orderLine.order, 'billing_info.dial_code'),
            options: globalSettings.countries.map((phone_label, index) => ({
              value: phone_label[2],
              label: dicoLabelsService.decodeFlag(phone_label[0]) + ' ' + phone_label[2],
              indexWeight: index
            })),
            hidden: get(orderLine.order, 'same_address'),
            split: 4
          },
          {
            name: 'billing_info.phone',
            label: dicoLabelsService.getCollectionLabel('phone', 'long'),
            type: 'text',
            value: get(orderLine.order, 'billing_info.phone'),
            hidden: get(orderLine.order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.street_1',
            label: dicoLabelsService.getCollectionLabel('street_1', 'long'),
            type: 'text',
            value: get(orderLine.order, 'billing_info.street_1'),
            required: true,
            hidden: get(orderLine.order, 'same_address')
          },
          {
            name: 'billing_info.street_2',
            label: dicoLabelsService.getCollectionLabel('street_2', 'long'),
            type: 'text',
            maxlength: 35,
            value: get(orderLine.order, 'billing_info.street_2'),
            hidden: get(orderLine.order, 'same_address')
          },
          {
            name: 'billing_info.postal_code',
            label: dicoLabelsService.getCollectionLabel('postal_code', 'long'),
            type: 'text',
            value: get(orderLine.order, 'billing_info.postal_code'),
            required: true,
            hidden: get(orderLine.order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.city',
            label: dicoLabelsService.getCollectionLabel('city', 'long'),
            type: 'text',
            value: get(orderLine.order, 'billing_info.city'),
            required: true,
            hidden: get(orderLine.order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'dropdown',
            options: globalSettings.countries.map((country, index) => ({
              value: country[0],
              label: country[1],
              indexWeight: index
            })),
            value: get(orderLine.order, 'billing_info.country'),
            required: true,
            hidden: get(orderLine.order, 'same_address'),
            split: 3
          }
        ]
      },
      {
        name: 'battery',
        label: '<i class="fas fa-battery-full"></i> Batterie neuve',
        controls: [
          {
            name: 'new_battery_required',
            label: dicoLabelsService.getCollectionLabel('new_battery_required', 'long'),
            type: 'toggle',
            value: get(orderLine, 'new_battery_required'),
            split: 2,
            afterChange: async (value: boolean, group) => {
              group.get('new_battery_price').hidden = value !== true;
              if (value !== true) {
                group.get('new_battery_price').value = null;
              }
            }
          },
          {
            name: 'new_battery_price',
            label: dicoLabelsService.getCollectionLabel('new_battery_price', 'long'),
            type: 'number',
            value: get(orderLine, 'new_battery_price'),
            unit: dicoLabelsService.getUnit('money'),
            required: true,
            split: 2
          }
        ]
      },
      {
        name: 'status',
        label: '<i class="fas fa-headset"></i> Demandes particulières',
        controls: [
          {
            name: 'new_match_required',
            label: dicoLabelsService.getCollectionLabel('new_match_required', 'long'),
            type: 'toggle',
            value: get(orderLine, 'new_match_required'),
            split: 2
          },
          {
            name: 'is_exchanged',
            label: dicoLabelsService.getCollectionLabel('is_exchanged', 'long'),
            type: 'toggle',
            value: get(orderLine, 'is_exchanged'),
            split: 2
          },
          {
            name: 'out_of_stock',
            label: dicoLabelsService.getCollectionLabel('out_of_stock', 'long'),
            type: 'toggle',
            value: get(orderLine, 'out_of_stock'),
            split: 2
          },
          {
            name: 'ordered_keyboard',
            label: dicoLabelsService.getCollectionLabel('ordered_keyboard', 'long'),
            type: 'dropdown',
            value: get(orderLine, 'ordered_keyboard'),
            options: globalSettings.accessories
              .filter(acc => acc[1].product_type === 'keyboard')
              .map(acc => ({
                value: acc[1].code,
                label: acc[1].desc
              }))
              .concat([{ value: 'none', label: 'Aucun' }]),
            hidden: !(
              orderLine.order_line_type === 'device' &&
              (orderLine.ordered_item?.model_namestring === 'iMac' ||
                orderLine.ordered_item?.model_namestring === 'Mac Mini' ||
                orderLine.ordered_item?.model_namestring === 'Mac Pro' ||
                orderLine.ordered_item?.model_namestring === 'Mac Studio')
            )
          },
          {
            name: 'ordered_mouse',
            label: dicoLabelsService.getCollectionLabel('ordered_mouse', 'long'),
            type: 'dropdown',
            value: get(orderLine, 'ordered_mouse'),
            options: globalSettings.accessories
              .filter(acc => acc[1].product_type === 'mouse')
              .map(acc => ({
                value: acc[1].code,
                label: acc[1].desc
              }))
              .concat([{ value: 'none', label: 'Aucun' }]),
            hidden: !(
              orderLine.order_line_type === 'device' &&
              (orderLine.ordered_item?.model_namestring === 'iMac' ||
                orderLine.ordered_item?.model_namestring === 'Mac Mini' ||
                orderLine.ordered_item?.model_namestring === 'Mac Pro' ||
                orderLine.ordered_item?.model_namestring === 'Mac Studio')
            )
          }
        ]
      }
    ]);
  }

  private getMarketplaceForm(marketplace: Marketplace, globalSettings: GlobalSettings, options?): Form<Marketplace> {
    return new Form<Marketplace>([
      {
        name: 'settings',
        label: '<i class="fas fa-cog"></i> Paramètres',
        controls: [
          {
            name: 'display_name',
            label: dicoLabelsService.getCollectionLabel('display_name', 'short'),
            type: 'text',
            value: marketplace.display_name,
            required: true,
            split: 2
          },
          {
            name: 'code',
            label: dicoLabelsService.getCollectionLabel('code', 'short'),
            type: 'text',
            value: marketplace.code,
            required: true,
            disabled: true,
            split: 2
          },
          {
            name: 'sav_dispatch_time',
            label: dicoLabelsService.getCollectionLabel('sav_dispatch_time', 'short'),
            type: 'number',
            unit: dicoLabelsService.getUnit('day'),
            value: marketplace.sav_dispatch_time,
            required: true,
            split: 2
          },
          {
            name: 'mirakl',
            label: dicoLabelsService.getCollectionLabel('is_mirakl', 'short'),
            type: 'toggle',
            value: marketplace.mirakl,
            required: true,
            afterChange: async (value: boolean, group) => {
              group.get('from_shippy_pro').hidden = value;
            },
            split: 2
          },
          {
            name: 'mode',
            label: dicoLabelsService.getCollectionLabel('selling_strat', 'short'),
            type: 'dropdown',
            options: [
              { value: 'margin', label: 'Marge' },
              { value: 'flowing', label: 'Écoulement' }
            ],
            value: marketplace.mode,
            required: true,
            split: 1
          },
          {
            name: 'from_shippy_pro',
            label: dicoLabelsService.getCollectionLabel('from_shippy', 'short'),
            type: 'toggle',
            value: marketplace.from_shippy_pro,
            required: true,
            hidden: get(marketplace, 'mirakl'),
            afterChange: async (value: boolean, group) => {
              group.get('shippy_pro_name').hidden = !value;
            },
            split: 2
          },
          {
            name: 'shippy_pro_name',
            label: dicoLabelsService.getCollectionLabel('shippy_name', 'short'),
            type: 'text',
            value: marketplace.shippy_pro_name,
            required: true,
            hidden: !get(marketplace, 'from_shippy_pro'),
            split: 2
          }
        ]
      },
      {
        name: 'alias',
        label: '<i class="fas fa-clone"></i> Alias',
        controls: [
          {
            name: 'alias_rules',
            label: dicoLabelsService.getCollectionLabel('alias_rules', 'short'),
            type: 'dropdown',
            options: options.aliasOptions,
            value: marketplace.alias_rules,
            required: true,
            split: 1
          }
        ]
      }
    ]);
  }

  private getAliasForm(alias: Alias, globalSettings: GlobalSettings, options?): Form<Alias> {
    return new Form<Alias>([
      {
        name: 'alias',
        label: '<i class="fas fa-clone"></i> Alias',
        controls: [
          {
            name: 'alias_rules',
            label: dicoLabelsService.getCollectionLabel('alias_rules', 'short'),
            type: 'dropdown',
            options: options.aliasOptions,
            value: alias.alias_rules,
            required: true,
            split: 1
          }
        ]
      }
    ]);
  }

  private getOrderForm(order: Order, globalSettings: GlobalSettings, options?): Form<Order & OrderAddress> {
    return new Form<Order & OrderAddress>([
      {
        name: 'purchase',
        label: '<i class="fas fa-shopping-basket"></i> Achat',
        controls: [
          {
            name: 'marketplace_id',
            label: dicoLabelsService.getCollectionLabel('marketplace', 'short'),
            type: 'dropdown',
            options: options.marketplaces.map(mp => ({
              value: mp.id,
              label: mp.display_name
            })),
            value: order.marketplace_id,
            required: true,
            split: 3
          },
          {
            name: 'marketplace_order_id',
            label: dicoLabelsService.getCollectionLabel('marketplace_order_id', 'long'),
            type: 'text',
            value: order.marketplace_order_id,
            required: true,
            disabled: true,
            split: 3
          },
          {
            name: 'order_date',
            label: dicoLabelsService.getCollectionLabel('order_date', 'long'),
            type: 'datetime-local',
            value: order.order_date,
            required: true,
            split: 3
          },
          {
            name: 'price',
            label: dicoLabelsService.getCollectionLabel('price', 'long'),
            type: 'number',
            value: order.total_price,
            required: true,
            disabled: true,
            split: 3
          },
          {
            name: 'currency',
            label: dicoLabelsService.getCollectionLabel('currency', 'long'),
            type: 'dropdown',
            options: globalSettings.currencies.map((currency, index) => ({
              value: currency[0],
              label: `${currency[1]} (${currency[0]})`,
              indexWeight: index
            })),
            value: order.currency,
            required: true,
            split: 3
          },
          {
            name: 'payment_method',
            label: dicoLabelsService.getCollectionLabel('payment_method'),
            type: 'dropdown',
            options: globalSettings.payment_methods.map((payment_method, index) => ({
              value: payment_method[0],
              label: payment_method[1],
              indexWeight: index
            })),
            value: order.payment_method,
            split: 3
          }
        ]
      },
      {
        name: 'dispatch_info',
        label: '<i class="fas fa-dolly"></i> Expédition',
        controls: [
          {
            name: 'express',
            label: dicoLabelsService.getCollectionLabel('express', 'long'),
            type: 'toggle',
            value: order.express
          },
          {
            name: 'max_shipping_date',
            label: dicoLabelsService.getCollectionLabel('max_shipping_date', 'long'),
            type: 'datetime-local',
            value: order.max_shipping_date,
            required: true,
            disabled: true,
            split: 4
          },
          {
            name: 'shipping_date',
            label: dicoLabelsService.getCollectionLabel('shipping_date', 'long'),
            type: 'datetime-local',
            value: order.shipping_date,
            disabled: !options?.UserCanUpdateOrderShippingDate,
            split: 4
          },
          {
            name: 'shipping_fees',
            label: dicoLabelsService.getCollectionLabel('shipping_fees', 'long'),
            type: 'number',
            value: order.shipping_fees,
            unit: dicoLabelsService.getUnit('money'),
            required: true,
            split: 5
          }
        ]
      },
      {
        name: 'shipping_info',
        label: '<i class="fas fa-dolly"></i> Adresse de livraison',
        controls: [
          {
            name: 'shipping_info.last_name',
            label: dicoLabelsService.getCollectionLabel('last_name', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.last_name'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.first_name',
            label: dicoLabelsService.getCollectionLabel('first_name', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.first_name'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.company',
            label: dicoLabelsService.getCollectionLabel('company', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.company'),
            split: 3
          },
          {
            name: 'shipping_info.email',
            label: dicoLabelsService.getCollectionLabel('email', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.email'),
            split: 2
          },
          {
            name: 'shipping_info.dial_code',
            label: dicoLabelsService.getCollectionLabel('dial_code'),
            type: 'dropdown',
            value: get(order, 'shipping_info.dial_code'),
            options: globalSettings.countries.map((phone_label, index) => ({
              value: phone_label[2],
              label: dicoLabelsService.decodeFlag(phone_label[0]) + ' ' + phone_label[2],
              indexWeight: index
            })),
            split: 6
          },
          {
            name: 'shipping_info.phone',
            label: dicoLabelsService.getCollectionLabel('phone', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.phone'),
            split: 3
          },
          {
            name: 'shipping_info.street_1',
            label: dicoLabelsService.getCollectionLabel('street_1', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.street_1'),
            required: true,
            split: 2
          },
          {
            name: 'shipping_info.street_2',
            label: dicoLabelsService.getCollectionLabel('street_2', 'long'),
            type: 'text',
            maxlength: 35,
            value: get(order, 'shipping_info.street_2'),
            split: 2
          },
          {
            name: 'shipping_info.postal_code',
            label: dicoLabelsService.getCollectionLabel('postal_code', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.postal_code'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.city',
            label: dicoLabelsService.getCollectionLabel('city', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.city'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'dropdown',
            options: globalSettings.countries.map((country, index) => ({
              value: country[0],
              label: country[1],
              indexWeight: index
            })),
            value: get(order, 'shipping_info.country'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.relay_id',
            label: 'nothing',
            type: 'text',
            value: get(order, 'shipping_info.relay_id'),
            split: 3,
            hidden: true
          }
        ]
      },
      {
        name: 'billing_info',
        label: '<i class="fas fa-money-bill-alt"></i> Adresse de facturation',
        controls: [
          {
            name: 'same_address',
            label: dicoLabelsService.getCollectionLabel('same_address', 'long'),
            type: 'toggle',
            value: get(order, 'same_address'),
            afterChange: async (value: boolean, group) => {
              group.get('billing_info.last_name').hidden = value;
              group.get('billing_info.first_name').hidden = value;
              group.get('billing_info.company').hidden = value;
              group.get('billing_info.vat_number').hidden = value;
              group.get('billing_info.nif_code').hidden = value;
              group.get('billing_info.email').hidden = value;
              group.get('billing_info.phone').hidden = value;
              group.get('billing_info.street_1').hidden = value;
              group.get('billing_info.street_2').hidden = value;
              group.get('billing_info.postal_code').hidden = value;
              group.get('billing_info.city').hidden = value;
              group.get('billing_info.country').hidden = value;
              group.get('billing_info.dial_code').hidden = value;
            }
          },
          {
            name: 'billing_info.last_name',
            label: dicoLabelsService.getCollectionLabel('last_name', 'long'),
            type: 'text',
            value: get(order, 'billing_info.last_name'),
            required: true,
            hidden: get(order, 'same_address'),
            split: 2
          },
          {
            name: 'billing_info.first_name',
            label: dicoLabelsService.getCollectionLabel('first_name', 'long'),
            type: 'text',
            value: get(order, 'billing_info.first_name'),
            required: true,
            hidden: get(order, 'same_address'),
            split: 2
          },
          {
            name: 'billing_info.company',
            label: dicoLabelsService.getCollectionLabel('company', 'long'),
            type: 'text',
            value: get(order, 'billing_info.company'),
            hidden: get(order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.vat_number',
            label: dicoLabelsService.getCollectionLabel('vat_number', 'long'),
            type: 'text',
            value: get(order, 'billing_info.vat_number'),
            hidden: get(order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.nif_code',
            label: dicoLabelsService.getCollectionLabel('nif_code', 'long'),
            type: 'text',
            value: get(order, 'billing_info.nif_code'),
            split: 3
          },
          {
            name: 'billing_info.email',
            label: dicoLabelsService.getCollectionLabel('email', 'long'),
            type: 'text',
            value: get(order, 'billing_info.email'),
            hidden: get(order, 'same_address'),
            split: 2
          },
          {
            name: 'billing_info.dial_code',
            label: dicoLabelsService.getCollectionLabel('dial_code'),
            type: 'dropdown',
            value: get(order, 'billing_info.dial_code'),
            options: globalSettings.countries.map((phone_label, index) => ({
              value: phone_label[2],
              label: dicoLabelsService.decodeFlag(phone_label[0]) + ' ' + phone_label[2],
              indexWeight: index
            })),
            hidden: get(order, 'same_address'),
            split: 6
          },
          {
            name: 'billing_info.phone',
            label: dicoLabelsService.getCollectionLabel('phone', 'long'),
            type: 'text',
            value: get(order, 'billing_info.phone'),
            hidden: get(order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.street_1',
            label: dicoLabelsService.getCollectionLabel('street_1', 'long'),
            type: 'text',
            value: get(order, 'billing_info.street_1'),
            required: true,
            hidden: get(order, 'same_address'),
            split: 2
          },
          {
            name: 'billing_info.street_2',
            label: dicoLabelsService.getCollectionLabel('street_2', 'long'),
            type: 'text',
            maxlength: 35,
            value: get(order, 'billing_info.street_2'),
            hidden: get(order, 'same_address'),
            split: 2
          },
          {
            name: 'billing_info.postal_code',
            label: dicoLabelsService.getCollectionLabel('postal_code', 'long'),
            type: 'text',
            value: get(order, 'billing_info.postal_code'),
            required: true,
            hidden: get(order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.city',
            label: dicoLabelsService.getCollectionLabel('city', 'long'),
            type: 'text',
            value: get(order, 'billing_info.city'),
            required: true,
            hidden: get(order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'dropdown',
            options: globalSettings.countries.map((country, index) => ({
              value: country[0],
              label: country[1],
              indexWeight: index
            })),
            value: get(order, 'billing_info.country'),
            required: true,
            hidden: get(order, 'same_address'),
            split: 3
          }
        ]
      }
    ]);
  }

  private getOrderCreationForm(order: Order, globalSettings: GlobalSettings, options): Form<Order & OrderAddress> {
    return new Form<Order & OrderAddress>([
      {
        name: 'purchase',
        label: '<i class="fas fa-shopping-basket"></i> Achat',
        controls: [
          {
            name: 'marketplace_id',
            label: dicoLabelsService.getCollectionLabel('marketplace', 'short'),
            type: 'dropdown',
            options: options.marketplaces.map(mp => ({
              value: mp.id,
              label: mp.display_name
            })),
            value: order?.marketplace_id,
            required: true,
            split: 3
          },
          {
            name: 'marketplace_order_id',
            label: dicoLabelsService.getCollectionLabel('marketplace_order_id', 'long'),
            type: 'text',
            value: order.marketplace_order_id,
            required: true,
            split: 3
          },
          {
            name: 'order_date',
            label: dicoLabelsService.getCollectionLabel('order_date', 'long'),
            type: 'datetime-local',
            value: order.order_date,
            required: true,
            split: 3
          },
          {
            name: 'currency',
            label: dicoLabelsService.getCollectionLabel('currency', 'long'),
            type: 'dropdown',
            options: globalSettings.currencies.map((currency, index) => ({
              value: currency[0],
              label: `${currency[1]} (${currency[0]})`,
              indexWeight: index
            })),
            value: order.currency,
            required: true,
            split: 3
          },
          {
            name: 'payment_method',
            label: dicoLabelsService.getCollectionLabel('payment_method'),
            type: 'dropdown',
            options: globalSettings.payment_methods.map((payment_method, index) => ({
              value: payment_method[0],
              label: payment_method[1],
              indexWeight: index
            })),
            value: order.payment_method,
            split: 3
          }
        ]
      },
      {
        name: 'dispatch_info',
        label: '<i class="fas fa-dolly"></i> Expédition',
        controls: [
          {
            name: 'express',
            label: dicoLabelsService.getCollectionLabel('express', 'long'),
            type: 'toggle',
            value: order.express,
            split: 2
          },
          {
            name: 'shipping_fees',
            label: dicoLabelsService.getCollectionLabel('shipping_fees', 'long'),
            type: 'number',
            value: order.shipping_fees,
            unit: dicoLabelsService.getUnit('money'),
            required: true,
            split: 2
          }
        ]
      },
      {
        name: 'shipping_info',
        label: '<i class="fas fa-dolly"></i> Adresse de livraison',
        controls: [
          {
            name: 'shipping_info.last_name',
            label: dicoLabelsService.getCollectionLabel('last_name', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.last_name'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.first_name',
            label: dicoLabelsService.getCollectionLabel('first_name', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.first_name'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.company',
            label: dicoLabelsService.getCollectionLabel('company', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.company'),
            split: 3
          },
          {
            name: 'shipping_info.email',
            label: dicoLabelsService.getCollectionLabel('email', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.email'),
            split: 2
          },
          {
            name: 'shipping_info.dial_code',
            label: dicoLabelsService.getCollectionLabel('dial_code'),
            type: 'dropdown',
            value: get(order, 'shipping_info.dial_code'),
            options: globalSettings.countries.map((phone_label, index) => ({
              value: phone_label[2],
              label: dicoLabelsService.decodeFlag(phone_label[0]) + ' ' + phone_label[2],
              indexWeight: index
            })),
            split: 6
          },
          {
            name: 'shipping_info.phone',
            label: dicoLabelsService.getCollectionLabel('phone', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.phone'),
            split: 3
          },
          {
            name: 'shipping_info.street_1',
            label: dicoLabelsService.getCollectionLabel('street_1', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.street_1'),
            required: true,
            split: 2
          },
          {
            name: 'shipping_info.street_2',
            label: dicoLabelsService.getCollectionLabel('street_2', 'long'),
            type: 'text',
            maxlength: 35,
            value: get(order, 'shipping_info.street_2'),
            split: 2
          },
          {
            name: 'shipping_info.postal_code',
            label: dicoLabelsService.getCollectionLabel('postal_code', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.postal_code'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.city',
            label: dicoLabelsService.getCollectionLabel('city', 'long'),
            type: 'text',
            value: get(order, 'shipping_info.city'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'dropdown',
            options: globalSettings.countries.map((country, index) => ({
              value: country[0],
              label: country[1],
              indexWeight: index
            })),
            value: get(order, 'shipping_info.country'),
            required: true,
            split: 3
          }
        ]
      },
      {
        name: 'billing_info',
        label: '<i class="fas fa-money-bill-alt"></i> Adresse de facturation',
        controls: [
          {
            name: 'same_address',
            label: dicoLabelsService.getCollectionLabel('same_address', 'long'),
            type: 'toggle',
            value: get(order, 'same_address'),
            afterChange: async (value: boolean, group) => {
              group.get('billing_info.last_name').hidden = value;
              group.get('billing_info.first_name').hidden = value;
              group.get('billing_info.company').hidden = value;
              group.get('billing_info.vat_number').hidden = value;
              group.get('billing_info.nif_code').hidden = value;
              group.get('billing_info.email').hidden = value;
              group.get('billing_info.phone').hidden = value;
              group.get('billing_info.street_1').hidden = value;
              group.get('billing_info.street_2').hidden = value;
              group.get('billing_info.postal_code').hidden = value;
              group.get('billing_info.city').hidden = value;
              group.get('billing_info.country').hidden = value;
              group.get('billing_info.dial_code').hidden = value;
            }
          },
          {
            name: 'billing_info.last_name',
            label: dicoLabelsService.getCollectionLabel('last_name', 'long'),
            type: 'text',
            value: get(order, 'billing_info.last_name'),
            required: true,
            hidden: get(order, 'same_address'),
            split: 2
          },
          {
            name: 'billing_info.first_name',
            label: dicoLabelsService.getCollectionLabel('first_name', 'long'),
            type: 'text',
            value: get(order, 'billing_info.first_name'),
            required: true,
            hidden: get(order, 'same_address'),
            split: 2
          },
          {
            name: 'billing_info.company',
            label: dicoLabelsService.getCollectionLabel('company', 'long'),
            type: 'text',
            value: get(order, 'billing_info.company'),
            hidden: get(order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.vat_number',
            label: dicoLabelsService.getCollectionLabel('vat_number', 'long'),
            type: 'text',
            value: get(order, 'billing_info.vat_number'),
            hidden: get(order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.nif_code',
            label: dicoLabelsService.getCollectionLabel('nif_code', 'long'),
            type: 'text',
            value: get(order, 'billing_info.nif_code'),
            split: 3
          },
          {
            name: 'billing_info.email',
            label: dicoLabelsService.getCollectionLabel('email', 'long'),
            type: 'text',
            value: get(order, 'billing_info.email'),
            hidden: get(order, 'same_address'),
            split: 2
          },
          {
            name: 'billing_info.dial_code',
            label: dicoLabelsService.getCollectionLabel('dial_code'),
            type: 'dropdown',
            value: get(order, 'billing_info.dial_code'),
            options: globalSettings.countries.map((phone_label, index) => ({
              value: phone_label[2],
              label: dicoLabelsService.decodeFlag(phone_label[0]) + ' ' + phone_label[2],
              indexWeight: index
            })),
            hidden: get(order, 'same_address'),
            split: 6
          },
          {
            name: 'billing_info.phone',
            label: dicoLabelsService.getCollectionLabel('phone', 'long'),
            type: 'text',
            value: get(order, 'billing_info.phone'),
            hidden: get(order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.street_1',
            label: dicoLabelsService.getCollectionLabel('street_1', 'long'),
            type: 'text',
            value: get(order, 'billing_info.street_1'),
            required: true,
            hidden: get(order, 'same_address'),
            split: 2
          },
          {
            name: 'billing_info.street_2',
            label: dicoLabelsService.getCollectionLabel('street_2', 'long'),
            type: 'text',
            value: get(order, 'billing_info.street_2'),
            maxlength: 35,
            hidden: get(order, 'same_address'),
            split: 2
          },
          {
            name: 'billing_info.postal_code',
            label: dicoLabelsService.getCollectionLabel('postal_code', 'long'),
            type: 'text',
            value: get(order, 'billing_info.postal_code'),
            required: true,
            hidden: get(order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.city',
            label: dicoLabelsService.getCollectionLabel('city', 'long'),
            type: 'text',
            value: get(order, 'billing_info.city'),
            required: true,
            hidden: get(order, 'same_address'),
            split: 3
          },
          {
            name: 'billing_info.country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'dropdown',
            options: globalSettings.countries.map((country, index) => ({
              value: country[0],
              label: country[1],
              indexWeight: index
            })),
            value: get(order, 'billing_info.country'),
            required: true,
            hidden: get(order, 'same_address'),
            split: 3
          }
        ]
      }
    ]);
  }

  private getCageForm(): Form<Cage> {
    return new Form<Cage>([
      {
        name: 'add',
        label: '<i class="fas fa-plus"></i> Ajouter',
        controls: [
          {
            name: 'scan',
            type: 'text'
          }
        ]
      },
      {
        name: 'list',
        label: '<i class="fas fa-list"></i> Liste des cartons',
        controls: [
          {
            name: 'test',
            label: 'test',
            type: 'text'
          }
        ]
      }
    ]);
  }

  private getCommissionForm(commission: Commission): Form<Commission> {
    return new Form<Commission>([
      {
        name: 'information',
        label: '<i class="fas fa-info"></i> Informations',
        controls: [
          {
            name: 'rate',
            label: dicoLabelsService.getCollectionLabel('rate'),
            type: 'number',
            value: commission.rate,
            split: 2
          },
          {
            name: 'fixed_amount_commission',
            label: dicoLabelsService.getCollectionLabel('fixed_amount'),
            type: 'number',
            value: commission.fixed_amount,
            split: 2
          }
        ]
      },
      {
        name: 'period',
        label: '<i class="fas fa-calendar"></i> Durée',
        controls: [
          {
            name: 'start_at',
            label: dicoLabelsService.getCollectionLabel('start_date'),
            type: 'datetime-local',
            value: commission.start_at,
            required: true
          },
          {
            name: 'end_at',
            label: dicoLabelsService.getCollectionLabel('end_date'),
            type: 'datetime-local',
            value: commission.end_at
          }
        ]
      }
    ]);
  }

  private getCommissionCreationForm(commission: Commission, globalSettings: GlobalSettings, options): Form<Commission> {
    return new Form<Commission>([
      {
        name: 'information',
        label: '<i class="fas fa-info"></i> Informations',
        controls: [
          {
            name: 'marketplace_id',
            label: dicoLabelsService.getCollectionLabel('marketplace'),
            type: 'dropdown',
            options: options.marketplaces.map(mp => ({
              value: mp.id,
              label: mp.display_name
            })),
            value: commission.marketplace?.id,
            required: true,
            split: 2
          },
          {
            name: 'country',
            label: dicoLabelsService.getCollectionLabel('country'),
            type: 'dropdown',
            options: globalSettings.countries.map((country, index) => ({
              value: country[0],
              label: country[1],
              indexWeight: index
            })),
            value: commission.country,
            required: true,
            split: 2
          },
          {
            name: 'rate',
            label: dicoLabelsService.getCollectionLabel('rate'),
            type: 'number',
            value: commission.rate,
            split: 2
          },
          {
            name: 'fixed_amount',
            label: dicoLabelsService.getCollectionLabel('fixed_amount'),
            type: 'number',
            value: commission.fixed_amount,
            split: 2
          }
        ]
      },
      {
        name: 'period',
        label: '<i class="fas fa-calendar"></i> Durée',
        controls: [
          {
            name: 'start_at',
            label: dicoLabelsService.getCollectionLabel('start_date'),
            type: 'datetime-local',
            value: commission.start_at,
            required: true
          },
          {
            name: 'end_at',
            label: dicoLabelsService.getCollectionLabel('end_date'),
            type: 'datetime-local',
            value: commission.end_at
          }
        ]
      }
    ]);
  }

  getPartForm(part: Part): Form<Part> {
    return new Form<Part>([
      {
        name: 'part',
        controls: [
          {
            name: 'part_type',
            label: dicoLabelsService.getCollectionLabel('part_type'),
            type: 'text',
            value: part.part_type,
            disabled: true
          },
          {
            name: 'serial_number',
            label: dicoLabelsService.getCollectionLabel('serial_number'),
            type: 'text',
            value: part.serial_number,
            disabled: true,
            split: 2
          },
          {
            name: 'grade',
            label: dicoLabelsService.getCollectionLabel('grade'),
            type: 'text',
            value: part.grade,
            disabled: true,
            split: 2
          },
          {
            name: 'modeles',
            label: dicoLabelsService.getCollectionLabel('modeles'),
            type: 'text',
            value: part.modele,
            disabled: true,
            split: 2
          },
          {
            name: 'taille',
            label: dicoLabelsService.getCollectionLabel('taille'),
            type: 'text',
            value: part.taille,
            disabled: true,
            split: 2
          },

          {
            name: 'processeur',
            label: dicoLabelsService.getCollectionLabel('processeur'),
            type: 'text',
            value: part.processeur,
            disabled: true,
            split: 2
          },
          {
            name: 'color',
            label: dicoLabelsService.getCollectionLabel('color'),
            type: 'text',
            value: part.color,
            disabled: true,
            split: 2
          },
          {
            name: 'annee_modele',
            label: dicoLabelsService.getCollectionLabel('annee_modele'),
            type: 'text',
            value: `${part.annee_debut} - ${part.annee_fin}`,
            disabled: true,
            split: 2
          }
        ]
      }
    ]);
  }

  getOrderLineExchangeCreationForm(
    orderLine: OrderLine,
    globalSettings: GlobalSettings
  ): Form<OrderLine & OrderAddress> {
    return new Form<OrderLine & OrderAddress>([
      {
        name: 'purchase',
        label: '<i class="fas fa-shopping-basket"></i> Achat',
        controls: [
          {
            name: 'order_line_type',
            label: dicoLabelsService.getCollectionLabel('order_line_type'),
            type: 'dropdown',
            options: globalSettings.order_line_types.map((type, index) => ({
              value: type[0],
              label: type[1],
              indexWeight: index
            })),
            value: orderLine.order_line_type,
            required: true,
            afterChange: async (value: string, group) => {
              // Adapt form depending on if the order is for a device or an accessory/part
              const isDeviceOrderLine = value === 'device';
              group.get('sku_marketplace').hidden = !isDeviceOrderLine;
              group.get('sku_okamac').label = isDeviceOrderLine
                ? dicoLabelsService.getCollectionLabel('sku_okamac', 'long')
                : dicoLabelsService.getCollectionLabel('accessory_part_id');
              group.get('sku_okamac').split = isDeviceOrderLine ? 2 : 1;
              group.get('sku_okamac').required = isDeviceOrderLine ? false : true;
            }
          },
          {
            name: 'order_id',
            label: dicoLabelsService.getCollectionLabel('order_id', 'short'),
            type: 'number',
            value: orderLine.order_id,
            required: true,
            split: 2
          },
          {
            name: 'marketplace_line_id',
            label: dicoLabelsService.getCollectionLabel('marketplace_order_id', 'long'),
            type: 'text',
            value: orderLine.marketplace_line_id,
            required: true,
            split: 2
          },
          {
            name: 'price',
            label: dicoLabelsService.getCollectionLabel('price', 'long'),
            type: 'number',
            value: orderLine.price,
            required: true,
            split: 3
          },
          {
            name: 'product_description',
            label: dicoLabelsService.getCollectionLabel('product_description', 'long'),
            type: 'text',
            value: orderLine.product_description
          },
          {
            name: 'sku_marketplace',
            label: dicoLabelsService.getCollectionLabel('sku_marketplace', 'long'),
            type: 'text',
            value: orderLine.sku_marketplace,
            required: true,
            split: 2
          },
          {
            name: 'sku_okamac',
            label: dicoLabelsService.getCollectionLabel('sku_okamac', 'long'),
            type: 'text',
            value: orderLine.sku_okamac,
            split: 2
          },
          {
            name: 'warranty',
            label: dicoLabelsService.getCollectionLabel('warranty', 'long'),
            type: 'number',
            value: orderLine.warranty,
            unit: dicoLabelsService.getUnit('month'),
            required: true,
            split: 2
          },
          {
            name: 'warranty_amount',
            label: dicoLabelsService.getCollectionLabel('warranty_amount', 'long'),
            type: 'number',
            value: orderLine.warranty_amount,
            unit: dicoLabelsService.getUnit('money'),
            split: 2
          },
          {
            name: 'return_id',
            label: 'Id de retour',
            type: 'text',
            value: orderLine.return_id,
            split: 2
          }
        ]
      },
      {
        name: 'battery',
        label: '<i class="fas fa-battery-full"></i> Batterie neuve',
        controls: [
          {
            name: 'new_battery_required',
            label: dicoLabelsService.getCollectionLabel('new_battery_required', 'long'),
            type: 'toggle',
            value: get(orderLine, 'new_battery_required'),
            split: 2,
            afterChange: async (value: boolean, group) => {
              group.get('new_battery_price').hidden = value !== true;
              if (value !== true) {
                group.get('new_battery_price').value = null;
              }
            }
          },
          {
            name: 'new_battery_price',
            label: dicoLabelsService.getCollectionLabel('new_battery_price', 'long'),
            type: 'number',
            value: get(orderLine, 'new_battery_price'),
            unit: dicoLabelsService.getUnit('money'),
            required: true,
            split: 2
          }
        ]
      }
    ]);
  }

  private getOrderReturnCreationForm(
    orderReturn: OrderReturn,
    globalSettings: GlobalSettings,
    options
  ): Form<OrderReturn & OrderAddress> {
    const { UserCanValidateOrderReturnPayment } = options;
    return new Form<OrderReturn & OrderAddress>([
      {
        name: 'order_return_creation',
        controls: [
          {
            name: 'return_type',
            label: dicoLabelsService.getCollectionLabel('return_type'),
            type: 'dropdown',
            options: globalSettings.return_types.map((type, index) => ({
              value: type[0],
              label: type[1],
              indexWeight: index
            })),
            value: 'indeterminate',
            afterChange: async (value: string, group) => {
              group.get('upgrade_amount').hidden = value !== 'exchange';
              group.get('new_sku').hidden = value !== 'exchange';
              group.get('is_paid').hidden = !UserCanValidateOrderReturnPayment || value !== 'exchange';
              group.get('declared_faulty').hidden = value !== 'sav';
              group.get('reasons').value = [];
              group.get('reasons').hidden = ['exchange', 'retractation'].indexOf(value) < 0;
              if (value !== 'exchange') {
                group.get('upgrade_amount').value = null;
                group.get('new_sku').value = null;
                group.get('is_paid').value = false;
              }
              if (value == null) {
                group.get('declared_faulty').value = [];
              }
            }
          },
          {
            name: 'upgrade_amount',
            label: dicoLabelsService.getCollectionLabel('upgrade_amount'),
            type: 'number',
            value: get(orderReturn, 'upgrade_amount'),
            unit: dicoLabelsService.getUnit('money'),
            split: 2
          },
          {
            name: 'new_sku',
            label: dicoLabelsService.getCollectionLabel('new_sku'),
            type: 'text',
            value: get(orderReturn, 'new_sku'),
            split: 2
          },
          {
            name: 'is_paid',
            label: dicoLabelsService.getCollectionLabel('is_paid'),
            type: 'toggle',
            value: get(orderReturn, 'is_paid'),
            split: 2
          },
          {
            name: 'customer_return_date',
            label: dicoLabelsService.getCollectionLabel('customer_return_date', 'long'),
            type: 'date',
            value: get(orderReturn, 'customer_return_date')
          },
          {
            name: 'reasons',
            label: dicoLabelsService.getCollectionLabel('reasons', 'long'),
            type: 'multi-dropdown',
            value: orderReturn.reasons,
            options: globalSettings.retractation_reasons.map(component => ({
              value: component[0],
              label: component[1]
            })),
            afterChange: async (value: string, group) => {
              group.get('declared_faulty').hidden =
                value.indexOf('failure') == -1 && group.get('return_type').value != 'sav';
            },
            required: true,
            enableAllField: false
          },
          {
            name: 'declared_faulty',
            label: dicoLabelsService.getCollectionLabel('declared_faulty', 'long'),
            type: 'multi-dropdown',
            value: orderReturn.declared_faulty,
            options: globalSettings.return_declared_faulties.map(faulty => ({
              value: faulty[0],
              label: faulty[1]
            })),
            enableAllField: false
          },
          {
            name: 'is_urgent',
            label: dicoLabelsService.getCollectionLabel('is_urgent'),
            type: 'toggle',
            value: get(orderReturn, 'is_urgent'),
            split: 2
          },
          {
            name: 'charger_included',
            label: dicoLabelsService.getCollectionLabel('charger_included'),
            type: 'toggle',
            value: get(orderReturn, 'charger_included'),
            split: 2
          }
          // {
          //   name: 'customer_slip',
          //   label: dicoLabelsService.getCollectionLabel('customer_slip'),
          //   type: 'text',
          //   value: get(orderReturn, 'customer_slip')
          // }
        ]
      }
    ]);
  }

  private getOrderReturnForm(
    orderReturn: OrderReturn,
    globalSettings: GlobalSettings,
    options
  ): Form<OrderReturn & OrderAddress> {
    const shippingCompany = globalSettings.shipping_companies.find(sp => sp[0] === orderReturn.shipping_company);
    const shippingTypes = (shippingCompany && shippingCompany[1].types) || [];
    const status_code = globalSettings?.expedition_status?.find(
      sp => sp[0] === String(orderReturn.carrier_label_status)
    );
    const statusLabel = status_code ? status_code[1] : '';
    const receptionCompany = globalSettings.reception_companies.find(sp => sp[0] === orderReturn.reception_company);
    const receptionTypes = (receptionCompany && receptionCompany[1].types) || [];
    const { UserCanValidateOrderReturnPayment } = options;
    return new Form<OrderReturn & OrderAddress>([
      {
        name: 'order_return',
        label: '<i class="fas fa-arrow-down"></i> Retour',
        controls: [
          {
            name: 'return_type',
            label: dicoLabelsService.getCollectionLabel('return_type'),
            type: 'dropdown',
            options: globalSettings.return_types.map((type, index) => ({
              value: type[0],
              label: type[1],
              indexWeight: index
            })),
            value: orderReturn.return_type,
            afterChange: async (value: string, group) => {
              group.get('upgrade_amount').hidden = value !== 'exchange';
              group.get('new_sku').hidden = value !== 'exchange';
              group.get('is_paid').hidden = !UserCanValidateOrderReturnPayment || value !== 'exchange';
              if (['exchange', 'retractation'].indexOf(value) < 0) {
                group.get('reasons').value = [];
              }
              group.get('reasons').hidden = ['exchange', 'retractation'].indexOf(value) < 0;
              if (value !== 'exchange') {
                group.get('upgrade_amount').value = null;
                group.get('new_sku').value = null;
                group.get('is_paid').value = false;
              }
              if (value == null || value == 'indeterminate') {
                group.get('declared_faulty').hidden = true;
                group.get('declared_faulty').value = [];
              } else {
                group.get('declared_faulty').hidden = false;
              }
            },
            disabled: !!orderReturn.reception_validated_at,
            split: 2
          },
          {
            name: 'date_reception',
            label: dicoLabelsService.getCollectionLabel('date_reception'),
            type: 'datetime-local',
            value: orderReturn.reception_validated_at,
            disabled: true,
            split: 2
          },
          {
            name: 'upgrade_amount',
            label: dicoLabelsService.getCollectionLabel('upgrade_amount'),
            type: 'number',
            value: get(orderReturn, 'upgrade_amount'),
            unit: dicoLabelsService.getUnit('money'),
            split: 2
          },
          {
            name: 'new_sku',
            label: dicoLabelsService.getCollectionLabel('new_sku'),
            type: 'text',
            value: get(orderReturn, 'new_sku'),
            split: 2
          },
          {
            name: 'is_paid',
            label: dicoLabelsService.getCollectionLabel('is_paid'),
            type: 'toggle',
            value: get(orderReturn, 'is_paid'),
            split: 2
          },
          {
            name: 'customer_return_date',
            label: dicoLabelsService.getCollectionLabel('customer_return_date', 'long'),
            type: 'date',
            value: get(orderReturn, 'customer_return_date')
          },
          {
            name: 'reasons',
            label: dicoLabelsService.getCollectionLabel('reasons', 'long'),
            type: 'multi-dropdown',
            value: orderReturn.reasons,
            options: globalSettings.retractation_reasons.map(component => ({
              value: component[0],
              label: component[1]
            })),
            enableAllField: false
          },
          {
            name: 'declared_faulty',
            label: dicoLabelsService.getCollectionLabel('declared_faulty', 'long'),
            type: 'multi-dropdown',
            value: orderReturn.declared_faulty,
            options: globalSettings.return_declared_faulties.map(faulty => ({
              value: faulty[0],
              label: faulty[1]
            })),
            enableAllField: false
          },
          {
            name: 'accessory_problems',
            label: dicoLabelsService.getCollectionLabel('accessory_problems'),
            type: 'multi-dropdown',
            value: orderReturn.accessory_problems,
            options: globalSettings.accessory_problems.map(faulty => ({
              value: faulty[0],
              label: faulty[1]
            })),
            enableAllField: false
          },
          {
            name: 'quality_defaults',
            label: dicoLabelsService.getCollectionLabel('quality_defaults'),
            type: 'multi-dropdown',
            value: orderReturn.quality_defaults,
            options: globalSettings.quality_defaults.map(faulty => ({
              value: faulty[0],
              label: faulty[1]
            })),
            enableAllField: false
          },
          {
            name: 'is_urgent',
            label: dicoLabelsService.getCollectionLabel('is_urgent'),
            type: 'toggle',
            value: get(orderReturn, 'is_urgent'),
            split: 2
          },
          {
            name: 'charger_included',
            label: dicoLabelsService.getCollectionLabel('charger_included'),
            type: 'toggle',
            value: get(orderReturn, 'charger_included'),
            split: 2
          }
        ]
      },
      {
        name: 'orderLine',
        label: '<i class="fas fa-shopping-basket"></i> Commande',
        controls: [
          {
            name: 'marketplace',
            label: dicoLabelsService.getCollectionLabel('marketplace', 'short'),
            type: 'text',
            value: orderReturn.order_line.order.marketplace.display_name,
            disabled: true,
            split: 2
          },
          {
            name: 'marketplace_order_id',
            label: dicoLabelsService.getCollectionLabel('marketplace_order_id', 'long'),
            type: 'text',
            value: orderReturn.order_line.order.marketplace_order_id,
            required: true,
            disabled: true,
            split: 2
          },
          {
            name: 'marketplace_line_id',
            label: dicoLabelsService.getCollectionLabel('marketplace_line_id', 'long'),
            type: 'text',
            value: orderReturn.order_line.marketplace_line_id,
            required: true,
            disabled: true
          },
          {
            name: 'order_date',
            label: dicoLabelsService.getCollectionLabel('order_date', 'long'),
            type: 'datetime-local',
            value: orderReturn.order_line.order.order_date,
            required: true,
            disabled: true,
            split: 2
          },
          {
            name: 'warranty',
            label: dicoLabelsService.getCollectionLabel('warranty', 'long'),
            type: 'number',
            value: orderReturn.order_line.warranty,
            unit: dicoLabelsService.getUnit('month'),
            required: true,
            disabled: true,
            split: 2
          },
          {
            name: 'sku_okamac',
            label: dicoLabelsService.getCollectionLabel('sku_okamac', 'long'),
            type: 'text',
            value: orderReturn.order_line.sku_okamac,
            disabled: true,
            split: 2
          },
          {
            name: 'price',
            label: dicoLabelsService.getCollectionLabel('price', 'long'),
            type: 'number',
            value: orderReturn.order_line.price,
            unit: orderReturn.order_line.order.currency,
            required: true,
            disabled: true,
            split: 2
          }
        ]
      },
      {
        name: 'return_info',
        label: '<i class="fas fa-dolly"></i> Réception',
        controls: [
          {
            name: 'reception_company',
            label: dicoLabelsService.getCollectionLabel('shipping_company', 'long'),
            type: 'dropdown',
            options: globalSettings.reception_companies.map((sc, index) => ({
              value: sc[0],
              label: sc[1].display_name,
              indexWeight: index
            })),
            value: orderReturn.reception_company,
            afterChange: async (value: string, group) => {
              const company = globalSettings.reception_companies.find(sp => sp[0] === value);
              const types = (company && company[1].types) || [];
              const options = types.map(type => ({
                value: type,
                label: type
              }));
              group.get('reception_type').options = options;
            },
            disabled: true,
            split: 2
          },
          {
            name: 'reception_type',
            label: dicoLabelsService.getCollectionLabel('shipping_type', 'long'),
            type: 'dropdown',
            options: receptionTypes.map(type => ({
              value: type,
              label: type
            })),
            value: orderReturn.reception_type,
            disabled: true,
            split: 2
          },
          {
            name: 'reception_date',
            label: dicoLabelsService.getCollectionLabel('print_return_label_date'),
            type: 'datetime-local',
            value: orderReturn.reception_date,
            disabled: true,
            split: 2
          },
          {
            name: 'reception_tracking_number',
            label: dicoLabelsService.getCollectionLabel('shipping_tracking_number', 'long'),
            type: 'text',
            value: orderReturn.reception_tracking_number,
            disabled: true,
            split: 2
          }
        ]
      },
      {
        name: 'dispatch_info',
        label: '<i class="fas fa-dolly"></i> Expédition',
        controls: [
          {
            name: 'shipping_company',
            label: dicoLabelsService.getCollectionLabel('shipping_company', 'long'),
            type: 'dropdown',
            options: globalSettings.shipping_companies.map((sc, index) => ({
              value: sc[0],
              label: sc[1].display_name,
              indexWeight: index
            })),
            value: orderReturn.shipping_company,
            afterChange: async (value: string, group) => {
              const company = globalSettings.shipping_companies.find(sp => sp[0] === value);
              const types = (company && company[1].types) || [];
              const options = types.map(type => ({
                value: type,
                label: type
              }));
              group.get('shipping_type').options = options;
            },
            split: 2
          },
          {
            name: 'shipping_type',
            label: dicoLabelsService.getCollectionLabel('shipping_type', 'long'),
            type: 'dropdown',
            options: shippingTypes.map(type => ({
              value: type,
              label: type
            })),
            value: orderReturn.shipping_type,
            split: 2
          },
          {
            name: 'max_shipping_date',
            label: dicoLabelsService.getCollectionLabel('max_shipping_date', 'long'),
            type: 'datetime-local',
            value: orderReturn.max_shipping_date,
            disabled: true,
            split: 2
          },
          {
            name: 'shipping_date',
            label: dicoLabelsService.getCollectionLabel('shipping_date', 'long'),
            type: 'datetime-local',
            value: orderReturn.shipping_date,
            disabled: true,
            split: 2
          },
          {
            name: 'shipping_tracking_number',
            label: dicoLabelsService.getCollectionLabel('shipping_tracking_number', 'long'),
            type: 'text',
            value: orderReturn.shipping_tracking_number,
            disabled: true,
            split: 2
          },
          {
            name: 'expedition_status',
            label: dicoLabelsService.getCollectionLabel('expedition_status'),
            type: 'text',
            value: statusLabel,
            required: false,
            split: 2
          }
        ]
      },
      {
        name: 'shipping_info',
        label: '<i class="fas fa-dolly"></i> Adresse de livraison',
        controls: [
          {
            name: 'shipping_info.last_name',
            label: dicoLabelsService.getCollectionLabel('last_name', 'long'),
            type: 'text',
            value: get(orderReturn, 'shipping_info.last_name'),
            required: true,
            split: 2
          },
          {
            name: 'shipping_info.first_name',
            label: dicoLabelsService.getCollectionLabel('first_name', 'long'),
            type: 'text',
            value: get(orderReturn, 'shipping_info.first_name'),
            required: true,
            split: 2
          },
          {
            name: 'shipping_info.company',
            label: dicoLabelsService.getCollectionLabel('company', 'long'),
            type: 'text',
            value: get(orderReturn, 'shipping_info.company')
          },
          {
            name: 'shipping_info.email',
            label: dicoLabelsService.getCollectionLabel('email', 'long'),
            type: 'text',
            value: get(orderReturn, 'shipping_info.email'),
            split: 3
          },
          {
            name: 'shipping_info.dial_code',
            label: dicoLabelsService.getCollectionLabel('dial_code'),
            type: 'dropdown',
            value: get(orderReturn, 'shipping_info.dial_code'),
            options: globalSettings.countries.map((phone_label, index) => ({
              value: phone_label[2],
              label: dicoLabelsService.decodeFlag(phone_label[0]) + ' ' + phone_label[2],
              indexWeight: index
            })),
            split: 4
          },
          {
            name: 'shipping_info.phone',
            label: dicoLabelsService.getCollectionLabel('phone', 'long'),
            type: 'text',
            value: get(orderReturn, 'shipping_info.phone'),
            split: 3
          },
          {
            name: 'shipping_info.street_1',
            label: dicoLabelsService.getCollectionLabel('street_1', 'long'),
            type: 'text',
            value: get(orderReturn, 'shipping_info.street_1'),
            required: true
          },
          {
            name: 'shipping_info.street_2',
            label: dicoLabelsService.getCollectionLabel('street_2', 'long'),
            type: 'text',
            maxlength: 35,
            value: get(orderReturn, 'shipping_info.street_2')
          },
          {
            name: 'shipping_info.postal_code',
            label: dicoLabelsService.getCollectionLabel('postal_code', 'long'),
            type: 'text',
            value: get(orderReturn, 'shipping_info.postal_code'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.city',
            label: dicoLabelsService.getCollectionLabel('city', 'long'),
            type: 'text',
            value: get(orderReturn, 'shipping_info.city'),
            required: true,
            split: 3
          },
          {
            name: 'shipping_info.country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'dropdown',
            options: globalSettings.countries.map((country, index) => ({
              value: country[0],
              label: country[1],
              indexWeight: index
            })),
            value: get(orderReturn, 'shipping_info.country'),
            required: true,
            split: 3
          }
        ]
      },
      {
        name: 'status',
        label: '<i class="fas fa-headset"></i> Demandes particulières',
        controls: [
          {
            name: 'out_of_stock',
            label: dicoLabelsService.getCollectionLabel('out_of_stock', 'long'),
            type: 'toggle',
            value: get(orderReturn, 'out_of_stock'),
            split: 2
          }
        ]
      }
    ]);
  }

  private getProductionOrderCreationForm(productionOrder: ProductionOrder): Form<ProductionOrder> {
    return new Form<ProductionOrder>([
      {
        name: 'production-order-line-creation',
        label: '<i class="fas fa-microchip"></i> Ordinateur à fabriquer',
        controls: [
          {
            name: 'sku',
            label: dicoLabelsService.getCollectionLabel('sku', 'long'),
            type: 'text',
            value: productionOrder.sku,
            required: true
          }
        ]
      }
    ]);
  }

  private getProductionOrderForm(productionOrder: ProductionOrder): Form<ProductionOrder> {
    return new Form<ProductionOrder>([
      {
        name: 'production-order',
        label: 'Ordre de fabrication',
        controls: [
          {
            name: 'sku',
            label: dicoLabelsService.getCollectionLabel('sku', 'long'),
            type: 'text',
            value: productionOrder.sku,
            disabled: true,
            required: true
          },
          {
            name: 'product_description',
            label: dicoLabelsService.getCollectionLabel('product_description', 'long'),
            type: 'text',
            value: productionOrder.product_description,
            disabled: true,
            required: true
          }
        ]
      }
    ]);
  }

  getOrderReturnReceptionForm(globalSettings: GlobalSettings): Form<OrderReturnReceptionOptions> {
    return new Form<OrderReturnReceptionOptions>([
      {
        name: 'reception-options',
        controls: [
          {
            name: 'litigation_reasons',
            label: dicoLabelsService.getCollectionLabel('litigation_reasons'),
            type: 'switch-list',
            value: [],
            options: globalSettings.litigation_reasons.map((reason, index) => ({
              value: reason[0],
              label: reason[1],
              indexWeight: index
            }))
          },
          {
            name: 'charger_included',
            label: dicoLabelsService.getCollectionLabel('charger_included'),
            type: 'toggle',
            split: 2
          },
          {
            name: 'comment',
            label: dicoLabelsService.getCollectionLabel('comment', 'long'),
            type: 'text-multiline',
            required: false
          }
        ]
      }
    ]);
  }

  getOrderReturnExchangeForm(orderReturn: OrderReturn, options): Form<OrderReturnExchangeOptions> {
    const { UserCanValidateOrderReturnPayment } = options;
    return new Form<OrderReturnExchangeOptions>([
      {
        name: 'exchange-options',
        controls: [
          {
            name: 'upgrade_amount',
            label: dicoLabelsService.getCollectionLabel('upgrade_amount'),
            type: 'number',
            value: get(orderReturn, 'upgrade_amount'),
            unit: dicoLabelsService.getUnit('money'),
            split: 2
          },
          {
            name: 'new_sku',
            label: dicoLabelsService.getCollectionLabel('new_sku'),
            type: 'text',
            value: get(orderReturn, 'new_sku'),
            split: 2
          },
          {
            name: 'is_paid',
            label: dicoLabelsService.getCollectionLabel('is_paid'),
            type: 'toggle',
            hidden: !UserCanValidateOrderReturnPayment,
            value: get(orderReturn, 'is_paid'),
            split: 2
          }
        ]
      }
    ]);
  }

  private getPurchaseQueryForm(query: PurchaseQuery): Form<PurchaseQuery> {
    const form = new Form<PurchaseQuery>([
      {
        name: 'product',
        controls: [
          {
            name: 'purchasable_category',
            label: dicoLabelsService.getCollectionLabel('purchasable_category', 'long'),
            type: 'dropdown',
            options: [
              { value: 'spare', label: 'Pièce détachée' },
              { value: 'consumable', label: 'Emballage' },
              { value: 'other', label: 'Autre' }
            ],
            value: query.purchasable_category,
            afterChange: async (value: string, group, hasChanged) => {
              group.get('purchasable_type').hidden = value !== 'spare';
              group.get('purchasable_id').hidden = value === 'other';
              group.get('desc').hidden = value === 'other';
              group.get('price_avg').hidden = value === 'other';
              group.get('other_desc').hidden = value !== 'other';
              group.get('other_reason').hidden = value !== 'other';
              group.get('other_price').hidden = value !== 'other';
              group.get('other_link').hidden = value !== 'other';

              if (hasChanged) {
                group.get('purchasable_type').value = null;
              }

              if (value === 'spare') {
                group.get('purchasable_type').options = [
                  { value: 'AccessoryRef', label: 'Accessoire' },
                  { value: 'BatteryRef', label: 'Batterie' },
                  { value: 'ChargerRef', label: 'Chargeur' },
                  { value: 'HardDriveRef', label: 'Disque Dur' },
                  { value: 'RamRef', label: 'Mémoire RAM' }
                ];
              }

              if (value === 'consumable') {
                group.get('purchasable_type').value = 'ConsumableRef';
                return consumablesService.list().then(res => {
                  if (!res) throw new Error('Impossible de récupérer la liste de valeurs');
                  group.get('purchasable_id').options = res?.values?.map(value => ({
                    value: value.id,
                    label: value.code,
                    details: value
                  }));
                });
              }
            }
          },
          {
            name: 'other_desc',
            label: dicoLabelsService.getCollectionLabel('other_desc', 'long'),
            type: 'text',
            value: query.other_desc,
            required: true
          },
          {
            name: 'other_reason',
            label: dicoLabelsService.getCollectionLabel('other_reason', 'long'),
            type: 'text',
            value: query.other_reason,
            required: true
          },
          {
            name: 'other_link',
            label: dicoLabelsService.getCollectionLabel('other_link', 'long'),
            type: 'text',
            value: query.other_link,
            required: true
          },
          {
            name: 'other_price',
            label: dicoLabelsService.getCollectionLabel('other_price', 'long'),
            type: 'number',
            value: query.other_price,
            unit: dicoLabelsService.getUnit('money'),
            required: true,
            step: '0.01',
            split: 2
          },
          {
            name: 'purchasable_type',
            label: dicoLabelsService.getCollectionLabel('purchasable_type', 'long'),
            type: 'dropdown',
            options: [],
            value: query.purchasable_type,
            afterChange: (value: string, group, hasChanged) => {
              if (!value) return;
              if (hasChanged) group.get('purchasable_id').value = null;
              if (group.get('purchasable_category').value !== 'spare') return;
              return sparesService.list({ filters: { spare_type: value } }).then(res => {
                if (!res) throw new Error('Impossible de récupérer la liste de valeurs');
                group.get('purchasable_id').options = res?.values?.map(value => ({
                  value: value.id,
                  label: value.code,
                  details: value
                }));
              });
            }
          },
          {
            name: 'purchasable_id',
            label: dicoLabelsService.getCollectionLabel('purchasable_id', 'long'),
            type: 'dropdown',
            options: [],
            value: query.purchasable_id,
            afterChange: async (value: number, group) => {
              const options = group.get('purchasable_id').options;
              if (!options) throw new Error('Impossible de récupérer la liste de valeurs');
              const option = options.find(opt => opt.value === value);

              group.get('desc').value = option?.details?.desc;
              group.get('price_avg').value = option?.details?.price_avg;
            },
            required: true
          },
          {
            name: 'desc',
            label: dicoLabelsService.getCollectionLabel('desc', 'long'),
            type: 'text',
            value: query.purchasable?.desc,
            disabled: true,
            required: true
          },
          {
            name: 'price_avg',
            label: dicoLabelsService.getCollectionLabel('price_avg', 'long'),
            type: 'number',
            value: query.purchasable?.price_avg,
            step: '0.01',
            disabled: true,
            split: 2
          },
          {
            name: 'quantity_query',
            label: dicoLabelsService.getCollectionLabel('quantity_query', 'long'),
            type: 'number',
            value: query.quantity_query,
            step: '0.01',
            required: true,
            split: 2
          },
          {
            name: 'comment',
            label: dicoLabelsService.getCollectionLabel('comment', 'long'),
            type: 'text-multiline',
            value: query.comment,
            required: true
          }
        ]
      }
    ]);

    return form;
  }

  private getPurchaseForm(purchase: Purchase, settings: GlobalSettings): Form<Purchase> {
    if (!settings) return;
    if (!purchase.supplier.ribs) return;

    const statusIndex = ['negotiation', 'shipping', 'received', 'control', 'validated'].indexOf(
      purchase.purchase_status
    );

    const form = new Form<Purchase>([
      {
        name: 'supplier',
        label: '<i class="fas fa-user-circle"></i> Fournisseur',
        controls: [
          {
            name: 'supplier_id',
            label: dicoLabelsService.getCollectionLabel('last_name', 'short'),
            type: 'dropdown',
            options: [],
            value: purchase.supplier?.id,
            disabled: statusIndex > 0,
            required: true,
            afterChange: (value: string, group) => {
              const options = group.get('supplier_id').options;
              if (!options?.length) {
                // Options init
                return suppliersService.order_list({ filters: { category: purchase.item_type } }).then(resp => {
                  const suppliers = resp.values || [];
                  if (!suppliers) throw new Error('Impossible de récupérer la liste des fournisseurs');
                  group.get('supplier_id').options = suppliers?.map(value => ({
                    value: value.id,
                    label: value.display_name,
                    details: value
                  }));
                });
              } else {
                const option = options.find(opt => opt.value === value);
                if (!group.get('supplier_country').value) {
                  group.get('supplier_country').value = option?.details?.country;
                }
                if (!group.get('currency').value) {
                  group.get('currency').value = option?.details?.currency;
                }
                if (!group.get('tax_scheme').value) {
                  group.get('tax_scheme').value = option?.details?.tax_scheme;
                }
                if (!group.get('payment_timing').value) {
                  group.get('payment_timing').value = option?.details?.payment_timing;
                  group.get('payment_delay').value = option?.details?.payment_delay;
                  group.get('payment_delay').hidden = option?.details?.payment_timing !== 'delay';
                }
              }
            }
          },
          {
            name: 'supplier_country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'text',
            value: purchase.supplier_country,
            required: true,
            split: 3
          },
          {
            name: 'currency',
            label: dicoLabelsService.getCollectionLabel('currency', 'long'),
            type: 'dropdown',
            value: purchase.currency,
            disabled: false,
            options: [
              { value: 'eur', label: 'EUR' },
              { value: 'gbp', label: 'GBP' },
              { value: 'usd', label: 'USD' }
            ],
            required: true,
            split: 3
          },
          {
            name: 'tax_scheme',
            label: dicoLabelsService.getCollectionLabel('tax_scheme', 'short'),
            value: purchase.tax_scheme,
            type: 'dropdown',
            options: settings.schemes.map((scheme, index) => ({
              value: scheme[0],
              label: scheme[1],
              indexWeight: index
            })),
            disabled: false,
            required: true,
            split: 3
          },
          {
            name: 'reference_internal',
            label: dicoLabelsService.getCollectionLabel('reference_internal', 'long'),
            type: 'text',
            value: purchase.reference_internal,
            disabled: true,
            split: 2
          },
          {
            name: 'reference_supplier',
            label: dicoLabelsService.getCollectionLabel('reference_supplier', 'long'),
            type: 'text',
            value: purchase.reference_supplier,
            disabled: false,
            required: false,
            split: 2
          },
          {
            name: 'incoterm',
            label: dicoLabelsService.getCollectionLabel('incoterm', 'short'),
            value: purchase.incoterm,
            type: 'dropdown',
            options: ['CIF', 'CIP', 'CFR', 'CPT', 'DAP', 'DDP', 'DPU', 'EXW', 'FAS', 'FCA', 'FOB'].map(
              (item, index) => ({
                value: item,
                label: item,
                indexWeight: index
              })
            ),
            disabled: false,
            required: false,
            split: 2
          },
          ...(statusIndex === 0
            ? ([
                {
                  name: 'payment_timing',
                  label: dicoLabelsService.getCollectionLabel('payment_timing', 'long'),
                  type: 'dropdown',
                  value: purchase.payment_timing || purchase.supplier?.payment_timing,
                  options: [
                    { value: 'before_reception', label: 'Avant expédition' },
                    { value: 'at_reception', label: 'A la livraison' },
                    { value: 'delay', label: 'A X jours' }
                  ],
                  required: true,
                  split: 2,
                  afterChange: async (value: string, group) => {
                    group.get('payment_delay').hidden = value !== 'delay';
                  }
                },
                {
                  name: 'payment_delay',
                  label: dicoLabelsService.getCollectionLabel('payment_delay', 'long'),
                  type: 'number',
                  value: purchase.payment_delay || purchase.supplier?.payment_delay,
                  required: true
                }
              ] as FormControlRaw<Purchase>[])
            : []),
          {
            name: 'purchase_types',
            label: dicoLabelsService.getCollectionLabel('purchase_types'),
            type: 'dropdown',
            value: purchase.purchase_types,
            options: settings.purchase_types.map(type => ({
              value: type[0],
              label: type[1]
            })),
            required: true,
            split: 2
          }
        ]
      },
      {
        name: 'purchase',
        label: '<i class="fas fa-money-check-alt"></i> Paiement',
        controls: [
          {
            name: 'payment_scheduled_timing',
            label: dicoLabelsService.getCollectionLabel('payment_scheduled_timing', 'long'),
            type: 'dropdown',
            options: [
              { value: 'immediate', label: 'Immédiat' },
              { value: 'automatic', label: 'Prélèvement automatique' },
              { value: 'date', label: 'Choisir une date' }
            ],
            value: purchase.payment_scheduled_timing,
            split: 2
            // afterChange: async (value: string, group) => {
            //   group.get('payment_scheduled_delay').hidden = value !== 'automatic';
            //   group.get('payment_expected_date').hidden = value !== 'date';
            // }
          },
          {
            name: 'delivery_expected_date',
            label: dicoLabelsService.getCollectionLabel('delivery_expected_date', 'long'),
            type: 'date',
            value: datesService.format(purchase.delivery_expected_date, 'YYYY-MM-DD'),
            split: 2
          },
          {
            name: 'payment_scheduled_at',
            label: dicoLabelsService.getCollectionLabel('payment_scheduled_at', 'long'),
            type: 'datetime-local',
            value: purchase.payment_scheduled_at,
            disabled: true,
            split: 2
          },
          {
            name: 'payment_scheduled_by',
            label: dicoLabelsService.getCollectionLabel('payment_scheduled_by', 'long'),
            type: 'text',
            value: dicoLabelsService.formatName(purchase.payment_scheduled_by),
            disabled: true,
            split: 2
          },
          {
            name: 'payment_transfer_date',
            label: dicoLabelsService.getCollectionLabel('payment_transfer_date', 'long'),
            type: 'datetime-local',
            value: purchase.payment_transfer_date,
            split: 2
          },
          {
            name: 'payment_method',
            label: dicoLabelsService.getCollectionLabel('payment_method'),
            type: 'dropdown',
            value: purchase.payment_method,
            split: 2,
            options: [
              { value: 'credit_card', label: 'Carte bancaire' },
              { value: 'paypal', label: 'Paypal' },
              { value: 'bank_transfer', label: 'Virement' }
            ],
            afterChange: async (value: string, group) => {
              group.get('payment_supplier_rib_id').hidden = value !== 'bank_transfer';
            }
          },
          {
            name: 'payment_supplier_rib_id',
            label: dicoLabelsService.getCollectionLabel('payment_supplier_rib_id', 'long'),
            type: 'text',
            // options: purchase.supplier?.ribs?.map(rib => ({
            //   value: rib.id,
            //   label: rib.bic
            // })),
            value: purchase.supplier.ribs.map(rib => rib.bic).join(', ')
          },
          {
            name: 'payment_effective_at',
            label: dicoLabelsService.getCollectionLabel('payment_effective_at', 'long'),
            type: 'datetime-local',
            value: purchase.payment_effective_at,
            disabled: true,
            split: 2
          },
          {
            name: 'payment_effective_by',
            label: dicoLabelsService.getCollectionLabel('payment_effective_by', 'long'),
            type: 'text',
            value: dicoLabelsService.formatName(purchase.payment_effective_by),
            disabled: true,
            split: 2
          },
          {
            name: 'shipping_carrier',
            label: dicoLabelsService.getCollectionLabel('shipping_carrier', 'long'),
            type: 'text',
            value: purchase.shipping_carrier,
            split: 2
          },
          {
            name: 'shipping_tracking_number',
            label: dicoLabelsService.getCollectionLabel('shipping_tracking_number', 'long'),
            type: 'text',
            value: purchase.shipping_tracking_number,
            split: 2
          },
          {
            name: 'shipping_cost',
            label: dicoLabelsService.getCollectionLabel('shipping_cost', 'long'),
            type: 'number',
            value: purchase.shipping_cost,
            step: '0.01',
            unit: dicoLabelsService.getUnit('money'),
            split: 2
          },
          {
            name: 'nb_expected_packaging',
            label: dicoLabelsService.getCollectionLabel('nb_expected_packaging'),
            type: 'number',
            value: purchase.nb_expected_packaging,
            split: 2
          },
          ...(purchase.items
            ? ([
                ...(purchase.item_type === 'spare'
                  ? [
                      {
                        name: 'spare_types',
                        label: dicoLabelsService.getCollectionLabel('spare_types', 'long'),
                        type: 'display',
                        value: dicoLabelsService.formatPurchaseItemShare(
                          purchase.items,
                          'spare_type',
                          settings?.spare_types
                        )
                      }
                    ]
                  : []),
                ...(purchase.item_type === 'consumable'
                  ? [
                      {
                        name: 'consumable_types',
                        label: dicoLabelsService.getCollectionLabel('consumable_types', 'long'),
                        type: 'display',
                        value: dicoLabelsService.formatPurchaseItemShare(
                          purchase.items,
                          'consumable_type',
                          settings?.consumable_types
                        )
                      }
                    ]
                  : [])
              ] as FormControlRaw<Purchase>[])
            : []),
          ...(statusIndex > 4
            ? ([
                {
                  name: 'invoice_ref',
                  label: dicoLabelsService.getCollectionLabel('invoice_ref', 'long'),
                  type: 'text',
                  value: purchase.invoice_ref,
                  required: true
                }
              ] as FormControlRaw<Purchase>[])
            : [])
        ]
      },
      ...(statusIndex > 2
        ? [
            {
              name: 'reception',
              label: 'Réception',
              controls: [
                {
                  name: 'last_reception_at',
                  label: dicoLabelsService.getCollectionLabel('last_reception_at', 'long'),
                  type: 'datetime-local',
                  value: purchase.last_reception_at,
                  disabled: true,
                  required: true
                },
                {
                  name: 'reception_status',
                  label: dicoLabelsService.getCollectionLabel('reception_status', 'short'),
                  type: 'display',
                  value: purchase.quantity_received === purchase.quantity ? 'Complète' : 'Partielle',
                  disabled: true,
                  split: 2
                },
                ...(purchase.quantity_received !== purchase.quantity
                  ? [
                      {
                        name: 'quantity_received',
                        label: dicoLabelsService.getCollectionLabel('quantity_received', 'short'),
                        type: 'number',
                        value: purchase.quantity_received,
                        required: true,
                        disabled: true,
                        split: 2
                      }
                    ]
                  : [])
              ] as FormControlRaw<Purchase>[]
            }
          ]
        : []),
      ...(statusIndex > 3 && purchase.item_type === 'device'
        ? [
            {
              name: 'control',
              label: 'Contrôle',
              controls: [
                {
                  name: 'control_started_at',
                  label: dicoLabelsService.getCollectionLabel('control_started_at', 'long'),
                  type: 'datetime-local',
                  value: purchase.control_started_at,
                  disabled: true,
                  required: true
                },
                {
                  name: 'control_started_by',
                  label: dicoLabelsService.getCollectionLabel('control_started_by', 'long'),
                  type: 'text',
                  value: dicoLabelsService.formatName(purchase.control_started_by),
                  disabled: true,
                  required: true
                }
              ] as FormControlRaw<Purchase>[]
            }
          ]
        : []),
      ...(purchase.purchase_status === 'rma' || purchase.rma_problem_type
        ? [
            {
              name: 'rma',
              label: 'RMA',
              controls: [
                {
                  name: 'rma_problem_type',
                  label: dicoLabelsService.getCollectionLabel('rma_problem_type'),
                  type: 'dropdown',
                  value: purchase.rma_problem_type,
                  required: true,
                  options: [
                    { value: 'partial_reception', label: 'Réception partielle' },
                    { value: 'damaged_package', label: 'Emballage abîmé' },
                    { value: 'damaged_product', label: 'Produits abîmés' },
                    { value: 'delivery_delay', label: 'Retard de livraison' },
                    { value: 'non_compliance', label: 'Non conformité' },
                    { value: 'not_received', label: 'Non reçu' }
                  ]
                },
                {
                  name: 'rma_problem_desc',
                  label: dicoLabelsService.getCollectionLabel('rma_problem_desc', 'long'),
                  type: 'text-multiline',
                  value: purchase.rma_problem_desc,
                  required: true
                },
                {
                  name: 'rma_solution',
                  label: dicoLabelsService.getCollectionLabel('rma_solution'),
                  type: 'dropdown',
                  value: purchase.rma_solution,
                  required: true,
                  options: [
                    { value: 'refund', label: 'Remboursement' },
                    { value: 'credit', label: 'Note de crédit' }
                  ]
                },
                {
                  name: 'rma_amount',
                  label: dicoLabelsService.getCollectionLabel('rma_amount', 'long'),
                  type: 'number',
                  value: purchase.rma_amount,
                  required: true,
                  split: 2
                },
                {
                  name: 'rma_currency',
                  label: dicoLabelsService.getCollectionLabel('rma_currency', 'long'),
                  type: 'dropdown',
                  value: purchase.rma_currency,
                  options: [
                    { value: 'eur', label: 'EUR' },
                    { value: 'gbp', label: 'GBP' },
                    { value: 'usd', label: 'USD' }
                  ],
                  required: true,
                  split: 2
                },
                {
                  name: 'rma_amount_refunded',
                  label: dicoLabelsService.getCollectionLabel('rma_amount_refunded', 'long'),
                  type: 'number',
                  value: purchase.rma_amount_refunded,
                  required: true
                },
                {
                  name: 'rma_status',
                  label: dicoLabelsService.getCollectionLabel('rma_status', 'long'),
                  type: 'dropdown',
                  value: purchase.rma_status,
                  options: [
                    { value: 'at_workshop', label: 'Présent en atelier' },
                    { value: 'at_supplier', label: 'Expédié au fournisseur' },
                    { value: 'back_refunded', label: 'Revenu en atelier / Remboursé par le fournisseur' }
                  ],
                  required: true
                }
              ] as FormControlRaw<Purchase>[]
            }
          ]
        : []),
      ...(purchase.purchase_status === 'rma' || purchase.rma_problem_type
        ? [
            {
              name: 'validation',
              label: 'Validation',
              controls: [
                {
                  name: 'validated_at',
                  label: dicoLabelsService.getCollectionLabel('validated_at', 'long'),
                  type: 'datetime-local',
                  value: purchase.validated_at,
                  disabled: true,
                  required: true
                },
                {
                  name: 'validated_by',
                  label: dicoLabelsService.getCollectionLabel('validated_by', 'long'),
                  type: 'text',
                  value: dicoLabelsService.formatName(purchase.validated_by),
                  disabled: true,
                  required: true
                }
              ] as FormControlRaw<Purchase>[]
            }
          ]
        : []),
      ...(purchase.paused_at || purchase.depaused_at
        ? [
            {
              name: 'pause',
              label: 'Mise en pause',
              controls: [
                ...(purchase.paused_at
                  ? [
                      {
                        name: 'paused_at',
                        label: dicoLabelsService.getCollectionLabel('paused_at', 'long'),
                        type: 'datetime-local',
                        value: purchase.paused_at,
                        disabled: true,
                        hidden: !purchase.paused_at
                      }
                    ]
                  : []),
                ...(purchase.paused_by
                  ? [
                      {
                        name: 'paused_by',
                        label: dicoLabelsService.getCollectionLabel('paused_by', 'long'),
                        type: 'text',
                        value: dicoLabelsService.formatName(purchase.paused_by),
                        disabled: true,
                        hidden: !purchase.paused_by
                      }
                    ]
                  : []),
                ...(purchase.depaused_at
                  ? [
                      {
                        name: 'depaused_at',
                        label: dicoLabelsService.getCollectionLabel('depaused_at', 'long'),
                        type: 'datetime-local',
                        value: purchase.depaused_at,
                        disabled: true,
                        hidden: !purchase.depaused_at
                      }
                    ]
                  : []),
                ...(purchase.depaused_by
                  ? [
                      {
                        name: 'depaused_by',
                        label: dicoLabelsService.getCollectionLabel('depaused_by', 'long'),
                        type: 'text',
                        value: dicoLabelsService.formatName(purchase.depaused_by),
                        disabled: true,
                        hidden: !purchase.depaused_by
                      }
                    ]
                  : [])
              ] as FormControlRaw<Purchase>[]
            }
          ]
        : []),
      {
        name: 'files',
        controls: [
          {
            name: 'initial_supplier_file',
            label: dicoLabelsService.getCollectionLabel('file', 'long'),
            type: 'file',
            value: purchase.initial_supplier_file,
            disabled: !purchase.id
          },
          {
            name: 'analysis_supplier_file',
            label: dicoLabelsService.getCollectionLabel('analysis_supplier_file', 'long'),
            type: 'file',
            value: purchase.analysis_supplier_file,
            disabled: !purchase.id
          },
          {
            name: 'payment_confirmation_file',
            label: dicoLabelsService.getCollectionLabel('payment_confirmation_file', 'long'),
            type: 'file',
            value: purchase.payment_confirmation_file
          },
          {
            name: 'packaging_photo_file',
            label: dicoLabelsService.getCollectionLabel('packaging_photo_file', 'long'),
            type: 'file',
            value: purchase.packaging_photo_file
          },
          {
            name: 'rma_file',
            label: dicoLabelsService.getCollectionLabel('rma_file', 'long'),
            type: 'file',
            value: purchase.rma_file
          }
        ]
      }
    ]);

    return form;
  }

  private getSupplierForm(supplier: Supplier, globalSettings: GlobalSettings): Form<Supplier> {
    if (!globalSettings) return;

    return new Form<Supplier>([
      {
        name: 'supplier',
        controls: [
          {
            name: 'display_name',
            label: dicoLabelsService.getCollectionLabel('display_name', 'long'),
            type: 'text',
            value: supplier.display_name,
            required: true,
            split: 1
          },
          {
            name: 'category',
            label: dicoLabelsService.getCollectionLabel('category', 'long'),
            type: 'dropdown',
            options: [
              { value: 'device', label: 'Ordinateurs' },
              { value: 'spare', label: 'Pièces détachées' },
              { value: 'consumable', label: 'Emballages' },
              { value: 'other', label: 'Autre' }
            ],
            value: supplier.category,
            required: true,
            afterChange: async (value: string, group) => {
              group.get('category').split = value === 'spare' ? 2 : 1;
              group.get('sub_categories').hidden = value !== 'spare';
            },
            split: supplier.category === 'spare' ? 2 : 1
          },
          {
            name: 'sub_categories',
            label: dicoLabelsService.getCollectionLabel('sub_categories', 'long'),
            type: 'multi-dropdown',
            options: [
              { value: 'accessory', label: 'Accessoire' },
              { value: 'battery', label: 'Batterie' },
              { value: 'charger', label: 'Chargeur' },
              { value: 'hard_drive', label: 'Disque dur' },
              { value: 'ram', label: 'RAM' }
            ],
            value: supplier.sub_categories,
            required: true,
            split: 2
          },
          {
            name: 'country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'dropdown',
            value: supplier.country,
            options: globalSettings.countries.map(country => ({
              value: country[0],
              label: country[1]
            })),
            required: true,
            split: 3
          },
          {
            name: 'currency',
            label: dicoLabelsService.getCollectionLabel('currency', 'long'),
            type: 'dropdown',
            value: supplier.currency,
            options: [
              { value: 'eur', label: 'EUR' },
              { value: 'gbp', label: 'GBP' },
              { value: 'usd', label: 'USD' }
            ],
            required: true,
            split: 3
          },
          {
            name: 'tax_scheme',
            label: dicoLabelsService.getCollectionLabel('tax_scheme', 'long'),
            value: supplier.tax_scheme,
            type: 'dropdown',
            options: globalSettings.schemes.map((scheme, index) => ({
              value: scheme[0],
              label: scheme[1],
              indexWeight: index
            })),
            required: true,
            split: 3
          },
          {
            name: 'warranty',
            label: dicoLabelsService.getCollectionLabel('warranty', 'long'),
            type: 'number',
            value: supplier.warranty,
            unit: dicoLabelsService.getUnit('day'),
            required: true,
            split: 2
          },
          {
            name: 'payment_timing',
            label: dicoLabelsService.getCollectionLabel('payment_timing', 'long'),
            type: 'dropdown',
            value: supplier.payment_timing,
            options: [
              { value: 'before_reception', label: 'Avant expédition' },
              { value: 'at_reception', label: 'A la livraison' },
              { value: 'delay', label: 'A X jours' }
            ],
            required: true,
            afterChange: async (value: string, group) => {
              group.get('payment_delay').hidden = value !== 'delay';
            },
            split: 2
          },
          {
            name: 'payment_delay',
            label: dicoLabelsService.getCollectionLabel('payment_delay', 'long'),
            type: 'number',
            value: supplier.payment_delay,
            required: true,
            split: 2
          },
          {
            name: 'comment',
            label: dicoLabelsService.getCollectionLabel('comment', 'long'),
            type: 'text-multiline',
            value: supplier.comment,
            split: 1
          }
        ]
      }
    ]);
  }

  private getCageCarrierLabelDisplayForm(
    cageCarrierLabel: CageCarrierLabel,
    globalSettings: GlobalSettings
  ): Form<CageCarrierLabel> {
    if (!globalSettings) return;

    return new Form<CageCarrierLabel>([
      {
        name: 'row',
        controls: [
          {
            name: 'serial_number',
            type: 'text',
            value: cageCarrierLabel.item_code,
            required: true,
            split: 2
          },
          {
            name: 'tracking_number',
            type: 'text',
            value: cageCarrierLabel.order_id,
            required: true,
            split: 2
          }
        ]
      }
    ]);
  }

  private getSupplierDisplayForm(supplier: Supplier, globalSettings: GlobalSettings): Form<Supplier> {
    if (!globalSettings) return;

    return new Form<Supplier>([
      {
        name: 'name',
        controls: [
          {
            name: 'display_name',
            label: dicoLabelsService.getCollectionLabel('display_name', 'long'),
            type: 'text',
            value: supplier.display_name,
            required: true,
            split: 1
          }
        ]
      },
      {
        name: 'category',
        controls: [
          {
            name: 'category',
            label: dicoLabelsService.getCollectionLabel('category', 'long'),
            type: 'dropdown',
            options: [
              { value: 'device', label: 'Ordinateurs' },
              { value: 'spare', label: 'Pièces détachées' },
              { value: 'consumable', label: 'Emballages' },
              { value: 'other', label: 'Autre' }
            ],
            value: supplier.category,
            required: true,
            afterChange: async (value: string, group) => {
              group.get('category').split = value === 'spare' ? 2 : 1;
              group.get('sub_categories').hidden = value !== 'spare';
            },
            split: supplier.category === 'spare' ? 2 : 1
          },
          {
            name: 'sub_categories',
            label: dicoLabelsService.getCollectionLabel('sub_categories', 'long'),
            type: 'multi-dropdown',
            options: [
              { value: 'accessory', label: 'Accessoire' },
              { value: 'battery', label: 'Batterie' },
              { value: 'charger', label: 'Chargeur' },
              { value: 'hard_drive', label: 'Disque dur' },
              { value: 'ram', label: 'RAM' }
            ],
            value: supplier.sub_categories,
            required: true,
            split: 2
          }
        ]
      },
      {
        name: 'supplier',
        controls: [
          {
            name: 'country',
            label: dicoLabelsService.getCollectionLabel('country', 'long'),
            type: 'text',
            value: supplier.country,
            required: true,
            split: 3
          },
          {
            name: 'currency',
            label: dicoLabelsService.getCollectionLabel('currency', 'long'),
            type: 'dropdown',
            value: supplier.currency,
            options: [
              { value: 'eur', label: 'EUR' },
              { value: 'gbp', label: 'GBP' },
              { value: 'usd', label: 'USD' }
            ],
            required: true,
            split: 3
          },
          {
            name: 'tax_scheme',
            label: dicoLabelsService.getCollectionLabel('tax_scheme', 'long'),
            value: supplier.tax_scheme,
            type: 'dropdown',
            options: globalSettings.schemes.map((scheme, index) => ({
              value: scheme[0],
              label: scheme[1],
              indexWeight: index
            })),
            required: true,
            split: 3
          },
          {
            name: 'warranty',
            label: dicoLabelsService.getCollectionLabel('warranty', 'long'),
            type: 'number',
            value: supplier.warranty,
            unit: dicoLabelsService.getUnit('day'),
            required: true,
            split: 3
          },
          {
            name: 'payment_timing',
            label: dicoLabelsService.getCollectionLabel('payment_timing', 'long'),
            type: 'dropdown',
            value: supplier.payment_timing,
            options: [
              { value: 'before_reception', label: 'Avant la livraison' },
              { value: 'at_reception', label: 'A la livraison' },
              { value: 'delay', label: 'A X jours' }
            ],
            required: true,
            afterChange: async (value: string, group) => {
              group.get('payment_delay').hidden = value !== 'delay';
            },
            split: 3
          },
          {
            name: 'payment_delay',
            label: dicoLabelsService.getCollectionLabel('payment_delay', 'long'),
            type: 'number',
            value: supplier.payment_delay,
            required: true,
            split: 3
          },
          {
            name: 'credit_amount',
            label: dicoLabelsService.getCollectionLabel('credit_amount', 'long'),
            type: 'text',
            value: `${supplier.credit_amount} ${supplier.currency?.toUpperCase() || ''}`,
            required: true
          },
          {
            name: 'comment',
            label: dicoLabelsService.getCollectionLabel('comment', 'long'),
            type: 'text-multiline',
            value: supplier.comment
          }
        ]
      }
    ]);
  }

  private getPickingCreationForm(picking: Picking): Form<Picking> {
    return new Form<Picking>([
      {
        name: 'picking-creation',
        label: '<i class="fas fa-cart"></i> Session(s) de picking',
        controls: [
          {
            name: 'computer_type',
            label: dicoLabelsService.getCollectionLabel('computer_type'),
            type: 'dropdown',
            options: [
              { value: 'laptop', label: 'Ordinateurs portables' },
              { value: 'desktop', label: 'Ordinateurs fixes' }
            ],
            value: picking.computer_type,
            required: true
          },
          {
            name: 'cart_number',
            label: 'Nb de chariot(s)',
            type: 'dropdown',
            options: [
              { value: 1, label: '1' },
              { value: 2, label: '2' }
            ],
            value: picking.cart_number,
            required: true,
            split: 2
          },
          {
            name: 'computer_quantity',
            label: "Nb d'ordinateurs par chariot",
            type: 'dropdown',
            options: [
              { value: 10, label: '10' },
              { value: 20, label: '20' },
              { value: 30, label: '30' },
              { value: 40, label: '40' }
            ],
            value: picking.computer_quantity,
            required: true,
            split: 2
          }
        ]
      }
    ]);
  }

  private getPickingForm(picking: Picking): Form<Picking> {
    return new Form<Picking>([
      {
        name: 'picking-creation',
        label: '<i class="fas fa-cart"></i> Session(s) de picking',
        controls: [
          {
            name: 'computer_type',
            label: dicoLabelsService.getCollectionLabel('computer_type'),
            type: 'dropdown',
            options: [
              { value: 'laptop', label: 'Ordinateurs portables' },
              { value: 'desktop', label: 'Ordinateurs fixes' }
            ],
            value: picking.computer_type,
            disabled: true
          },
          {
            name: 'cart_number',
            label: 'Nb de chariot(s)',
            type: 'text',
            value: picking.cart_number,
            disabled: true,
            required: true,
            split: 2
          },
          {
            name: 'computer_quantity',
            label: "Nb d'ordinateurs par chariot",
            type: 'text',
            value: picking.computer_quantity,
            disabled: true,
            required: true,
            split: 2
          },
          {
            name: 'picked_quantity',
            label: "Nb d'ordinateurs sortis",
            type: 'text',
            value: picking.picked_quantity,
            disabled: true,
            required: true,
            split: 2
          }
        ]
      }
    ]);
  }

  private getDeviceModelControls(
    device: Device,
    type: DeviceFormType,
    globalSettings: GlobalSettings
  ): FormControlRaw<Device>[] {
    let init = true;

    //// A supprimer a voir avec thib
    // c'est pour que le control puisse continuer même si des liste sont pas mis a jour au achats (ref fournisseur, SN)
    let skuCreateControl = null;
    if (globalSettings.models.filter(model => model[1] == device.model_namestring)[0]) {
      skuCreateControl = [
        globalSettings.models.filter(model => model[1] == device.model_namestring)[0][0] || 'XX',
        device.size || 'XX',
        device.year || 'XXX',
        device.proc || 'XXX',
        Math.round(device.freq * 100) || 'XXX',
        'XX',
        'XXX',
        device.hdtype.toUpperCase() || 'XXX',
        device.hdsize.toString().padStart(4, '0') || 'XXXX',
        'R',
        'XXXXXXXXX'
      ].join('');
    }
    //// A supprimer

    let controls: FormControlRaw<Device>[] = [
      {
        name: 'message',
        type: 'message-insert',
        content: '',
        theme: '',
        hidden: true
      },
      {
        name: 'serial_no',
        label: dicoLabelsService.getCollectionLabel('serial_no', 'long'),
        type: 'text',
        value: device.serial_no,
        disabled:
          type === 'control-relocate' ||
          (type === 'control' && device.from_ajout_tool !== false && device?.control === undefined) ||
          type === 'device',
        required: true,
        afterChange: async (value: string, group) => {
          try {
            await devicesService.get(value);
            if (group.parent.get('message.message').hidden) group.parent.get('message.message').hidden = false;
            group.parent.get('message.message').theme = 'warning';
            group.parent.get('message.message').content =
              'Cet ordinateur a déjà été contrôlé le &nbsp;' +
              '<span class="emphasis">' +
              datesService.format(get(device, 'control.created_at')) +
              '</span>' +
              '</div>';
          } catch (error) {
            group.parent.get('message.message').hidden = true;
          }
          const messageSupplier = group.parent.get('supplier.message');
          try {
            const result = await purchasesService.checkDevicePurchase('', value);
            const { status, message } = result;
            group.get('message').hidden = true;
            group.get('message').theme = status;
            group.get('message').content = message;
            group.get('message').hidden = false;
            if (status === 'success') {
              group.parent.get('supplier.check_ref_supplier').hidden = true;
              group.parent.get('supplier.supplier_id').hidden = true;
              group.parent.get('supplier.reference_internal').hidden = true;
              group.parent.get('supplier.purchase_price').hidden = true;
              messageSupplier.hidden = true;
            } else {
              group.parent.get('supplier.check_ref_supplier').hidden = false;
              if (init && device.device) {
                if (!device.supplier_reference) {
                  group.parent.get('supplier.check_ref_supplier').hidden = true;
                  group.parent.get('supplier.check_ref_supplier').value = true;
                }
              }
              messageSupplier.theme = 'warning';
              messageSupplier.content = message;
              messageSupplier.hidden = false;
            }
          } catch (error) {
            group.get('message').theme = 'error';
            group.get('message').content = 'Une erreur est survenue lors de la vérification du numéro de série.';
            group.get('message').hidden = false;
          }
          init = false;
        }
      },
      {
        name: 'sku_okamac',
        label: dicoLabelsService.getCollectionLabel('sku_okamac', 'long'),
        type: 'text',
        value: device.sku || device.supplier_sku || skuCreateControl,
        disabled: true
      },
      {
        name: 'model_identifier',
        label: dicoLabelsService.getCollectionLabel('model_identifier', 'long'),
        type: 'text',
        value: device.model_identifier,
        disabled:
          type === 'control-relocate' || (type === 'control' && device.from_ajout_tool !== false) || type === 'device',
        required: true,
        split: 2
      },
      {
        name: 'model_namestring',
        label: dicoLabelsService.getCollectionLabel('model_namestring', 'long'),
        type: 'dropdown',
        value: device.model_namestring,
        options: globalSettings.models.map(model => ({
          value: model[1],
          label: model[1]
        })),
        disabled:
          type === 'control-relocate' || (type === 'control' && device.from_ajout_tool !== false) || type === 'device',
        required: true,
        split: 2
      },
      {
        name: 'year',
        label: dicoLabelsService.getCollectionLabel('year', 'long'),
        type: 'dropdown',
        options: globalSettings.years.map((year, index) => ({
          value: year[0],
          label: year[1].display_name,
          indexWeight: index
        })),
        value: device.year,
        required: true,
        split: 2
      }
    ];
    if (type !== 'control' && type !== 'device-creation') {
      controls = [
        ...controls,
        {
          name: 'size',
          label: dicoLabelsService.getCollectionLabel('size', 'long'),
          type: 'dropdown',
          options: globalSettings.screens.map(size => ({
            value: parseFloat(size[1]),
            label: size[1] + dicoLabelsService.getUnit('screen_size')
          })),
          value: device.size,
          disabled: true,
          split: 2
        }
      ];
    }

    controls = [
      ...controls,
      {
        name: 'keyboard',
        label: dicoLabelsService.getCollectionLabel('keyboard', 'long'),
        type: 'dropdown',
        options: globalSettings.keyboards.map((keyboard, index) => ({
          value: keyboard[0],
          label: keyboard[1],
          indexWeight: index
        })),
        value: device.keyboard,
        required: true,
        split: 2
      },
      {
        name: 'color',
        label: dicoLabelsService.getCollectionLabel('color', 'long'),
        type: 'dropdown',
        options: globalSettings.colors.map((color, index) => ({
          value: color[0],
          label: dicoLabelsService.decodeColor(color[0], color[1]),
          indexWeight: index
        })),
        value: device.color,
        required: true,
        split: 2
      },
      {
        name: 'keyboard_us',
        label: dicoLabelsService.getCollectionLabel('keyboard_us', 'long'),
        type: 'toggle',
        value: device.keyboard_us,
        split: 2
      }
    ];
    // if (type === 'device') {
    //   controls = [
    //     ...controls,
    //     {
    //       name: 'swap_screen_todo',
    //       label: dicoLabelsService.getCollectionLabel('swap_screen_todo'),
    //       type: 'toggle',
    //       value: device.swap_screen_todo,
    //       split: 2
    //     }
    //   ];
    // }

    return controls;
  }

  private getDevicePerfsControls(device: Device, globalSettings: GlobalSettings): FormControlRaw<Device>[] {
    if (!globalSettings) return [];
    return [
      {
        name: 'proc',
        label: dicoLabelsService.getCollectionLabel('proc', 'long'),
        type: 'dropdown',
        options: globalSettings.procs.map((proc, index) => ({
          value: proc[0],
          label: proc[1],
          indexWeight: index
        })),
        value: device.proc,
        required: true,
        split: 2
      },
      {
        name: 'freq',
        label: dicoLabelsService.getCollectionLabel('freq', 'long'),
        type: 'dropdown',
        options: globalSettings.hdd_freqs.map(freq => ({
          value: freq,
          label: `${freq} ${dicoLabelsService.getUnit('frequency')}`
        })),
        value: device.freq,
        required: true,
        split: 2
      },
      {
        name: 'number_cores',
        label: dicoLabelsService.getCollectionLabel('number_cores', 'long'),
        type: 'dropdown',
        options: globalSettings.cores.map(core => ({
          value: core,
          label: '' + core
        })),
        value: device.number_cores,
        required: true,
        split: 2
      },
      {
        name: 'graphic_card',
        label: dicoLabelsService.getCollectionLabel('graphic_card', 'long'),
        type: 'dropdown',
        options: globalSettings.graphic_cards.map((code, index) => ({
          value: code[0],
          label: code[1],
          indexWeight: index
        })),
        value: device.graphic_card,
        required: true,
        split: 2
      },
      {
        name: 'hdtype',
        label: dicoLabelsService.getCollectionLabel('hdtype', 'long'),
        type: 'dropdown',
        options: globalSettings.drives.map((drive, index) => ({
          value: drive[0],
          label: drive[1],
          indexWeight: index
        })),
        value: device.hdtype,
        required: true,
        split: 2
      },
      {
        name: 'hdsize',
        label: dicoLabelsService.getCollectionLabel('hdsize', 'long'),
        type: 'dropdown',
        options: globalSettings.hdd_sizes.map(size => ({
          value: size,
          label: `${size} ${dicoLabelsService.getUnit('capacity')}`
        })),
        value: device.hdsize,
        required: true,
        split: 2
      },
      {
        name: 'ram',
        label: dicoLabelsService.getCollectionLabel('ram', 'long'),
        type: 'dropdown',
        options: globalSettings.rams.map(ram => ({
          value: ram,
          label: `${ram} ${dicoLabelsService.getUnit('capacity')}`
        })),
        unit: dicoLabelsService.getUnit('capacity'),
        value: device.ram,
        required: true,
        split: 2
      }
    ];
  }

  private getDeviceBatteryControls(device: Device, type: DeviceFormType): FormControlRaw<Device>[] {
    const hasBattery = !/(i\s?mac)|(mac\s?mini)|(mac\s?pro)/i.test(device.model_namestring);

    const controls: FormControlRaw<Device>[] = [
      {
        name: 'battery_ratio',
        label: dicoLabelsService.getCollectionLabel('battery_ratio', 'long'),
        type: 'number',
        unit: dicoLabelsService.getUnit('percentage'),
        value: device.battery_ratio,
        disabled: true,
        split: type === 'device' ? 3 : 2
      },
      {
        name: 'current_battery_capacity',
        label: dicoLabelsService.getCollectionLabel('current_battery_capacity', 'long'),
        type: 'number',
        unit: dicoLabelsService.getUnit('intensity'),
        value: device.current_battery_capacity,
        required: hasBattery,
        split: type === 'device' ? 3 : 2
      },
      {
        name: 'cycles',
        label: dicoLabelsService.getCollectionLabel('cycles', 'long'),
        type: 'number',
        value: device.cycles,
        required: hasBattery,
        split: type === 'device' ? 3 : 2
      }
    ];

    return type === 'device' ? controls : controls.slice(-2);
  }

  // private getDeviceComponentsControls(
  //   faulty: FaultyComponent[],
  //   globalSettings: GlobalSettings
  // ): FormControlRaw<{ faulty: FaultyComponent[] }>[] {
  //   return [
  //     {
  //       name: 'faulty',
  //       label: dicoLabelsService.getCollectionLabel('faulty', 'long'),
  //       type: 'switch-list',
  //       value: faulty,
  //       options: globalSettings.components.map((component, index) => ({
  //         value: component[0],
  //         label: component[1],
  //         indexWeight: index
  //       }))
  //     }
  //   ];
  // }
  private getDeviceSupplierControls(
    device: Device,
    type: DeviceFormType,
    globalSettings: GlobalSettings
  ): FormControlRaw<Device>[] {
    if (!globalSettings) return [];
    if (type == 'device') {
      return [
        {
          name: 'purchase_price',
          label: dicoLabelsService.getCollectionLabel('purchase_price', 'long'),
          type: 'number',
          value: device.purchase_price,
          unit: dicoLabelsService.getUnit('money'),
          required: true,
          step: '0.01',
          split: 2
        },
        {
          name: 'purchase_date',
          label: dicoLabelsService.getCollectionLabel('purchase_date', 'long'),
          type: 'datetime-local',
          value: device.purchase_date,
          required: true,
          split: 2
        },
        {
          name: 'supplier_id',
          label: dicoLabelsService.getCollectionLabel('supplier_id', 'long'),
          type: 'dropdown',
          value: device.supplier_id,
          options: globalSettings.suppliers.map(supplier => ({
            value: supplier[0],
            label: supplier[1].display_name,
            indexWeight: supplier[1].index_weight
          })),
          required: true,
          split: 2
        },
        {
          name: 'supplier_reference',
          label: dicoLabelsService.getCollectionLabel('supplier_reference', 'long'),
          type: 'text',
          value: device.supplier_reference,
          split: 2
        },
        {
          name: 'tax_scheme',
          label: dicoLabelsService.getCollectionLabel('tax_scheme', 'long'),
          type: 'dropdown',
          options: globalSettings.schemes.map((scheme, index) => ({
            value: scheme[0],
            label: scheme[1],
            indexWeight: index
          })),
          value: device.tax_scheme,
          required: true,
          split: 2
        },
        {
          name: 'warranty',
          label: dicoLabelsService.getCollectionLabel('warranty', 'long'),
          type: 'number',
          value: device.warranty,
          unit: dicoLabelsService.getUnit('day'),
          required: true,
          split: 2
        }
      ];
    }

    let check_ref_supplier = true;
    let initialize = true;
    if (device.device) {
      if (!device.supplier_reference) {
        check_ref_supplier = false;
      }
    }
    return [
      {
        name: 'message',
        type: 'message-insert',
        content: '',
        theme: '',
        hidden: true
      },
      {
        name: 'check_ref_supplier',
        label: dicoLabelsService.getCollectionLabel('check_ref_supplier'),
        type: 'toggle',
        value: check_ref_supplier,
        afterChange: async (value: boolean, group) => {
          const messageControl = group.get('message');
          const messageModelControl = group.parent.get('model.message');
          const serial_number = group.parent.get('model.serial_no').value as string;
          const referenceInternalControl = group.get('reference_internal');
          const supplierReference = group.get('supplier_reference');
          if (value) {
            if (!initialize) supplierReference.value = null;
          } else {
            messageControl.content = 'Sélectionner une référence interne';
            messageControl.theme = 'warning';
            if (messageModelControl.content) messageModelControl.hidden = false;
            if (messageControl.content) {
              messageControl.hidden = true;
              messageControl.hidden = false;
            }
            if (!initialize) referenceInternalControl.value = null;
          }
          const result = await purchasesService.checkDevicePurchase('ref', serial_number);
          supplierReference.hidden = !value;
          referenceInternalControl.hidden = value;
          const referencesInternes: string[] = result.references_internes || [];
          referencesInternes.unshift('Rachat');
          referenceInternalControl.options = referencesInternes.map(reference => ({
            value: reference,
            label: reference
          }));
        },
        split: 2
      },
      {
        name: 'supplier_reference',
        label: dicoLabelsService.getCollectionLabel('supplier_reference', 'long'),
        type: 'text',
        value: device.supplier_reference,
        split: 2,
        afterChange: async (value: string, group) => {
          const messageControl = group.get('message');
          const messageModelControl = group.parent.get('model.message');
          const serial_number = group.parent.get('model.serial_no').value as string;
          const referenceInternalControl = group.get('reference_internal');
          const result = await purchasesService.checkDevicePurchase(value, serial_number);
          if (!initialize) referenceInternalControl.value = null;
          referenceInternalControl.hidden = !value;
          if ((messageModelControl.theme == 'success' && value) || messageModelControl.theme == 'warning') {
            messageControl.hidden = true;
            messageControl.content = result.message;
            messageControl.theme = result.status;
            messageControl.hidden = false;
          }
          if (value) {
            if (messageModelControl.content && messageModelControl.theme != 'success') {
              messageModelControl.hidden = result.status == 'success';
            }
            if (result.status != 'success') {
              const referencesInternes: string[] = result.references_internes || [];
              referencesInternes.unshift('Rachat');
              referenceInternalControl.options = referencesInternes.map(reference => ({
                value: reference,
                label: reference
              }));
            } else {
              referenceInternalControl.hidden = true;
            }
          } else {
            if (messageModelControl.theme == 'success') {
              messageControl.hidden = true;
            } else {
              if (messageModelControl.content) messageModelControl.hidden = false;
            }
          }
          if (initialize && result.status == 'success') initialize = false;
        }
      },
      {
        name: 'reference_internal',
        type: 'option-list',
        label: 'Associer manuellement à une liste',
        value: device.reference_internal,
        options: [],
        hidden: true,
        afterChange: async (selectedOption: string, group) => {
          if (selectedOption) {
            group.get('message').hidden = true;
            group.parent.get('model.message').hidden = true;
          }
          if (selectedOption == 'Rachat') {
            group.get('supplier_id').hidden = false;
            group.get('supplier_id').required = true;
            group.get('purchase_price').hidden = false;
            group.get('purchase_price').required = true;
          } else {
            group.get('supplier_id').hidden = true;
            if (!initialize) group.get('supplier_id').value = null;
            group.get('supplier_id').required = false;
            group.get('purchase_price').hidden = true;
            if (!initialize) group.get('purchase_price').value = null;
            group.get('purchase_price').required = false;
          }
          initialize = false;
        }
      },
      {
        name: 'supplier_id',
        type: 'dropdown',
        value: device.supplier_id,
        options: globalSettings.suppliers
          .filter(s => s[1].buyback)
          .map(supplier => ({
            value: supplier[0],
            label: supplier[1].display_name,
            indexWeight: supplier[1].index_weight
          })),
        label: dicoLabelsService.getCollectionLabel('marketplace_buyback'),
        hidden: true
      },
      {
        name: 'purchase_price',
        type: 'number',
        label: dicoLabelsService.getCollectionLabel('amount'),
        value: device.purchase_price,
        hidden: true
      }
    ];
  }

  private getDeviceGradingsControls(device: Device, type: DeviceFormType): FormControlRaw<Device>[] {
    let gradeForm: FormControlRaw<Device>[] = [];
    if (type == 'device' && device.grading_id != null) {
      gradeForm = gradeForm.concat([
        // {
        //   name: 'is_eligible_painting',
        //   label: dicoLabelsService.getCollectionLabel('is_eligible_painting'),
        //   type: 'toggle',
        //   value: device.is_eligible_painting,
        //   split: 2
        // },
        {
          name: 'filters_eligibility',
          label: dicoLabelsService.getCollectionLabel('eligible_polarizer'),
          type: 'toggle',
          value: device.grading.filters_eligibility > 0 ? true : false,
          split: 2,
          disabled: true
        },
        {
          name: 'seals_eligibility',
          label: dicoLabelsService.getCollectionLabel('eligible_seals'),
          type: 'toggle',
          value: device.grading.seals_eligibility > 0 ? true : false,
          split: 2,
          disabled: true
        }
      ]);
    }
    if (type == 'control' || type == 'control-relocate') {
      const grading = device.grading;
      [
        'blue_spots',
        'yellow_spots',
        'white_spots',
        'light_leakage',
        'pink_screen',
        'liquid_spots',
        'dust_screen'
      ].forEach(element => {
        const value = grading ? grading[element] : false;
        element != 'yellow_spots'
          ? element != 'dust_screen'
            ? gradeForm.push({
                name: element,
                label: dicoLabelsService.getCollectionLabel(element),
                type: 'options-list-unique',
                value: value || 0,
                options: [
                  { value: false, label: 'light_' + element.split('_')[0] },
                  { value: false, label: 'deep_' + element.split('_')[0] }
                ],
                split: 2
              })
            : gradeForm.push({
                name: element,
                label: dicoLabelsService.getCollectionLabel(element),
                type: 'options-list-unique',
                value: value || 0,
                options: [
                  { value: false, label: 'light_' + element.split('_')[0] },
                  { value: false, label: 'middle_' + element.split('_')[0] },
                  { value: false, label: 'deep_' + element.split('_')[0] }
                ],
                split: 2
              })
          : gradeForm.push({
              name: element,
              label: dicoLabelsService.getCollectionLabel(element),
              type: 'toggle',
              value: value,
              split: 2
            });
      });
    }
    return gradeForm;
  }

  private getFilterOptions(name: string, values: any[], globalSettings: GlobalSettings): FormControlOption[] {
    let unit = '';
    switch (name) {
      case 'freq':
        unit = dicoLabelsService.getUnit('frequency');
        break;
      case 'hdsize':
      case 'ram':
        unit = dicoLabelsService.getUnit('capacity');
        break;
    }
    if (name === 'order_line_type') return this.getSortedOptions(globalSettings?.order_line_types, values);
    if (name === 'return_type') return this.getSortedOptions(globalSettings?.return_types, values);
    if (name === 'year' || name === 'ordered_year') return this.getSortedOptions(globalSettings?.years, values);
    if (name === 'color') return this.getSortedOptions(globalSettings?.colors, values);
    if (name === 'model') return this.getSortedOptions(globalSettings?.models, values);
    if (name === 'graphic_card') return this.getSortedOptions(globalSettings?.graphic_cards, values);
    if (name === 'freq') return this.getSortedOptions(globalSettings?.hdd_freqs, values, unit);
    if (name === 'proc') return this.getSortedOptions(globalSettings?.procs, values);
    if (name === 'ram') return this.getSortedOptions(globalSettings?.rams, values, unit);
    if (name === 'hdsize') return this.getSortedOptions(globalSettings?.hdd_sizes, values, unit);
    if (name === 'grade')
      return this.getSortedOptions(
        globalSettings?.grades.filter(grade => grade[0] !== 'A1'),
        values
      );
    if (name === 'keyboard') return this.getSortedOptions(globalSettings?.keyboards, values);
    if (name === 'stock_status_id') return this.getSortedOptions(globalSettings?.stocks_status, values);
    if (name === 'tax_scheme') return this.getSortedOptions(globalSettings?.schemes, values);
    if (name === 'buffer_alert') return this.getSortedOptions(globalSettings?.buffer_alerts, values);
    if (name === 'supplier') return this.getSortedOptions(globalSettings?.suppliers, values);
    if (name === 'spares_mix' || name === 'sub_category')
      return this.getSortedOptions(globalSettings?.spare_types, values);
    if (name === 'consumables_mix') return this.getSortedOptions(globalSettings?.consumable_types, values);
    if (name === 'devices_mix') return this.getSortedOptions(globalSettings?.models, values);
    if (name === 'country') return this.getSortedOptions(globalSettings?.countries, values);
    if (name === 'rma_problem_type') return this.getSortedOptions(globalSettings?.rma_problem_types, values);
    if (name === 'computer_type') return this.getSortedOptions(globalSettings?.computer_types, values);
    if (name === 'declared_faulty') return this.getSortedOptions(globalSettings?.components, values);
    if (name === 'state') return this.getSortedOptions(globalSettings?.states, values);
    if (name === 'process_handled_by') {
      return values.concat([{ value: 'all', label: 'Tous' }]);
    }

    if (name === 'ordered_size') {
      return (globalSettings?.screens as any)
        .map(setting => {
          return { value: setting[0], label: setting[1] + '"' };
        })
        .concat([{ value: 'all', label: 'Tous' }]);
    }

    return values
      .map(opt => {
        if (opt === null || opt === undefined) return { value: opt, label: 'Vide' };
        else if (opt === false) return { value: opt, label: 'Non' };
        else if (opt === true) return { value: opt, label: 'Oui' };
        else if (name === 'size') return { value: opt, label: opt + '"' };
        else return { value: opt, label: opt + (unit ? ` ${unit}` : '') };
      })
      .concat([{ value: 'all', label: 'Tous' }]);
  }

  private getSortedOptions(
    settings: GlobalSettings[keyof GlobalSettings],
    options: (string | number | boolean)[],
    unit?: string
  ): FormControlOption[] {
    if (!settings) return [];

    return (settings as any) // As any to avoid 'Expression not callable' TS error o_O
      .filter(setting =>
        setting instanceof Array
          ? options.findIndex(opt => opt + '' === setting[0] + '') > -1
          : options.findIndex(opt => opt + '' === setting + '') > -1
      )
      .map(setting => {
        const value = setting instanceof Array ? setting[0] : setting;
        const label =
          setting instanceof Array ? (setting[1] instanceof Object ? setting[1].display_name : setting[1]) : setting;
        return { value, label: label + (unit ? ` ${unit}` : '') };
      })
      .concat([{ value: 'all', label: 'Tous' }]);
  }
}

export const formsService = new FormsService();
